.disputes-modal .modal-dialog {
  width: 80%; }

.disputes-modal .sub-title {
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #787a7e; }

.disputes-modal .modal-content {
  font-family: 'Open Sans', serif;
  border-radius: 20px;
  padding: 25px 40px; }
  .disputes-modal .modal-content .modal-header {
    border-bottom: none; }
  .disputes-modal .modal-content .section-title {
    width: 100%;
    color: #2f385c;
    font-weight: bold;
    font-size: 1.6rem;
    margin: 1rem 0rem 1rem 0rem; }
    .disputes-modal .modal-content .section-title.reasons {
      margin-top: 2rem; }
  .disputes-modal .modal-content .close {
    font-size: 4rem;
    padding: 0;
    margin: -1rem -1rem -1rem 1rem;
    color: rgba(0, 0, 0, 0.54);
    font-weight: normal; }
  .disputes-modal .modal-content .modal-title {
    display: flex;
    align-items: flex-start;
    flex-direction: column; }
    .disputes-modal .modal-content .modal-title h2 {
      font-style: normal;
      font-weight: bold;
      font-size: 2.4rem;
      white-space: nowrap; }
    .disputes-modal .modal-content .modal-title span {
      font-size: 1.1rem; }
    .disputes-modal .modal-content .modal-title .file-dispute-text {
      font-size: 1.3rem; }
  .disputes-modal .modal-content .hcp-name-type {
    margin-top: 2.4rem;
    display: flex;
    font-size: 1.4rem;
    min-width: 100%; }
    .disputes-modal .modal-content .hcp-name-type div {
      margin-right: 8rem;
      display: flex; }
    .disputes-modal .modal-content .hcp-name-type b {
      margin-left: 2.3rem;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;
      color: #000000; }
  .disputes-modal .modal-content .separation {
    border: 1px solid #eeeeee;
    width: 100%;
    margin: 0; }
  .disputes-modal .modal-content .disputes-modal-wi-pay.is-guaranteed,
  .disputes-modal .modal-content .disputes-modal-wi-pay.is-guaranteed span {
    color: #00a698;
    display: flex;
    align-items: center; }
  .disputes-modal .modal-content .disputes-modal-wi-pay .total {
    font-weight: bold; }
  .disputes-modal .modal-content .disputes-modal-wi-pay .guaranteed-badge {
    margin-left: 5px;
    width: 26px; }
  .disputes-modal .modal-content .with-prefix {
    display: flex;
    flex-wrap: nowrap;
    font-size: 1.4rem;
    color: #717579;
    flex-direction: column; }
    .disputes-modal .modal-content .with-prefix .input-group .text-mode {
      min-width: 100px; }
      .disputes-modal .modal-content .with-prefix .input-group .text-mode.error {
        border: 3px #9f2f45 solid; }
    .disputes-modal .modal-content .with-prefix .input-group:focus-visible {
      outline-color: #7200a0; }
    .disputes-modal .modal-content .with-prefix .input-group .input-mode input {
      min-width: 100px; }
    .disputes-modal .modal-content .with-prefix .input-group .input-mode .cancel-icon,
    .disputes-modal .modal-content .with-prefix .input-group .input-mode .save-icon {
      padding-right: 0.5rem; }
    .disputes-modal .modal-content .with-prefix span,
    .disputes-modal .modal-content .with-prefix > div {
      font-size: 1.2rem;
      color: black; }
    .disputes-modal .modal-content .with-prefix .time-select {
      width: 8.8rem;
      height: 3.8rem;
      border: 1px solid #000000;
      border-radius: 2px; }
      .disputes-modal .modal-content .with-prefix .time-select.error {
        border: 1.5px #9f2f45 solid; }
    .disputes-modal .modal-content .with-prefix .time-select:focus-visible {
      outline-color: #7200a0; }
    .disputes-modal .modal-content .with-prefix .late__cancellation--badge {
      margin-left: 0.5rem;
      font-size: 0.95rem;
      margin-top: 0.5rem; }
    .disputes-modal .modal-content .with-prefix .field-title {
      color: #000000;
      font-size: 1.2rem; }
      .disputes-modal .modal-content .with-prefix .field-title.adjusted {
        padding-bottom: 1.5rem; }
    .disputes-modal .modal-content .with-prefix.worked-hours {
      margin-bottom: 1rem;
      margin-right: 2rem; }
      .disputes-modal .modal-content .with-prefix.worked-hours .hours {
        margin-bottom: 2rem; }
        .disputes-modal .modal-content .with-prefix.worked-hours .hours.adjusted {
          margin-top: 0.5rem; }
      .disputes-modal .modal-content .with-prefix.worked-hours.is-guaranteed p {
        color: #00a698; }
      .disputes-modal .modal-content .with-prefix.worked-hours p {
        padding-top: 1rem; }
    .disputes-modal .modal-content .with-prefix.break .break-select {
      width: 9rem;
      height: 3rem; }
      .disputes-modal .modal-content .with-prefix.break .break-select .sm__control {
        border: 1px #000000 solid;
        border-radius: 2px; }
      .disputes-modal .modal-content .with-prefix.break .break-select .sm__control--is-focused {
        border: 2px #7200a0 solid;
        border-radius: 2px; }
      .disputes-modal .modal-content .with-prefix.break .break-select.error {
        border: 3px #9f2f45 solid; }
    .disputes-modal .modal-content .with-prefix.break .late__cancellation--badge {
      visibility: hidden; }
    .disputes-modal .modal-content .with-prefix .required {
      color: #9f2f45;
      visibility: hidden;
      font-size: 1rem;
      font-style: italic;
      text-align: left; }
      .disputes-modal .modal-content .with-prefix .required.show {
        visibility: visible; }
    .disputes-modal .modal-content .with-prefix .input-group img {
      cursor: pointer; }
    .disputes-modal .modal-content .with-prefix .input-group .text-mode {
      display: flex;
      align-items: center;
      justify-content: space-around;
      border: 1px solid #c4c4c4;
      border-radius: 2px;
      width: 8.8rem;
      height: 3.8rem; }
      .disputes-modal .modal-content .with-prefix .input-group .text-mode span {
        margin: 0;
        font-weight: bold;
        color: #c4c4c4; }
    .disputes-modal .modal-content .with-prefix .input-group .input-mode {
      position: relative;
      display: flex;
      align-items: center; }
      .disputes-modal .modal-content .with-prefix .input-group .input-mode input {
        width: 8.8rem;
        height: 3.8rem;
        border: 1px solid #717579;
        border-radius: 2px;
        outline-color: #7200a0; }
      .disputes-modal .modal-content .with-prefix .input-group .input-mode .save-icon {
        position: absolute;
        right: 23px; }
      .disputes-modal .modal-content .with-prefix .input-group .input-mode .cancel-icon {
        position: absolute;
        right: 8px; }
  .disputes-modal .modal-content .disputes-modal-send_button {
    width: 243px;
    height: 50px;
    background-color: #7200a0 !important;
    border-radius: 4px;
    border: 0;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem; }
  .disputes-modal .modal-content .disputes-modal-shift-information {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between; }
    .disputes-modal .modal-content .disputes-modal-shift-information.extra {
      margin: 1rem 0rem; }
  .disputes-modal .modal-content .disputes-modal-worked-information {
    margin-top: 2rem; }
    .disputes-modal .modal-content .disputes-modal-worked-information .with-prefix span.credential-download-available {
      margin-right: 5px; }
    .disputes-modal .modal-content .disputes-modal-worked-information .information-row {
      display: flex; }
    .disputes-modal .modal-content .disputes-modal-worked-information .resource-type {
      min-width: 4.2rem;
      min-height: 4.2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 1rem; }
      .disputes-modal .modal-content .disputes-modal-worked-information .resource-type.CNA, .disputes-modal .modal-content .disputes-modal-worked-information .resource-type.STNA {
        color: #5870d1;
        background-color: rgba(88, 112, 209, 0.2); }
      .disputes-modal .modal-content .disputes-modal-worked-information .resource-type.CC, .disputes-modal .modal-content .disputes-modal-worked-information .resource-type.RNS {
        color: #00a698;
        background-color: rgba(0, 166, 152, 0.2); }
      .disputes-modal .modal-content .disputes-modal-worked-information .resource-type.RN {
        color: #ffa900;
        background-color: rgba(255, 169, 0, 0.2); }
      .disputes-modal .modal-content .disputes-modal-worked-information .resource-type.LPN {
        color: #cc51f4;
        background-color: rgba(204, 81, 244, 0.2); }
      .disputes-modal .modal-content .disputes-modal-worked-information .resource-type.GNA {
        color: #5870d1;
        background-color: rgba(88, 112, 209, 0.2); }
    .disputes-modal .modal-content .disputes-modal-worked-information .section-title {
      color: #2f385c; }
  .disputes-modal .modal-content .disputes-modal-reason .section-title {
    color: #2f385c; }
  .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-reason-checkboxes {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 15px;
    min-height: 8rem; }
    .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-reason-checkboxes.error {
      border: 3px #9f2f45 solid;
      padding: 1rem; }
    .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-reason-checkboxes .disputes-modal-checkbox {
      display: flex;
      align-items: center;
      width: 33%;
      margin: 5px 0;
      font-size: 1.2rem; }
      .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-reason-checkboxes .disputes-modal-checkbox > label {
        cursor: pointer;
        margin: 0; }
      .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-reason-checkboxes .disputes-modal-checkbox .custom-checkbox-control {
        border-width: 2px; }
  .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-additional-info {
    display: flex;
    align-items: center;
    flex-direction: row; }
    .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-additional-info label {
      white-space: nowrap; }
    .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-additional-info .description {
      font-size: 1.3rem;
      color: #000000; }
    .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-additional-info .disputes-additional-info-input {
      flex: 1 100%;
      margin-left: 20px;
      padding: 5px;
      border-radius: 2px;
      border: 1px solid #000000; }
      .disputes-modal .modal-content .disputes-modal-reason .disputes-modal-additional-info .disputes-additional-info-input.error {
        border: 3px #9f2f45 solid; }
  .disputes-modal .modal-content .modal-footer {
    border-top: none; }
