@import './../../stylesheets/variables.scss';

.input-group {
	flex-direction: column;
	line-height: 3.2rem;

	.input-label {
		padding-bottom: 0.4rem;
		line-height: 2.1rem;

		&__tooltip {
			display: inline;
			color: $grayscale-500;

			.tooltip-component--overlay {
				font-size: 1.4rem;
				text-align: left;
			}
		}
	}

	.input {
		height: 38px;
		padding: 8px 16px;
		border-radius: 4px;
		border: 1px solid $grayscale-300;

		&:hover,
		&:focus,
		&:active {
			border-width: 2px;
			box-shadow: none;
			border-color: $new-purple;
		}

		&:disabled {
			background-color: $grayscale-25;
			border-color: $grayscale-200;
		}

		&__readonly {
			border-bottom: 1px solid $grayscale-100;
			min-height: 40px;
		}

		&.invalid-input {
			border-width: 2px;
			border-color: $red-400;
		}
	}

	.error-message {
		margin-top: 0.25rem;
		line-height: 2rem;
		color: $red-400;
	}
}
