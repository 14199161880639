.professional-row__container {
  display: flex;
  flex: 1;
  align-items: center;
  padding: 10px; }

.professional-row__container[disabled] {
  pointer-events: none; }

.professional-row__info {
  display: flex;
  flex: 4;
  flex-direction: column;
  padding-left: 1rem;
  align-items: flex-start; }
  .professional-row__info__name {
    font-size: 1.4rem;
    font-weight: 600;
    color: #000000; }
  .professional-row__info__data {
    font-size: 1.2rem;
    font-weight: 400;
    color: #000000; }

.professional-row__info[disabled] {
  opacity: 0.3; }

.professional-row__badge {
  display: flex;
  flex: 2;
  justify-content: flex-end;
  font-size: 1.2rem;
  font-weight: 600;
  color: #000000; }
  .professional-row__badge__pill {
    display: flex;
    gap: 4px;
    padding: 4px 8px;
    align-items: center;
    border-radius: 20px; }
    .professional-row__badge__pill--available {
      background: #cbe6cb; }
    .professional-row__badge__pill--unavailable {
      background: #ff9f9f; }
    .professional-row__badge__pill--assigned {
      background: #e7e7e7; }
    .professional-row__badge__pill--not_provided {
      background: #ffefb6; }
  .professional-row__badge__dot {
    height: 8px;
    width: 8px; }
    .professional-row__badge__dot--available {
      fill: #10a112; }
    .professional-row__badge__dot--unavailable {
      fill: #a11010; }
    .professional-row__badge__dot--assigned {
      fill: #858586; }
    .professional-row__badge__dot--not_provided {
      fill: #f6d046; }
