@import './../../stylesheets/variables.scss';

.font-style {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 2rem;
	color: $ultra-white;
}

.professionals-menu-item-tooltip {
	.tooltip-inner {
		background-color: $off-black;
		color: $ultra-white;
		font-size: 1.4rem;
	}
}

.app-header-desktop {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: $header-height;
	background-color: $primary-500;
	color: $ultra-white;
	padding: 0 4rem;
	z-index: 10;
	position: relative;

	.dropdown-item.active,
	.dropdown-item:active {
		background-color: $primary-400;
	}

	.icon-img {
		width: 20px;
		height: 20px;
	}

	.vertical-separator {
		margin: 0 1rem;
		border-left: 2px solid $ultra-white;
		width: 2px;
		max-width: 2px;
		height: 3rem;
	}

	.facility-and-menu {
		width: 100%;
		display: flex;

		.right-menu {
			display: flex;
			align-items: center;
			max-height: 6rem;
			justify-content: flex-end;
			flex-grow: 1;

			.shift-menu-item {
				padding: 18px 5px;
				margin-left: 10px;
				display: flex;
				align-items: center;

				&:hover {
					text-decoration: none; /* Remove underline on hover */
				}

				&.active {
					background-color: $primary-800;
					height: 100%;
				}
			}

			.shift-menu-simple-item {
				color: $ultra-white;
				font-size: 1.6rem;
				font-weight: 600;
				padding: 16px;
			}
		}

		.facility-label-dropdown {
			align-items: center;
			display: flex;
			padding: 10px 10px;

			.dropdown {
				display: flex;
				max-width: 500px;
				width: 100%;
				height: 100%;

				.toggle {
					background: none;
					border: none;
					align-items: center;
					display: flex;
					color: $ultra-white;
					font-size: 1.5rem;

					.toggle-content {
						text-align: left;
						min-width: 15ch;
						max-width: 45ch;
						max-height: 3.9rem;
						text-overflow: ellipsis;
						overflow: hidden;
						-webkit-line-clamp: 2;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						white-space: normal;
						word-break: break-all;
					}

					&:hover {
						text-decoration: none;
					}

					&__caret {
						width: 1.8rem;
						min-width: 1.8rem;
						margin-left: 2rem;
					}
				}

				.dropdown-menu {
					width: 100%;
					min-width: 350px;
				}
			}

			.expand-dropdown {
				padding-left: 5px;
				width: 26px;
				height: 16px;
				cursor: pointer;
			}

			.rbt {
				width: 100%;
				height: 100%;

				div:first-child {
					align-items: center;
				}

				.rbt-highlight-text {
					white-space: normal;
				}

				.rbt-input-main {
					background-color: transparent;
					border: none;
					border-radius: 0;
					box-shadow: none;
					color: $white;
					font-size: 1.4rem;
					padding: 0 5px 0 5px;

					&:focus {
						background-color: $ultra-white;
						color: $off-black;
					}
				}

				.rbt-input-hint {
					display: none;
				}

				.rbt-menu {
					border-radius: 0;

					.dropdown-item {
						align-items: center;
						display: flex;
						justify-content: space-between;
						padding: 0;

						&:active {
							background-color: $primary-100;
						}

						&:hover {
							background-color: $primary-400;
							color: $ultra-white;
						}

						.text {
							padding: 0.25rem 1.5rem;
							white-space: normal;
							width: 100%;
							display: flex;
							flex-direction: row;

							span {
								flex-grow: 1;
							}

							&.selected {
								background-color: $primary-100;
							}
						}

						.pending-confirmation {
							font-size: 10px;
						}
					}
				}
			}
		}
		.navbar-menus {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.logo {
		display: flex;
		justify-content: center;
		width: 13rem;
		min-width: 8rem;
		height: 3.41rem;
		background: url(../../assets/images/logo-white.svg) no-repeat;
		-webkit-background-size: contain;
		-moz-background-size: contain;
		-o-background-size: contain;
		background-size: contain;
	}

	.header-menu-icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 22px;
	}

	.dropdown-item {
		font-size: 1.2rem;
		line-height: 2.2rem;
	}
}

@media (min-width: 1280px) {
	.app-header-desktop {
		.facility-and-menu {
			.right-menu {
				.shift-menu-item {
					padding: 18px 20px;
					margin-left: 10px;
					display: flex;
					align-items: center;
				}
			}
		}
	}

	.right-menu-label {
		padding: 0 15px;
		font-size: 1.6rem;
		white-space: nowrap;
	}
}

.ie-10 {
	.with-margin {
		margin-top: 2px;
	}

	.flex-bottom {
		display: flex;
	}
}
