@import './../../../stylesheets/variables.scss';

.cg-name-container {
	display: flex;

	p {
		padding-top: 0.5rem;
		line-height: 2rem;
	}

	img {
		height: 3rem;
		border-radius: 2rem;
		margin-right: 1rem;
	}
}

.remove-dnr {
	text-align: center;
}

.delete-dnr {
	font-weight: 600;
	line-height: 2rem;
	text-align: center;
	letter-spacing: 0.05em;
	padding: 0.5rem 2rem;
	font-size: 1rem;
	border-radius: 4rem;
	border: none;
	color: $ultra-white;
	background: $new-purple;
}
