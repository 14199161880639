@import './../../stylesheets/variables.scss';

.global-overview-screen {
	font-family: 'Open Sans', sans-serif;
	width: 100%;
	height: calc(100vh - 60px);
	margin-bottom: 4rem;
	display: flex;
	color: $black;

	.overview-container {
		padding: 0 20px 16px 20px;
		margin-top: 16px; // for sticky elements
		width: 100%;
		min-width: 793px;
		overflow: auto;
		display: flex;
		flex-direction: column;

		.heading {
			border-bottom: 1px solid $grayscale-150;
			display: flex;
			align-items: center;
			font-size: 1.6rem;
			line-height: 2.4rem;
			font-weight: 700;
			padding-bottom: 16px;
			width: 100%;

			.calendar-icon {
				padding-right: 4px;
				width: 18px;
			}
		}

		.filters-container {
			width: 100%;
			display: flex;
			align-items: center;
			align-self: stretch;
			padding: 16px 0;
			gap: 16px;
			border-bottom: 1px solid $grayscale-150;
			margin-bottom: 16px;
			justify-content: space-between;

			.filter-item {
				max-width: 32rem;
			}

			.push-right {
				align-self: flex-end;
			}

			.select-filter-type-container {
				gap: 12rem;
				min-width: 12.5rem;
				min-height: 3.5rem;
			}

			.select-filter-status-container {
				gap: 12rem;
				min-width: 10.6rem;
				min-height: 3.5rem;
			}

			.select-filter-type-container .button,
			.select-filter-status-container .button {
				margin-left: 12px;
				color: $grayscale-600;
				padding: 2px 3px 2px 8px;
				font-weight: 400;
				border-radius: 0.4rem;
				min-height: 3.5rem;
				gap: 0.08rem;
			}

			.select-filter-type-container .button img,
			.select-filter-status-container .button img {
				height: 1.4rem;
				width: 1.4rem;
			}

			.inline-filters {
				display: flex;
				align-items: center;
				gap: 1.6rem;
			}
		}

		.timeframe-selector-container {
			display: flex;
			align-items: center;
			align-self: stretch;
			padding: 16px 0;
			gap: 16px;
			border-bottom: 1px solid $grayscale-150;

			.secondary-button {
				display: flex;
				border: 1.5px solid $grayscale-600;
				background-color: $grayscale-0;
				color: $grayscale-900;
				padding: 8px 16px;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				font-size: 1.4rem;
				font-weight: 600;
				line-height: 1.6rem;
				width: 75px;
			}

			.shifts-period {
				flex: 1;
				display: flex;
				align-items: center;
				font-size: 1.6rem;
				font-weight: 600;
				line-height: normal;
				padding-left: 10px;

				.shifts-period-nav {
					padding: 3px 10px 3px 3px;
					cursor: pointer;

					&.disabled {
						opacity: 0.2;
						cursor: auto;
					}
				}

				.nav-date-item {
					min-width: 136px;
				}
			}
		}

		.view-by-container {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.2rem;
			font-weight: 600;

			.actions-button {
				button.dropdown-toggle {
					font-size: 1.4rem;
					font-weight: 600;
					color: $primary-800;
					background-color: rgba(0, 0, 0, 0) !important;
					border-color: rgba(0, 0, 0, 0) !important;
					box-shadow: none;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.dropdown-menu {
				padding: 0;
				border: 1px solid $grayscale-100;
				box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);

				.dropdown-item {
					font-size: 1.2rem;
					font-weight: 600;
					color: $black;
					padding: 10px 16px;
					display: block;
					border-bottom: 1px solid $grayscale-100;

					&:hover {
						background-color: $grayscale-50;
						color: $primary-800;
					}

					&:focus {
						background-color: rgba(0, 0, 0, 0);
						border-color: rgba(0, 0, 0, 0);
					}
				}
			}

			.filter-btn {
				background: none;
				border: none;
				padding: 0;
				display: flex;
				align-items: center;
				justify-content: center;

				&.clear {
					margin-right: 1.6rem;
					color: $red-500;
					text-decoration: none;
					font-weight: 400;
					font-size: 1.4rem;
				}
			}

			.filter-container {
				width: 13.1rem;
				height: 4rem;
				padding: 0.08rem 1rem 0rem 1rem;
				gap: 0.4rem;
				border-radius: 0.04rem;
				background-color: $grayscale-125;
				display: flex;
				align-items: center;

				&.noFilterCount {
					width: 10.8rem;
				}

				.filter-text {
					font-size: 1.4rem;
					font-weight: 400;
					line-height: 2rem;
					text-align: left;
					color: $primary-500;
				}

				.filter-number {
					width: 1.9rem;
					height: 2rem;
					padding: 0.15rem;
					gap: 1rem;
					border-radius: 6.2rem;
					background-color: $primary-500;
					color: $grayscale-0;
					align-items: center;
					font-size: 1.2rem;
					font-weight: 400;
					line-height: 1.6rem;
					text-align: center;
				}

				.arrow-icon {
					transition: transform 0.2s;
					cursor: pointer;
				}

				.arrow-icon.down {
					transform: rotate(180deg);
				}
			}
		}

		.week-days-container {
			flex: 1;
			display: flex;
			gap: 8px;

			.col {
				display: flex;
				flex-direction: column;
				gap: 8px;
				padding: 0;
			}

			.summary-box {
				border: 1px solid $grayscale-100;
				background-color: $ultra-white;
				position: sticky;
				top: 0;
				z-index: 4;

				.day-label {
					padding: 4px 10px;
					background-color: $grayscale-100;
					font-size: 1.4rem;
					font-weight: 600;

					&.today-label {
						background-color: $primary-100;
					}
				}

				.summary-container {
					padding: 8px;
					display: flex;
					flex-direction: column;
					gap: 4px;
					font-size: 1.1rem;
					font-weight: 700;

					.filled-count {
						padding: 2px 4px;
						background-color: $green-100;
					}

					.unfilled-count {
						padding: 2px 4px;
						background-color: $yellow-200;
					}
				}
			}

			.shifts-container {
				flex: 1;
				border: 1px solid $grayscale-100;
				padding: 8px;
				font-size: 1.1rem;
				line-height: 1.6rem;
				font-weight: 600;

				.shift-container {
					padding-bottom: 8px;
				}

				.shift-box {
					padding: 8px;
					border-radius: 0 4px 0 0;
					border-top: 2px solid $green-600;
					border-right: 2px solid $green-600;
					border-bottom: 2px solid $green-600;
					border-left: 6px solid $green-600;
					background-color: $ultra-white;

					&.unfilled {
						border-color: $yellow-600;
					}
					&.cancelled {
						border-color: $red-500;
					}

					.facility-name {
						font-weight: 700;
					}

					.fill-summary {
						font-weight: 400;
					}
				}

				.slot-title {
					display: flex;
					flex-direction: column;

					&__cancelled-name {
						text-decoration: line-through;
					}

					&__cancelled-title {
						font-style: italic;
						font-weight: 400;
					}

					&__unit_name {
						font-style: italic;
						font-weight: 400;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
						text-overflow: ellipsis;
						word-break: break-word;
						margin-top: 0.4em;
						margin-bottom: 0.4em;
					}
				}

				.slot-box {
					padding: 8px 0 8px 8px;
					border-left: 6px solid $green-600;
					border-bottom: 1px solid $green-600;
					background-color: $green-100;
					display: flex;

					&.unfilled {
						border-color: $yellow-600;
						background-color: $yellow-200;
					}

					&.cancelled {
						border-color: $red-500;
						background-color: $red-200;
					}

					.slot-label {
						display: flex;
						align-items: center;
					}

					.dropdown-menu {
						z-index: 2;
					}
				}

				.action-menu {
					background-color: $transparent !important;
					border: 0;
					padding: 0;

					&:hover,
					&::after,
					:focus {
						background-color: $transparent !important;
						border: 0;
					}
				}

				.action-menu-item {
					font-size: 1.2rem;
					font-weight: 600;
				}
			}
		}
	}
}

.refresh-modal {
	.primary-button {
		background-color: $primary-500 !important;
	}
}
