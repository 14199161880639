@import './../../stylesheets/variables.scss';

.professionals-container {
	.title-wrapper {
		margin-bottom: 0;
	}
}
.professionals-screen {
	height: 100%;
	flex-grow: 1;
	font-style: normal;
	padding-bottom: 12rem;

	h1 {
		font-weight: 600;
		border-bottom: 0.15rem solid $grayscale-100;
		line-height: 5rem;
		margin-bottom: 2rem;
		position: sticky;
		top: -2.2rem;
		background-color: white;
		z-index: 2;
	}

	&__filters-section {
		border-bottom: 0.15rem solid $grayscale-100;
		margin-bottom: 2rem;
		padding-bottom: 2rem;
		padding-top: 2rem;
		display: flex;
		flex-direction: column;
		position: sticky;
		top: -2.2rem;
		background-color: white;
		z-index: 2;

		.filter-item {
			max-width: 400px;
		}

		.push-right {
			align-self: flex-end;
		}

		.searched-caregivers {
			max-width: 400px;
			width: 100%;
		}
	}

	&__counter {
		display: inline-block;
		font-size: 1.6rem;
		font-weight: 600;
		line-height: 3.2rem;
		margin-bottom: 1rem;
	}
}
