@import './../../../stylesheets/variables.scss';

.assign-professional-container {
	.section-buttons {
		background-color: white !important;
		color: #121315;
		font-size: 1.6rem;
		font-weight: 600;
		border-width: 0;
		border-bottom-width: 6px;
		border-color: #121315 !important;
		margin-right: 16px;
	}
	.section-buttons:hover {
		color: $new-purple;
		border-color: $new-purple !important;
	}

	.selected {
		background-color: white !important;
		color: #7200a0 !important;
		font-size: 1.6rem;
		font-weight: 600;
		border-width: 0;
		border-bottom-width: 6px;
		border-color: #7200a0 !important;
		margin-right: 1rem;
	}

	.modal-body {
		padding: 2rem 0;
		font-weight: 400;
		font-size: 1.4rem;
		overflow: visible;

		.select-container {
			overflow: visible;
		}
	}

	.selected-professionals-body {
		height: 200px;
		.list-selected-caregivers {
			list-style-type: none;
			padding: 0;
			.selected-caregiver-container {
				display: flex;
				flex-direction: row;
				button {
					border: none;
					background-color: white;
				}
				li {
					padding: 8px;
					.platinum-badge-caregiver {
						margin-left: auto;
					}
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					font-weight: 600;
					background-color: white;
					border: 1px solid #e7e7e7;
					border-radius: 4px;
				}
			}
		}
	}
	.selected-professionals-body-list {
		height: 200px;
		.list-selected-caregivers {
			list-style-type: none;
			padding: 0;
			.selected-caregiver-container {
				display: flex;
				flex-direction: row;
				button {
					border: none;
					background-color: white;
				}
				button:hover {
					background-color: rgb(169, 121, 121) !important;
				}
				li {
					padding: 8px;
					.platinum-badge-caregiver {
						margin-left: auto;
					}
					width: 100%;
					margin-right: 1.7rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					font-weight: 600;
					background-color: white;
					border: 1px solid #e7e7e7;
					border-radius: 4px;
				}
				span {
					font-weight: 400;
					b {
						font-weight: 700;
						&.caregiver-name {
							font-weight: 600;
						}
					}
				}
				&.accepted {
					li {
						background-color: rgb(224, 247, 224);
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
					}
				}
			}
		}
	}

	.selected-professionals-body::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	.selected-professionals-body::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	.selected-professionals-body::-webkit-scrollbar-thumb {
		background: #888;
	}

	/* Handle on hover */
	.selected-professionals-body-list::-webkit-scrollbar-thumb:hover {
		background: #555;
	}

	.selected-professionals-body-list::-webkit-scrollbar {
		width: 4px;
	}

	/* Track */
	.selected-professionals-body-list::-webkit-scrollbar-track {
		background: #f1f1f1;
	}

	/* Handle */
	.selected-professionals-body-list::-webkit-scrollbar-thumb {
		background: #888;
	}
}
