@import './../../stylesheets/variables.scss';

.external-agency-schedule {
	@keyframes assigned-fade {
		0% {
			background-color: inherit;
		}
		15% {
			background-color: $green-300;
		}
		85% {
			background-color: $green-300;
		}
		100% {
			background-color: inherit;
		}
	}

	@keyframes error-fade {
		0% {
			background-color: inherit;
		}
		15% {
			background-color: $red-200;
		}
		85% {
			background-color: $red-200;
		}
		100% {
			background-color: inherit;
		}
	}

	@keyframes removeRow {
		0% {
			transform: scale(1, 1);
			line-height: 1.7;
			visibility: visible;
			opacity: 1;
		}
		25% {
			transform: scale(1, 1);
			line-height: 1.7;
			height: 65px;
			opacity: 0;
		}
		50% {
			transform: scale(1, 0.5);
			line-height: 1.1;
			height: 32px;
			opacity: 0;
		}
		100% {
			transform: scale(1, 0);
			height: 0;
			line-height: 0;
			opacity: 0;
			visibility: collapse;
		}
	}

	.p-datatable {
		.cancelled-shift-row {
			.table-cell {
				color: #af3d52;

				span,
				div {
					text-decoration: line-through;
				}

				.cancelled-label {
					text-decoration: none;
					font-weight: 700;
				}
			}
		}

		.highlight-assigned-row {
			.table-cell {
				animation-name: assigned-fade;
				animation-duration: 2.2s;
			}
		}

		.highlight-error-row {
			.table-cell {
				animation-name: error-fade;
				animation-duration: 2.2s;
			}
		}

		.assign-button {
			color: $primary-500;
			text-align: center;

			&:hover {
				cursor: pointer;
				color: $primary-800;
				font-weight: 600;
			}
		}

		tr.remove-row-animation {
			transition-duration: 1s;
			animation: removeRow 1s 1 linear forwards;
			transform-origin: top;
		}

		tr.delayed-remove-row-animation {
			transition-duration: 1s;
			animation: removeRow 1s 1 linear forwards;
			transform-origin: top;
			animation-delay: 2s;
		}
	}

	.multi-select-container .button {
		border-radius: 4px;
		border-color: $grayscale-400;
		padding: 8px 10px 8px 16px;
		font-weight: 400;
		color: $grayscale-600;
	}

	.right-align-dropdown {
		.multi-select-container .content {
			right: 30px;
		}
	}

	.shift-count-warning {
		display: flex;
		align-items: center;
		gap: 16px;
		padding: 16px;
		border-radius: 8px;
		background: #fcea90;
		color: $grayscale-900;
		font-size: 1.4rem;
		line-height: 2.4rem;
		letter-spacing: 0.25px;

		.shift-count-warning-icon {
			transform: rotate(180deg);
		}
	}

	.shift-count-info {
		display: flex;
		align-items: center;
		gap: 8px;
		align-self: stretch;
		padding-top: 16px;
		color: $grayscale-600;
		font-size: 1.4rem;
		font-style: italic;
		line-height: 2.4rem;
	}
}

.facility-menu-item-tooltip .tooltip-inner {
	background-color: $grayscale-900;
	color: $ultra-white;
	font-size: 1.4rem;
}
