@import './../../../stylesheets/variables.scss';

.base-modal {
	.modal-open {
		.modal {
			background-color: rgba($grayscale-600, $transparency-80);
		}
	}

	h2 {
		font-size: 1.6rem;
		font-weight: 700;
		line-height: 3.2rem;
	}

	.modal-content {
		section {
			border-top: 1px solid $grayscale-100;
			padding: 2rem 1rem;
			margin: 0 -1rem;

			@media (min-width: 200px) and (max-width: 575px) {
				padding: 1.5rem 1rem;
			}
		}

		font-size: 1.6rem;
		padding: 1rem 2rem;

		@media (min-width: 200px) and (max-width: 575px) {
			padding: 1rem;
		}

		.modal-header {
			font-size: 2.4rem;
			padding: 0;
			margin: 0 1rem;

			.header-content {
				h1 {
					font-weight: 700;
					margin: 1.8rem 0;

					@media (min-width: 200px) and (max-width: 575px) {
						margin: 1rem 0;
					}
				}
			}

			.header-close-spacer {
				width: 20px;
				height: 20px;
			}

			.close {
				font-weight: 400;
				font-size: 3.6rem;
				margin: 0;
				padding: 2rem;
				position: absolute;
				right: 10px;
				top: -5px;
			}
		}

		.modal-footer {
			display: flex;
			flex-direction: row;
			justify-content: right;
			padding: 1.5rem 0;

			@media (min-width: 200px) and (max-width: 575px) {
				flex-direction: column-reverse;
				align-content: center;
			}

			.footer-buttons {
				min-width: 160px;
				padding: 10px 20px 10px 20px;
				border-radius: 30px;
				border: 1px solid $black;
				font-weight: 600;
				margin: 0.75rem;

				@media (min-width: 200px) and (max-width: 575px) {
					width: 100%;
				}

				&.secondary-button {
					background-color: $ultra-white;

					&:disabled {
						background-color: $grayscale-100;
						border-color: $grayscale-100;
						color: $ultra-white;
					}
				}

				&.primary-button {
					color: $ultra-white;
					border-color: $primary-500;
					background-color: $primary-500;

					&:disabled {
						background-color: $grayscale-100;
						border-color: $grayscale-100;
						color: $ultra-white;
					}
				}
			}
		}
	}

	&.confirm-modal {
		.modal-content {
			padding: 20px 30px;
			text-align: center;

			.modal-header {
				border: none;

				.header-content {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: center;
					flex-grow: 1;
				}

				.close {
					right: 15px;
					top: 0;
				}
			}

			.modal-footer {
				justify-content: center;
				border: none;

				.primary-button {
					border-color: $red-500;
					background-color: $red-500;

					&.primary-colors {
						border-color: $primary-500;
						background-color: $primary-500;
					}
				}
			}
		}
	}

	&.router-prompt-modal {
		.modal-content {
			.modal-footer {
				justify-content: center;
				border: none;

				.primary-button {
					border-color: $primary-500;
					background-color: $primary-500;
				}
			}
		}
	}
}
