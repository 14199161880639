@import './../../../stylesheets/variables.scss';

.filter-shift-container {
	width: 100%;
	background-color: $grey-background;
	padding: 0.5rem 1.6rem;

	.clear-section {
		margin: 1rem 0;
		.clear-button {
			font-size: 1.4rem;
			font-weight: 600;
			color: $black;
			cursor: pointer;
			border: none;
			background-color: $grey-background;
			text-decoration: underline;
		}

		.clear-button:hover {
			color: $new-purple;
		}

		.subtitle {
			font-size: 1.4rem;
			font-weight: 600;
		}
	}

	.toggle-button {
		font-size: 1.4rem;
		font-weight: 600;
		color: $black;
		cursor: pointer;
		border: none;
		background-color: $grey-background;
		text-decoration: underline;

		img {
			transform: rotate(90deg);
			height: 1.1rem;
			width: 6px;
			margin-right: 5px;
		}

		&.active {
			img {
				transform: rotate(270deg);
			}
		}
	}
}

.filter-selects {
	width: 100%;
	display: flex;
	gap: 1rem;
	align-items: center;
	flex-wrap: wrap;
	margin-top: 2px;
}

.react-datepicker__tab-loop {
	// counteracts the gap when added to the DOM
	margin-left: -1rem;
}

.date-picker-filter {
	background-color: $ultra-white;
	display: flex;
	cursor: pointer;
	min-width: 15rem;
	border: 1px solid $light-grey;
	border-radius: 2px;
	padding: 2px 3px;
	align-items: center;
	justify-content: space-between;
	caret-color: transparent;
	min-height: 4.4rem;
	.date-subtitle {
		font-style: normal;
		font-weight: 600;
		font-size: 1.4rem;
		color: $light-grey;
	}

	.date {
		font-style: normal;
		font-weight: 600;
		font-size: 1.4rem;
		color: $black;
	}

	img {
		transform: rotate(90deg);
		height: 11px;
		width: 6px;
		margin-right: 5px;
	}
}

.date-picker-filter:focus-visible {
	outline-color: $new-purple;
	img {
		transform: rotate(270deg);
	}
}

@media (min-width: 200px) and (max-width: 575px) {
	.filter-shift-container {
		max-height: 450px;
		max-width: 100%;
		overflow: scroll;
		padding-bottom: 2rem;
	}

	.multi-select-container {
		position: relative;
	}

	.filter-selects,
	.filter-caregivers-section,
	.filter-section {
		width: 100%;
		flex-direction: column;
		align-items: center;
	}

	.react-datepicker__tab-loop {
		// counteracts the gap when added to the DOM
		margin-top: -1rem;
	}

	.react-datepicker-wrapper {
		width: 100% !important;
	}

	.date-picker-filter {
		width: 100%;
	}

	.clear-section {
		height: 3.5rem;
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin: 0.5rem !important;

		.clear-button-section {
			display: flex;
			width: 45%;
		}

		.information-section {
			display: flex;
			justify-content: flex-end;
			align-items: center;
			width: 45%;
		}

		.filter-count {
			margin: 0 1rem;
			cursor: pointer;
			width: 3rem;
			height: 3rem;
			font-weight: 700;
			background: $new-purple;
			color: $ultra-white;
			border-radius: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
}
