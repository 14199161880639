@import './../../stylesheets/variables.scss';

// PrimeReact issue fixed in later version https://github.com/primefaces/primereact/issues/4728#issuecomment-1663853101
svg.p-icon,
.p-checkbox-box * {
	pointer-events: none;
}

.report-screen-container {
	width: 100%;
	overflow: hidden;

	.report-screen-page {
		width: 100%;
		padding: 0 4rem 1rem 4rem;
		overflow: hidden;
		background-color: $grayscale-0;

		a {
			color: $primary-500;
			text-decoration: underline;
		}

		.report-screen-header {
			font-size: 1.6rem;

			font-weight: 500;
			color: $grayscale-500;

			.back-button {
				display: flex;
				align-items: center;
				gap: 0.5rem;
				text-decoration: none;
				color: inherit;
				padding: 0.5rem 0;
				max-width: 200px;
			}

			.report-screen-title {
				display: flex;

				flex-direction: row;
				align-items: center;
				flex-wrap: wrap;
				border-bottom: 1px solid $grayscale-200;
				margin-bottom: 1rem;
				padding-bottom: 1rem;

				.report-screen-title-label {
					font-size: 2.4rem;
					font-weight: 700;
					color: $grayscale-600;
					margin: 0 3rem 0 1rem;

					&.with-top-margin {
						margin-top: 3rem;
					}
				}

				.report-screen-download-btn {
					margin-left: auto;
					color: $black;
					border-radius: 6px;

					* {
						font-weight: normal;
					}

					&.p-button-outlined {
						border: 1px solid;
						font-weight: normal;
					}
				}
			}
		}

		.report-screen-content {
			.report-list-section {
				font-size: 1.4rem;
				margin: 3rem 3rem 0 1rem;

				.report-list-category {
					font-size: 1.6rem;
					font-weight: 700;
					color: $grayscale-600;
					margin-bottom: 1.5rem;
				}

				thead {
					background-color: $grayscale-50;

					th {
						border-bottom: none;
					}
				}

				tbody {
					.report-name {
						width: 30%;
					}

					.report-description {
						width: 70%;
					}
				}
			}
		}
	}
	.report-screen-filters {
		display: flex;
		flex-direction: row;
		gap: 1rem;
		align-items: center;

		.report-screen-filter {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			label {
				padding-left: 1.2rem;
				font-weight: 600;
				line-height: 1rem;

				&::after {
					content: '\00a0';
					display: inline-block;
				}
			}
		}
	}
}

.p-component {
	font-family: 'Open Sans', Arial, sans-serif;
	font-size: 1.2rem;

	border-radius: 2px;

	&.date-range-button {
		background-color: $grayscale-0;
		color: $grayscale-600;
		border: 1px solid $grayscale-200;
		margin: 0.5rem;
		min-width: 30rem;

		&:hover {
			background-color: $grayscale-0;
			color: $grayscale-600;
		}

		.date-range-button-label {
			display: flex;
			align-items: center;
			gap: 0.5rem;
			width: 100%;
			justify-content: space-between;

			span {
				flex-grow: 1;
			}
		}
	}
}

.p-datepicker table td > span.p-highlight,
.p-datepicker table td.p-datepicker-today > span.p-highlight {
	border-radius: 0;
	color: $grayscale-700;

	&:has(.from) {
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
		border-top: 2px solid $grayscale-0;
		border-bottom: 2px solid $grayscale-0;
		text-decoration-color: $grayscale-0;
		background-color: $primary-100;
	}

	&:has(.to) {
		border-top-right-radius: 50%;
		border-bottom-right-radius: 50%;
		border-top: 2px solid $grayscale-0;
		border-bottom: 2px solid $grayscale-0;
		text-decoration-color: $grayscale-0;
		background-color: $primary-100;
	}

	&:has(.between) {
		border-top: 2px solid $grayscale-0;
		border-bottom: 2px solid $grayscale-0;
		text-decoration-color: $grayscale-600;
		background-color: $primary-100;

		span {
			background-color: $primary-100;
		}
	}

	.from,
	.to {
		background-color: $primary-500;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		font-weight: 700;
		color: $grayscale-0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.p-datepicker table td.p-datepicker-today > span {
	background-color: $grayscale-0;
	font-weight: 700;
	text-decoration: underline;
	text-decoration-thickness: 2px;
	text-underline-offset: 2px;
	text-decoration-color: inherit;

	> span {
		background-color: $grayscale-0;
		color: inherit;

		&.p-highlight {
			background-color: $primary-500;
			color: $grayscale-0;
		}
	}
}

.p {
	&-datatable {
		background-color: $grayscale-0;

		.p-datatable-header {
			background-color: $grayscale-0;
			border: none;
		}

		.p-datatable-thead > tr > th {
			background-color: $grayscale-50;
		}
	}

	&-paginator {
		.p-paginator-pages,
		.p-paginator-next,
		.p-paginator-prev,
		.p-paginator-first,
		.p-paginator-last {
			border-radius: 2px;

			* {
				font-size: 1.2rem;
				font-weight: 600;
			}

			.p-paginator-page {
				cursor: pointer;
				padding: 0.5rem 1rem;
				margin: 0.5rem;
				border: 1px solid $grayscale-0;
				border-radius: 2px;
				background-color: $grayscale-0;

				&:not(.p-highlight) {
					&:hover {
						border-color: $grayscale-50;
						background-color: $grayscale-50;
					}

					&:active {
						background-color: $grayscale-600;
						color: $grayscale-200;
					}
				}

				&.p-highlight {
					background-color: $grayscale-600;
					color: $grayscale-200;
					cursor: not-allowed;
				}
			}
		}
	}

	&-multiselect {
		margin: 0.5rem 0.75rem;
		border: 1px solid $light-grey;
		border-radius: 2px;
		min-width: 16rem;
		max-width: 16rem;

		&-panel &-header {
			display: flex;
			flex-wrap: wrap;
			flex-direction: column;
			align-items: flex-start;
			min-width: 25rem;
			background-color: $grayscale-0;
			margin: 1rem 1rem 0 1rem;
			padding: 0 0.25rem 1.25rem 0.25rem;

			.p-checkbox {
				width: 100%;

				.p-checkbox-box {
					margin-right: 0.5rem;
				}
			}

			.selected-count {
				line-height: 3rem;
			}

			.p-multiselect-filter-container {
				width: 100%;
				margin: 1rem 0;

				.p-input-icon-right {
					width: 100%;

					> img {
						position: absolute;
						top: 1.1rem;
						right: 0.75rem;
					}

					> input {
						width: 100%;
						padding-right: 2.5rem;
					}
				}
			}

			.p-multiselect-close {
				display: none;
			}
		}

		&-panel &-items &-item.p-highlight {
			background-color: $grayscale-0;
			color: inherit;
		}
	}

	&-checkbox {
		.p-checkbox-box {
			border-radius: 2px;
			color: $grayscale-0;

			&.p-highlight {
				border-color: $primary-500;
				background: $primary-500;
			}
		}
	}

	&-datepicker {
		border: none;

		&.p-datepicker-multiple-month {
			border-bottom: 1px solid $grayscale-200;
			padding-bottom: 1rem;
			margin-bottom: 1rem;

			.p-datepicker-group {
				border-left: none;

				> * {
					padding: 0 2rem;
				}
			}
		}

		.p-datepicker {
			&-header {
				border-bottom: none;

				.p-datepicker-month,
				.p-datepicker-year {
					font-size: 1.6rem;
				}
			}
		}

		&-group:first-of-type {
			border-right: 1px solid $grayscale-200;
			border-left: none;
		}

		&-group:last-of-type {
			border-left: 1px solid $grayscale-200;
		}

		table {
			text-align: center;
			font-size: 1.2rem;
		}

		table td {
			padding: 0;
			border-top: 3px solid $grayscale-0;
			border-bottom: 3px solid $grayscale-0;

			> span {
				width: 3.5rem;
				height: 3.5rem;
			}
		}
	}
}

.date-range-actions {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	gap: 2.5rem;
	margin: 0.5rem 0;

	.p-button {
		padding-left: 3.5rem;
		padding-right: 3.5rem;
		border-radius: 3rem;
		background-color: $primary-500;
		color: $grayscale-0;

		&:hover {
			background-color: $primary-400;
		}

		&-text {
			background-color: $grayscale-0;
			color: $primary-500;

			&:hover {
				color: $primary-100;
			}
		}
	}

	.p-button-label {
		font-weight: bold;
		font-size: 1.4rem;
	}
}

.p-multiselect-items-wrapper {
	max-height: 200px;
	margin: 0 1rem 1rem 0;

	/* Firefox */
	scrollbar-width: thin;
	scrollbar-color: auto;
}

/* WebKit and Chromium-based browsers (Chrome, Safari, Edge, Opera) */
*::-webkit-scrollbar {
	width: 8px; /* Width of the vertical scrollbar */
	height: 8px; /* Height of the horizontal scrollbar */
}

*::-webkit-scrollbar-track {
	background: $grayscale-100;
	border-radius: 0;
}

*::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.5);
	border: 2px solid $grayscale-100;
	background-clip: padding-box;
}

*::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.7);
}

/* Remove arrows */
*::-webkit-scrollbar-button {
	display: none;
}

/* For Internet Explorer and Edge (legacy versions) */
* {
	-ms-overflow-style: -ms-autohiding-scrollbar;
}

.report-screen-page {
	* {
		font-size: 1.2rem;
	}

	.custom-paginator {
		display: flex;
		justify-content: center;
		min-width: 100px;
		width: 400px;
		border: none;
		margin: 2rem auto;
		padding: 0;

		.p-dropdown {
			border-radius: 6px;
		}

		.p-dropdown-label {
			display: flex;
			align-items: center;
		}
	}
}
