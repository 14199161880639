@import './../../stylesheets/variables.scss';

.account-management {
	font-size: 1.4rem;

	// Define a mixin for common styles
	@mixin input-styles {
		margin: 1rem 2rem;
		max-width: 460px;
		width: auto;
		font-size: 1.4rem;

		.input-label,
		.select-label {
			font-weight: 700;
			font-size: 1.4rem;

			&__comment {
				font-weight: 400;
				font-style: italic;
			}
		}
	}

	.input-label {
		font-weight: 700;
		font-size: 1.4rem;
	}

	.flex-rows {
		display: flex;
		flex-direction: row;
		max-width: 630px;
	}

	.with-divider {
		border-top: 1px solid $grayscale-200;
		margin-top: 1rem;
		padding-top: 2rem;
	}

	.edit-contact {
		font-size: 14px;
		color: $primary-500;
		padding: 2rem;
		cursor: pointer;

		svg {
			margin: 0 1rem 0 0;
		}
	}

	&__form-wrapper {
		.input-group {
			@include input-styles;
		}

		.select-container {
			@include input-styles;
		}

		.toggle-container {
			@include input-styles;
		}
	}

	&__phone-wrapper {
		display: flex;
		flex-direction: row;
		width: 500px;

		> * {
			padding-right: 0;
		}

		:first-child {
			flex-grow: 2;
		}

		:nth-child(2) {
			flex-shrink: 1;
		}

		@media (min-width: 200px) and (max-width: 575px) {
			flex-direction: column;
			width: auto;
		}
	}

	&__input-description {
		font-size: 1.4rem;
		max-width: 460px;
		margin: 0 2rem;
		text-align: justify;
	}

	&__right-section {
		font-size: 1.4rem;
		max-width: 460px;
		margin: 0 2rem;
		text-align: justify;
	}

	&__buttons {
		margin: 2rem;
		max-width: 460px;
		width: auto;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		@media (min-width: 200px) and (max-width: 575px) {
			flex-direction: column-reverse;
		}
	}

	&__button {
		min-width: 160px;
		padding: 10px 0;
		margin: 0.75rem;
		border-radius: 30px;
		border: 1px solid $black;
		font-weight: 600;

		@media (min-width: 200px) and (max-width: 575px) {
			width: 100%;
			margin: 0.75rem 0;
		}

		&.secondary-button {
			background-color: $ultra-white;

			&:disabled {
				background-color: $grayscale-100;
				border-color: $grayscale-100;
				color: $ultra-white;
			}
		}

		&.primary-button {
			color: $ultra-white;
			border-color: $primary-500;
			background-color: $primary-500;

			&:disabled {
				background-color: $grayscale-100;
				border-color: $grayscale-100;
				color: $ultra-white;
			}
		}
	}
}

.notifications-section--enable-modal {
	&.confirm-modal {
		.modal-dialog {
			max-width: 520px;
		}
		.modal-content .modal-footer .primary-button {
			background-color: $primary-500;
			color: $ultra-white;
			border-color: $primary-500;
		}
	}
}
