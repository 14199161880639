@import './../../../stylesheets/variables.scss';

.facility-info-page {
	color: $black;
	min-height: 20rem;
	height: auto;
	padding-bottom: 100px;

	.facility-overview-header {
		background-color: $background-grey;
		display: flex;
		margin-bottom: 1.6rem;

		p {
			font-weight: 700;
			font-size: 3.1rem;
			line-height: 16px;
			padding-left: 1rem;
			margin-bottom: 1.2rem;
			margin-top: 1.2rem;
		}

		.edit {
			margin-left: 3rem;
			font-weight: 600;
			font-size: 1.4rem;
			letter-spacing: 0.25px;
			color: $new-purple;
			margin-top: 1rem;
			cursor: pointer;
		}
	}

	.facility-info-warning-box {
		border-radius: 0.8rem;
		padding: 1rem;
		border: 1px solid $dnr-red;
		background: rgba(157, 36, 36, 0.1);
		display: flex;
		font-size: 1.6rem;
		min-height: 7rem;
		align-items: center;

		p {
			margin: 1rem;
		}

		img {
			margin: 2rem;
		}
	}

	.facility-overview {
		margin: 2rem 0rem;

		.info-disclaimer {
			font-weight: 400;
			font-size: 1.6rem;
			line-height: 120%;
		}
	}

	.overview-info {
		display: flex;
		gap: 20px;
		margin-top: 2rem;
		margin-bottom: 2rem;
		min-width: 100%;

		.signature-required-box {
			p {
				font-weight: 600;
				font-size: 1.6rem;
			}

			input {
				min-width: 25rem;
				min-height: 3rem;
				margin: 0 auto;
				border: none;
				border: solid 0.1rem $black;
				border-radius: 0.5rem;
			}

			.door-code {
				width: 2rem;
				margin-left: 1rem;
			}
		}
	}

	.additional-details-section {
		margin-top: 2rem;
		margin-bottom: 2rem;

		.additional-information {
			display: flex;
			align-items: flex-start;
			margin-bottom: 1.6rem;

			span {
				margin-left: 0.75rem;
				font-size: 1.4rem;
				font-style: italic;
				font-weight: 400;
				line-height: 1.9rem;
				text-align: left;
			}
		}

		textarea {
			min-width: 80%;
			min-height: 10rem;
			margin: 0 auto;
			border: none;
			border: solid 0.1rem $black;
			border-radius: 0.5rem;
		}

		p {
			max-width: 80%;
			font-weight: 600;
			font-size: 1.6rem;

			span {
				float: right;
				font-weight: 400;
				font-size: 1.2rem;
			}
		}
	}

	.upload-file-section {
		margin-top: 2rem;
		margin-bottom: 2rem;
		p {
			font-size: 1.6rem;
			b {
				font-weight: 600;
			}
		}

		.file-type {
			margin-top: 0.6rem;
			font-weight: 400;
			font-size: 1.4rem;
			line-height: 1.6rem;
			color: $grey-info;
		}

		.upload-button {
			border: 1px solid $upload-blue;
			border-radius: 8px;
			background: white;
			color: $upload-blue;
			width: 14.3rem;
			height: 4rem;
			font-weight: 600;
			font-size: 1.4rem;
		}
	}

	.action-section {
		margin-top: 4rem;
		float: right;

		.button-action {
			width: 18rem;
			border-radius: 10rem;
			font-weight: 600;
			font-size: 1.7rem;
			height: 4.7rem;
			text-align: center;
			letter-spacing: 0.05em;
			margin-right: 4rem;
		}

		.save-changes {
			background: $medium-gray;
			border-color: $medium-gray;
			border: none;
			color: $white;
			cursor: not-allowed !important;

			&.active {
				cursor: pointer !important;
				background: $new-purple;
			}
		}

		.cancel-button {
			border: 2px solid $default-text-color;
			color: $default-text-color;
			background-color: $ultra-white;
		}
	}
}
