@import '../../../stylesheets/variables.scss';

.guarantee-slots-modal {
	.modal-dialog {
		max-width: 830px;
	}

	.modal-body > p {
		margin-top: 1rem;
		text-align: justify;
	}

	.guarantee-section {
		padding: 0.2rem 1rem;
		margin: 2rem 2.5rem;
		background-color: $purple;
		border-radius: 6px;
		text-align: center;

		.guarantee-bold {
			color: $default-purple;
			font-weight: bold;
		}

		.guarantee-italic {
			font-style: italic;
		}

		p {
			line-height: 2.4rem;
			margin: 1.4rem 0;
		}
	}
}
