@import './../../stylesheets/variables.scss';

.date-range-selector {
	.range-container {
		min-width: 235px;
		display: flex;
		justify-content: left;
		padding: 8px 16px 8px 12px;
		align-items: center;
		gap: 4px;
		border-radius: 4px;
		background: $primary-100;
		color: $primary-500;

		&:hover {
			cursor: pointer;
			color: $primary-800;
			font-weight: 600;
			letter-spacing: -0.07px;

			.range-date {
				margin-top: -0.5px;
			}
		}
	}
}

.date-range-selector-overlay.p-overlaypanel .p-overlaypanel-content {
	padding: 24px;

	.header {
		display: flex;
		align-items: center;
		padding-bottom: 24px;
		border-bottom: 1px solid $grayscale-100;

		.title {
			flex: 1;
			font-size: 2.4rem;
			font-weight: 700;
			line-height: 3.2rem; /* 133.333% */
			letter-spacing: -0.333px;
			color: $grayscale-900;
		}
	}

	.p-datepicker {
		padding: 24px 0;

		.p-datepicker-header {
			padding-bottom: 16px;

			.p-datepicker-prev,
			.p-datepicker-next {
				width: 30px;
				height: 30px;

				.p-datepicker-prev-icon,
				.p-datepicker-next-icon {
					width: 24px;
					height: 24px;
				}

				&:focus {
					box-shadow: none;
				}
			}

			.p-datepicker-title {
				.p-datepicker-month,
				.p-datepicker-year {
					font-size: 2rem;
					font-weight: 700;
					line-height: 2.4rem;
					color: $grayscale-900;
					margin: 0;
					padding: 2.5px;
				}
			}
		}

		.p-datepicker-calendar {
			font-size: 1.6rem;
			line-height: 2.4rem;
			color: $grayscale-600;

			.p-disabled {
				opacity: 0.2;
			}

			thead span {
				font-size: 1.6rem;
				font-weight: 600;
				line-height: 2.4rem;
				color: $grayscale-600;
			}

			td > span {
				width: 5.6rem;
				height: 5.6rem;
			}

			td > span.p-highlight,
			td.p-datepicker-today > span.p-highlight {
				border-radius: 0;
				color: $grayscale-700;

				&:has(.from) {
					border-top-left-radius: 50%;
					border-bottom-left-radius: 50%;
					border-top: 2px solid $grayscale-0;
					border-bottom: 2px solid $grayscale-0;
					text-decoration-color: $grayscale-0;
					background-color: $primary-100;
				}

				&:has(.to) {
					border-top-right-radius: 50%;
					border-bottom-right-radius: 50%;
					border-top: 2px solid $grayscale-0;
					border-bottom: 2px solid $grayscale-0;
					text-decoration-color: $grayscale-0;
					background-color: $primary-100;
				}

				&:has(.between) {
					border-top: 2px solid $grayscale-0;
					border-bottom: 2px solid $grayscale-0;
					text-decoration-color: $grayscale-600;
					background-color: $primary-100;

					span {
						background-color: $primary-100;
					}
				}

				&:has(.from-only) {
					border-radius: 50%;
				}

				.from,
				.to,
				.from-only {
					background-color: $primary-500;
					width: 100%;
					height: 100%;
					border-radius: 50%;
					font-weight: 700;
					color: $grayscale-0;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}

			.p-datepicker-today {
				& > span {
					background-color: inherit;
					text-decoration: none;
					font-weight: 700;

					&:hover {
						background-color: #e9ecef;

						> span {
							background-color: #e9ecef;
						}
					}
				}

				> span {
					background-color: inherit;
					color: inherit;

					&.p-highlight {
						background-color: $primary-500;
						color: $grayscale-0;
					}
				}
			}

			td:has(.p-highlight):first-of-type .p-highlight {
				border-top-left-radius: 50%;
				border-bottom-left-radius: 50%;
			}

			td:has(.p-highlight):last-of-type .p-highlight {
				border-top-right-radius: 50%;
				border-bottom-right-radius: 50%;
			}
		}

		&.p-datepicker-multiple-month {
			border-bottom: none;
			margin-bottom: 0;
		}
	}

	.pro-tip-banner {
		display: flex;
		align-items: center;
		gap: 16px;
		align-self: stretch;
		padding: 16px;
		border-radius: 8px;
		background-color: #d8eaff;
		font-size: 1.4rem;
		line-height: 2.4rem;
		letter-spacing: 0.25px;
		max-width: 875px;
	}

	.date-range-actions {
		padding-top: 24px;
		margin: 0;
		border-top: 1px solid $grayscale-100;

		&.date-range-actions-top {
			margin-top: 24px;
		}

		.p-button {
			display: flex;
			padding: 8px 16px;
			justify-content: center;
			align-items: center;
			gap: 4px;
			border-radius: 4px;
			background-color: $primary-500;

			&:hover {
				background-color: $primary-400;
			}

			.p-button-label {
				font-weight: 400;
				font-size: 1.4rem;
				line-height: 2.4rem; /* 171.429% */
				letter-spacing: -0.333px;
			}
		}
	}
}
