@import './../../stylesheets/variables.scss';

.custom-radio {
	.custom-radio-option {
		display: flex;
		align-items: center;
		margin: 10px 0;

		input {
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;

			display: inline-block;
			width: 2rem;
			height: 2rem;
			padding: 0.3rem;
			background-clip: content-box;
			border: 2px solid $light-grey;
			background-color: transparent;
			border-radius: 50%;

			&:checked {
				background-color: $new-purple;
				border-color: $new-purple;
			}
		}

		label {
			margin-left: 13px;
			margin-bottom: 0;
		}
	}
}
