.modal-actions .shift-details-header h4 span {
  font-size: 1.6rem;
  font-weight: 600;
  color: #121315; }

.modal-actions .shift-details-body h4 span {
  font-size: 1.4rem;
  font-weight: 600; }

.modal-actions .shift-details-body .shift-details-row {
  display: flex;
  flex-direction: row; }
  .modal-actions .shift-details-body .shift-details-row p {
    width: 50%; }

.modal-actions .shift-details-body p {
  font-size: 1.4rem;
  font-weight: 600; }
