@import './../../stylesheets/variables.scss';

.avatar {
	height: 4.2rem;
	width: 4.2rem;
	border-radius: 50%;
	font-weight: 600;
	font-size: 1.6rem;
	text-align: center;
	line-height: 4.2rem;
	vertical-align: 50%;

	&__large {
		height: 11.2rem;
		width: 11.2rem;
	}

	&.user-avatar {
		color: $black;
		background-color: $ultra-white;
	}
}
