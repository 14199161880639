@import './../../stylesheets/variables.scss';

.no-matching-shifts {
	&__container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		flex: 1;
		align-items: center;
		margin-bottom: 20rem;
	}

	&__title {
		font-size: 1.6rem;
		font-weight: bold;
	}

	&__subtitle {
		font-size: 1.6rem;
		font-weight: normal;
	}

	&__clear-button {
		font-size: 1.6rem;
		font-weight: 400;
		color: $red-500;
		cursor: pointer;
		border: none;
		background-color: $ultra-white !important;
		margin-top: 1.6rem;
	}
}
