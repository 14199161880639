.employee-details__wrapper {
  width: 40%;
  line-height: 2.4rem;
  padding-top: 0.4rem; }
  .employee-details__wrapper h3 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #323335; }
  .employee-details__wrapper h4 {
    font-weight: 600;
    line-height: 2.4rem;
    font-size: 1.4rem;
    color: #121315; }
  .employee-details__wrapper h2 {
    font-weight: 600;
    margin-bottom: 2.4rem;
    color: #323335; }
  .employee-details__wrapper .employee-details {
    gap: 2.4rem;
    margin-bottom: 2.4rem;
    border-bottom: 0.15rem solid #e7e7e7; }
    .employee-details__wrapper .employee-details .form-input-group {
      margin-bottom: 2.4rem; }
    .employee-details__wrapper .employee-details .form-input {
      height: 4rem;
      border-radius: 0.4rem;
      border: 1px solid #858586; }
      .employee-details__wrapper .employee-details .form-input .form-control:disabled {
        border: 1px solid #b6b6b7;
        background-color: #fff; }
    .employee-details__wrapper .employee-details .form-input.form-control:disabled {
      border: 1px solid #cececf;
      background-color: #fff;
      color: #cececf; }
    .employee-details__wrapper .employee-details .select {
      border-radius: 0.4rem;
      border: 1px solid #858586;
      box-shadow: none; }
    .employee-details__wrapper .employee-details .form-text {
      color: #323335;
      font-size: 1.2rem;
      font-weight: 400;
      letter-spacing: 0.022rem;
      margin-top: 0.8rem; }
    .employee-details__wrapper .employee-details .licenses-wrapper {
      border-bottom: 0.15rem solid #e7e7e7;
      padding-bottom: 2.4rem; }
    .employee-details__wrapper .employee-details .input-group {
      display: flex;
      flex-direction: row;
      gap: 1.6rem;
      border-bottom: 0.15rem solid #e7e7e7; }
    .employee-details__wrapper .employee-details .location-input-group {
      flex: 1; }
    .employee-details__wrapper .employee-details .form-label {
      color: #323335;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      margin-bottom: 0.8rem; }
    .employee-details__wrapper .employee-details .form-label.disabled {
      color: #cececf; }
    .employee-details__wrapper .employee-details .invalid-feedback-custom {
      display: block;
      width: 100%;
      margin-top: 0.25rem;
      font-size: 80%;
      color: #dc3545; }
  .employee-details__wrapper .form-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1.6rem; }
    @media (min-width: 200px) and (max-width: 575px) {
      .employee-details__wrapper .form-actions {
        flex-direction: column-reverse; } }
    .employee-details__wrapper .form-actions__button {
      padding: 10px 0;
      font-weight: 600; }
      @media (min-width: 200px) and (max-width: 575px) {
        .employee-details__wrapper .form-actions__button {
          width: 100%;
          margin: 0.75rem 0; } }
      .employee-details__wrapper .form-actions__button.secondary-button {
        background-color: #ffffff;
        border: none;
        color: #7200a0;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem;
        display: flex;
        padding: 0.8rem 1.6rem;
        justify-content: center;
        align-items: center;
        gap: 0.4rem; }
        .employee-details__wrapper .form-actions__button.secondary-button:disabled {
          background-color: #e7e7e7;
          border-color: #e7e7e7;
          color: #ffffff; }
      .employee-details__wrapper .form-actions__button.primary-button {
        color: #ffffff;
        border-color: #7200a0;
        background-color: #7200a0;
        border-radius: 0.4rem;
        display: flex;
        padding: 0.8rem 1.6rem;
        justify-content: center;
        align-items: center;
        gap: 0.4rem;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.4rem; }
        .employee-details__wrapper .form-actions__button.primary-button:disabled {
          background-color: #e7e7e7;
          border-color: #e7e7e7;
          color: #ffffff; }
