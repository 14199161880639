@import './../../../stylesheets/variables.scss';

#tooltip-hcp-search {
	opacity: 1;

	.tooltip-inner {
		background-color: $background-black;
		color: $ultra-white;
		font-size: 1.4rem;
	}
}

.dnr-page {
	min-height: 100%;

	.search-section {
		min-height: 50rem;
		.dnr-warning {
			border-radius: 0.8rem;
			padding: 1rem;
			border: 1px solid $dnr-red;
			background: rgba(157, 36, 36, 0.1);
			display: flex;
			font-size: 1.6rem;
			min-height: 7rem;
			align-items: center;

			p {
				margin: 1rem;
			}

			img {
				margin: 2rem;
			}
		}

		.section-title {
			margin-top: 4rem;
			font-size: 2rem;
			font-weight: 700;
		}

		.copy-info {
			font-weight: 600;
			font-size: 1.4rem;
		}

		.selects-section {
			display: flex;
			margin-top: 2rem;
			justify-content: space-between;

			.column {
				display: inline-block;
				min-width: 25rem;
			}

			b {
				font-size: 2rem;
			}

			.select-title {
				font-size: 1.6rem;
				font-weight: 600;
				margin-bottom: 1rem;
			}

			.dnr-reasons {
				margin-top: 3rem;
			}
		}

		.search-bar-div {
			width: 30rem;

			.search-hcp-icon {
				width: 1rem;
				margin-left: 0.2rem;
				padding-bottom: 1rem;
			}
		}

		.submit-dnr {
			margin-top: 5rem;
			margin-bottom: 2rem;
			padding: 1.2rem 1rem;
			background: $dnr-caregiver-picture-border;
			border-radius: 2rem;
			font-weight: 600;
			font-size: 1.6rem;
			color: #ffffff;
			border: none;
			width: 25rem;
			cursor: not-allowed;

			&.active {
				background: $new-purple;
				cursor: pointer;
			}
		}

		.hcp-picture-placeholder {
			float: right;
			background: #f5f5f5;
			border: 1px solid $dnr-caregiver-picture-border;
			border-radius: 8px;
			height: 16rem;
			width: 16rem;
		}

		.hcp-picture-not-found {
			display: flex;
			flex-wrap: wrap;
			background: #f5f5f5;
			border: 1px solid $dnr-caregiver-picture-border;
			border-radius: 8px;
			height: 16rem;
			width: 16rem;

			.container {
				justify-content: center;

				img {
					margin-top: 10px;
					width: 100%;
				}

				p {
					margin-top: 3px;
					color: $medium-gray;
					text-align: center;
					font-weight: 700;
				}
			}
		}

		.selected-caregiver-name {
			margin-top: 9px;
		}

		.warning {
			margin-top: 0.5rem;
			color: $dnr-red;
			visibility: hidden;
			font-style: italic;

			&.display {
				visibility: visible;
			}
		}
	}

	.dnr-list-section {
		.section-title {
			font-size: 2.4rem;
			font-weight: 600;
			margin-bottom: 4rem;
		}
	}

	.dnr-hcp {
		background-color: $new-purple;
		color: $ultra-white;
		padding: 1px 8px;
		margin-left: 5px;
		border-radius: 10px;
		font-weight: bold;
	}
}

.dnr-modal {
	font-size: 1.6rem;
	padding: 16px;
	border: 4px solid $border-color;
	border-radius: 8px;
	line-height: 2.2rem;

	.dnr-warning-body {
		margin-bottom: 2rem;
		margin-top: 3rem;
	}
	.dnr-list-section {
		height: 70%;
	}
}

.search-bar-dnr {
	.search-hcp-icon {
		width: 1rem;
		margin-left: 0.2rem;
		padding-bottom: 1rem;
	}

	.input {
		border-radius: 0.8rem;
		min-height: 38px;
		border: 1px solid $medium-gray;
	}

	.search-bar__results-list {
		border: 1px solid $new-purple;
		box-shadow: 0 5px 10px $dnr-tooltip-border;
		border-radius: 0.8rem;
		padding: 3px;
		max-height: 25rem;
		overflow: auto;

		.search-bar__autocomplete-item {
			background-color: $ultra-white;
			color: $black;

			&:hover {
				background-color: $white;
			}
		}
	}
}

.select-dnr {
	.has-error {
		.sm__control {
			border: 1px solid $dnr-red;
		}
	}

	.sm__control {
		border-radius: 0.8rem;
		min-height: 38px;
		border: 1px solid $medium-gray;
		box-shadow: none;

		&.sm__control--is-focused {
			border: 1px solid $new-purple;
		}
	}

	.sm__menu {
		border-radius: 0.8rem;
		border: 1px solid $new-purple;

		.sm__option--is-focused,
		.sm__option--is-selected {
			background-color: $white;
			color: $black;
			font-weight: 400;
		}
	}
}
