@import '../../../../stylesheets/variables.scss';

.modal-assign-professionals {
	.shift-details-body {
		.shift-details-row {
			display: flex;
			flex-wrap: nowrap;
			gap: 16px;

			.shift-details-row-div {
				margin-bottom: 16px;
				padding: 8px 16px;
				border-radius: 4px;
				background: #f9f9f9;

				p {
					margin-bottom: 0px !important;
				}

				.shift-details-title {
					color: #000 !important;
					font-family: 'Open Sans', sans-serif !important;
					font-size: 14px !important;
					font-style: normal !important;
					font-weight: 400 !important;
					line-height: 24px !important;
				}

				.shift-details-text {
					color: #000 !important;
					font-family: 'Open Sans', sans-serif !important;
					font-size: 14px !important;
					font-style: normal !important;
					font-weight: 600 !important;
					line-height: 24px !important;

					.time-period {
						.break-duration {
							font-style: italic;
						}
					}

					.shift-type {
						.guaranteed-badge {
							transform: translateY(-24px);
						}
					}
				}
			}

			.col-50 {
				flex: 0 0 49%; // 49% due to the gap size.
				max-width: 49%;
			}

			.col-25 {
				flex: 0 0 23.5%;
				max-width: 23.5%; // 23.5% due to the gap size.
			}
		}
	}
}
