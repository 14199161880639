.cancel-shift-modal .modal-content {
  min-height: 480px; }
  .cancel-shift-modal .modal-content .modal-body {
    padding-top: 2rem; }
    .cancel-shift-modal .modal-content .modal-body hr {
      margin: 2rem 0; }
    .cancel-shift-modal .modal-content .modal-body .warning-professional {
      background: #ffe3a4;
      padding: 10px;
      display: flex;
      gap: 1.8rem;
      margin-bottom: 2.5rem; }
      .cancel-shift-modal .modal-content .modal-body .warning-professional span {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.1rem;
        letter-spacing: 0em;
        text-align: left; }
    .cancel-shift-modal .modal-content .modal-body .cancellation-information {
      display: flex;
      flex-direction: column;
      gap: 1rem; }
      .cancel-shift-modal .modal-content .modal-body .cancellation-information .sub-title {
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 2.2rem; }
      .cancel-shift-modal .modal-content .modal-body .cancellation-information .shift-details-container {
        display: flex;
        align-items: center; }
        .cancel-shift-modal .modal-content .modal-body .cancellation-information .shift-details-container .cancellation-info {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 2.2rem;
          padding: 0 8px;
          border-right: 1px solid #e7e7e7; }
        .cancel-shift-modal .modal-content .modal-body .cancellation-information .shift-details-container .cancellation-info:last-child {
          border-right: none; }
    .cancel-shift-modal .modal-content .modal-body .sm__control,
    .cancel-shift-modal .modal-content .modal-body .sm__menu {
      border: 2px solid #7200a0;
      box-shadow: none; }
      .cancel-shift-modal .modal-content .modal-body .sm__control .sm__option--is-focused,
      .cancel-shift-modal .modal-content .modal-body .sm__menu .sm__option--is-focused {
        background-color: #edecfb;
        color: #7200a0; }
      .cancel-shift-modal .modal-content .modal-body .sm__control .sm__option--is-selected,
      .cancel-shift-modal .modal-content .modal-body .sm__menu .sm__option--is-selected {
        background-color: #edecfb;
        color: #7200a0; }
    .cancel-shift-modal .modal-content .modal-body .reason-section .reason-feedback .reason-feedback-text input {
      width: 100%;
      border: 2px solid #7200a0;
      min-height: 40px;
      border-radius: 4px;
      padding: 2px 8px; }
    .cancel-shift-modal .modal-content .modal-body .acknowledgment-checkbox {
      display: flex;
      align-items: center;
      margin-top: 23px; }
      .cancel-shift-modal .modal-content .modal-body .acknowledgment-checkbox > label {
        cursor: pointer;
        margin: 0; }
      .cancel-shift-modal .modal-content .modal-body .acknowledgment-checkbox .custom-checkbox-control {
        border-width: 2px; }
    .cancel-shift-modal .modal-content .modal-body .error-message {
      margin-top: 2.3rem;
      font-size: 1.4rem;
      color: #a02e44; }
  .cancel-shift-modal .modal-content .modal-footer {
    border-top: none; }

.cancel-shift-modal .guaranteed-span {
  color: #00a698; }

@media all and (max-width: 575px) {
  .cancel-shift-modal .modal-content {
    min-height: unset; }
    .cancel-shift-modal .modal-content .modal-body .row .col-4,
    .cancel-shift-modal .modal-content .modal-body .row .col-12,
    .cancel-shift-modal .modal-content .modal-body .row .col-6 {
      padding-top: 7px; }
    .cancel-shift-modal .modal-content .modal-body .guaranteed-span {
      font-size: 1rem; }
    .cancel-shift-modal .modal-content .modal-body .reason-section .reason-feedback {
      margin-top: 15px; }
    .cancel-shift-modal .modal-content .modal-body .cancellation-information .shift-details-container {
      align-items: flex-start;
      flex-flow: column; }
      .cancel-shift-modal .modal-content .modal-body .cancellation-information .shift-details-container .cancellation-info {
        border-right: none; } }
