.available-professionals__container {
  display: flex;
  flex-direction: column;
  height: 30vh; }
  .available-professionals__container__subtitle {
    font-size: 10px;
    font-weight: 400; }
  .available-professionals__container__form {
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow-y: scroll; }
    .available-professionals__container__form__item {
      display: flex !important;
      flex-direction: column !important;
      justify-content: center !important;
      padding-left: 24px !important;
      border: 1px #d9d9d9 solid;
      border-radius: 8px;
      margin: 8px 8px 4px 8px; }
    .available-professionals__container__form__item input[type='radio'] {
      accent-color: #000000; }
  .available-professionals__container__form[disabled] {
    pointer-events: none;
    opacity: 0.3; }
  .available-professionals__container__empty {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 0px 16px;
    font-size: 14px;
    font-weight: 400; }

.available-professionals__container__form::-webkit-scrollbar {
  width: 4px; }

/* Handle */
.available-professionals__container__form::-webkit-scrollbar-thumb {
  background: #888; }

/* Handle on hover */
.available-professionals__container__form-list::-webkit-scrollbar-thumb:hover {
  background: #555; }
