@import './../../../stylesheets/variables.scss';

.edit-assignment {
	width: 100%;
	margin: 1rem 0 0 0;

	.edit-assignment-button-wrapper {
		display: block;
		text-wrap: nowrap;
		margin-top: 1rem;
		justify-content: center;
	}

	.edit-assignment-button {
		max-width: 140px;
		height: 3.2rem;
		color: $black;
		background-color: $transparent;
		border: 1px solid $black;
		border-radius: 3rem;
		text-align: center;
		padding: 0 1rem;
		flex-grow: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		&.main-action {
			border-radius: 3rem 0 0 3rem;
			flex-grow: 2;
			padding-right: 0.5rem;
			max-width: 130px;
		}

		&.dropdown {
			border-radius: 0 3rem 3rem 0;
			flex-grow: 0;
			border-left: none;
			overflow: hidden;
			text-overflow: unset;
		}
	}

	.dropdown-item {
		background-color: $ultra-white;
		border: none;
		border-radius: 0.2rem;
		text-align: left;
		height: 3.2rem;
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		width: 165px;
		z-index: 1;
		box-shadow: 0px 2px 2px 0px $grayscale-300;

		&.hide {
			display: none;
		}
	}
}

.unit-assignment {
	width: 100%;
	font-size: 1.4rem;
}
