@import './../../../stylesheets/variables.scss';

.modal-content {
	border-radius: 8px;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.notification-popup-modal {
	.common-font {
		font-family: Open Sans, serif;
		font-style: normal;
		font-weight: normal;
		font-size: 1.2rem;
		line-height: 1.6rem;
		color: $text-grey;
	}

	&-title {
		display: flex;
		justify-content: center;
		align-items: center;

		.title-font {
			font-weight: bold;
			font-size: 2.4rem;
			line-height: 2.2rem;
			text-align: center;
			padding: 30px;
			width: 100%;
		}
	}

	&-body {
		box-sizing: border-box;
		padding: 10px 48px 16px 48px;
		display: flex;
		align-items: center;
		width: 100%;

		.body-text {
			border-radius: 8px;
			border: 4px solid $border-color;
			padding: 10px;
			font-size: 1.6rem;
			line-height: 2.2rem;

			&-light {
				@extend .body-text;
				border: none;
				padding: 10px 0;

				b {
					font-weight: 600;
				}

				.facility-name {
					font-weight: 700;
				}
			}
		}
	}

	&-footer {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding: 0 48px 11px 48px;

		.buttons-block {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-bottom: 10px;

			.confirm-button {
				background-color: $new-purple;
				box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.03);
				font-size: 1.6rem;
				line-height: 2.2rem;
				color: #ffffff;
			}

			.close-button {
				background-color: #ffffff;
				border: 1px solid $black;
				box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.03);
				color: $black;
			}

			.custom-button {
				flex-grow: 1;
				max-width: 50%;
				font-weight: bold;
				font-size: 1.6rem;
				line-height: 2rem;
				display: flex;
				align-items: center;
				text-align: center;
				text-transform: uppercase;
				justify-content: center;
				height: 36px;
				border-radius: 20px;

				&:hover {
					opacity: 0.8;
				}
			}
		}
	}

	&-image {
		height: 100px;
	}

	.close {
		font-size: 3rem;
		display: flex;
		justify-content: flex-end;
		padding-right: 20px;
	}

	@media (max-width: 575px) {
		&-title {
			.title-font {
				padding: 15px;
			}
		}

		&-body {
			padding: 5px 24px 8px 24px;

			.body-text {
				border-radius: 6px;
				border: 3px solid $border-color;
				padding: 5px;
			}
		}

		&-footer {
			padding: 0 24px 8px 24px;

			.buttons-block {
				padding-bottom: 5px;

				.confirm-button {
					margin-left: 5px;
				}

				.close-button {
					margin-right: 5px;
				}

				.custom-button {
					height: 40px;
					border-radius: 6px;
				}
			}
		}
	}
}

@media (max-width: 575px) {
	.notification-popup-modal {
		display: none !important;

		&-backdrop {
			display: none !important;
		}
	}
}
