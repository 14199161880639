@import './../../stylesheets/variables.scss';

.settings-container {
	width: 100%;
	height: 100vh;
	overflow-y: scroll;
	font-family: 'Open Sans';
	margin-bottom: 4rem;

	.settings-page {
		display: flex;
	}
}
