@import './../../stylesheets/variables.scss';

.toggle-container {
	display: flex;
	flex-direction: row;
	cursor: pointer;
	position: relative;
	-webkit-user-select: none;
	user-select: none;
	justify-content: space-between;
	align-items: center;

	.toggle-slider {
		width: 30px;
		height: 18px;
		background-color: $grayscale-900;
		border-radius: 10px;
		transition: background-color 0.3s;

		&--disabled {
			background-color: $grayscale-300;
		}

		&--checked {
			background-color: green;
		}
	}

	.toggle-circle {
		width: 14px;
		height: 14px;
		background-color: $grayscale-0;
		border-radius: 50%;
		position: absolute;
		top: 50%;
		transition: left 0.3s;
		left: calc(100% - 30px + 2px);
		transform: translateY(-7px);

		&--checked {
			left: calc(100% - 16px);
		}
	}
}
