@import './../../stylesheets/variables.scss';

.tier-badge {
	align-self: baseline;
	width: 30px;
	margin-left: 1rem;

	@media screen and (min-width: 768px) {
		width: 42px;
		position: absolute;
		top: 0;
		right: 0;
	}
}

.tier-badge-tooltip {
	.tooltip-inner {
		background-color: $background-black;
		color: $ultra-white;
		font-size: 1.4rem;
		text-align: left;
	}
	.arrow {
		display: none !important;
	}
}
