.custom-checkbox {
  display: grid;
  grid-template-columns: min-content auto;
  grid-gap: 0.5em;
  font-size: 2rem;
  color: var(rebeccapurple);
  margin: 0; }
  .custom-checkbox--disabled {
    color: var(#959495);
    cursor: not-allowed; }

.custom-checkbox-control {
  display: inline-grid;
  width: 1em;
  height: 1em;
  border: 1px solid #4811a3;
  background-color: white;
  color: white;
  border-radius: 4px;
  cursor: pointer; }
  .custom-checkbox-control svg {
    transform: scale(0);
    fill: white; }

.custom-checkbox-container {
  display: grid;
  grid-template-areas: 'checkbox'; }
  .custom-checkbox-container > * {
    grid-area: checkbox; }
  .custom-checkbox-container input {
    opacity: 0;
    width: 1em;
    height: 1em;
    cursor: pointer; }
    .custom-checkbox-container input:checked + .custom-checkbox-control {
      background-color: #531362; }
    .custom-checkbox-container input:checked + .custom-checkbox-control svg {
      transform: scale(1); }
    .custom-checkbox-container input:disabled + .custom-checkbox-control, .custom-checkbox-container input:disabled {
      color: var(#959495);
      cursor: not-allowed; }
