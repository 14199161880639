@import './../stylesheets/variables.scss';

.top-selected-menu-bar {
	gap: 1rem;
	margin: 0 4rem;
	padding-top: 2rem;
	min-height: 5.25rem;
	max-width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	&.daily-schedule {
		margin-top: 5rem;
	}

	.right-content {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;
	}

	&-label {
		color: $soft-purple;
		font-size: 1.8rem;
		font-weight: bold;
		padding: 0 2rem;
		white-space: nowrap;
	}

	&-right {
		color: $red-label;
		font-size: 1.6rem;
		font-weight: bold;
		padding: 0;
		margin-right: 2rem;
	}
}

@media (max-width: 1100px) {
	.top-selected-menu-bar {
		margin: 0 1.5rem 2.5rem 1.5rem;

		&.daily-schedule {
			margin-top: 5rem;
		}
	}
}

.facility-receivable-status-banner {
	min-height: 5rem;
	width: 100%;
	display: flex;
	align-items: center;
	background-color: $banner-yellow;
	padding: 10px 26px;
	gap: 20px;
	overflow: auto;

	img {
		transform: rotate(180deg);
	}

	.label {
		font-size: 1.5rem;
		font-weight: 600;
	}
}

@media (min-width: 200px) and (max-width: 575px) {
	.top-selected-menu-bar,
	.right-content {
		flex-direction: column;
		height: auto;
		width: 100%;
		margin-left: 0;
		padding-bottom: 1rem;
	}
}
