@import './../../../stylesheets/variables.scss';

.section-caregiver-search {
	.search-invite {
		flex-direction: row;

		input {
			height: 44px;
			border-left: none;
			color: #979797;
			font-size: 1.4rem;
		}

		input::placeholder {
			font-size: 1.4rem;
			font-weight: 600;
		}
	}

	.search-icon {
		background-color: white;
		border-right: none;
		color: #979797;
		padding-left: 12px;
		content: url('../../../assets/images/search-invite.svg');
		display: inline-block;
		border-radius: 0.25rem 0 0 0.25rem;
	}

	.searched-caregivers {
		.list-search-caregivers {
			list-style-type: none;
			padding: 0;
			button {
				padding-top: 8px;
				padding-bottom: 8px;
				.platinum-badge-caregiver {
					margin-left: auto;
				}
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				font-weight: 600;
				background-color: white;
				border: 1px solid #e7e7e7;
			}
			button:hover {
				background-color: rgb(241, 241, 241);
			}
		}
	}

	.search-professional-info-container {
		margin-top: 12px;
		display: flex;
		flex-direction: row;
		.info-icon {
			position: relative;
			content: url('../../../assets/images/important.svg');
		}
		p {
			font-style: italic;
			font-weight: 600;
			font-size: 1.4rem;
			color: #ef853b;
			padding-top: 8px;
			margin-left: 12px;
		}
	}

	.selected-professionals-body {
		height: 200px;
		overflow-y: scroll;
		scrollbar-width: none;
		-ms-overflow-style: none;

		.list-selected-caregivers {
			list-style-type: none;
			padding: 0;
			.selected-caregiver-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 16px;

				li {
					padding: 8px;
					.platinum-badge-caregiver {
						margin-left: auto;
					}
					width: 100%;
					display: flex;
					flex-direction: row;
					align-items: center;
					font-weight: 600;
					background-color: white;
					border: 1px solid #e7e7e7;
					border-radius: 4px;
				}
			}
		}
	}
	.selected-professionals-body-list {
		height: 200px;
		overflow-y: scroll;
		.list-selected-caregivers {
			list-style-type: none;
			padding: 0;
			.selected-caregiver-container {
				display: flex;
				flex-direction: row;
				button {
					border: none;
					background-color: white;
				}
				li {
					padding: 8px;
					.platinum-badge-caregiver {
						margin-left: auto;
					}
					width: 100%;
					margin-right: 1.7rem;
					display: flex;
					flex-direction: row;
					align-items: center;
					font-weight: 600;
					background-color: white;
					border: 1px solid #e7e7e7;
					border-radius: 4px;
				}
				span {
					font-weight: 400;
					b {
						font-weight: 700;
						&.caregiver-name {
							font-weight: 600;
						}
					}
				}
				&.accepted {
					li {
						background-color: rgb(224, 247, 224);
						box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
					}
				}
			}
		}
	}
}

.selected-professionals-body::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.selected-professionals-body::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.selected-professionals-body::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on hover */
.selected-professionals-body-list::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.selected-professionals-body-list::-webkit-scrollbar {
	width: 4px;
}

/* Track */
.selected-professionals-body-list::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
.selected-professionals-body-list::-webkit-scrollbar-thumb {
	background: #888;
}
