@import './../../stylesheets/variables.scss';

.pagination-container {
	display: flex;
	list-style-type: none;
	justify-content: center;
	margin-top: 2rem;
	height: 50%;
	.pagination-item {
		padding: 0 12px;
		height: 3rem;
		text-align: center;
		color: rgba(0, 0, 0, 0.87);
		display: flex;
		box-sizing: border-box;
		align-items: center;
		letter-spacing: 0.01071em;
		line-height: 1.43;
		font-size: 1.3rem;
		min-width: 2rem;
		border-bottom: solid 1px $soft-grey-border;
		border-top: solid 1px $soft-grey-border;

		&:first-child {
			border-left: solid 1px $soft-grey-border;
			border-radius: 10px 0 0 10px;
			img {
				margin-right: 1rem;
			}
		}

		&:last-child {
			border-right: solid 1px $soft-grey-border;
			border-radius: 0 10px 10px 0;
			img {
				margin-left: 1rem;
			}
		}

		&.dots:hover {
			background-color: transparent;
			cursor: default;
		}
		&:hover {
			background-color: rgba(0, 0, 0, 0.04);
			cursor: pointer;
		}

		&.selected {
			background-color: rgba(0, 0, 0, 0.08);
		}

		&.disabled {
			pointer-events: none;

			.arrow::before {
				border-right: 0.12em solid rgba(0, 0, 0, 0.43);
				border-top: 0.12em solid rgba(0, 0, 0, 0.43);
			}

			&:hover {
				background-color: transparent;
				cursor: default;
			}
		}
	}
}

.pagination-info {
	display: flex;
	justify-content: center;
}
