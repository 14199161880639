@import './../../stylesheets/variables.scss';

.dropdown-component {
	box-shadow: 0px 2px 2px 0px $box-shadow;
	z-index: 1000;
	background-color: $ultra-white;
	border-radius: 4px;
	border: 1px solid $line-divider;
	width: auto;
	min-width: 250px;
	max-width: 400px;
	position: absolute;
	top: 5.4rem;
	color: $off-black;
	font-size: 16px;
	overflow-wrap: break-word;
	display: none;
	flex-direction: column;

	&.open {
		display: flex;
	}

	ul {
		padding: 1rem 0;
		margin: 0;
		list-style: none;
		text-align: left;
		background-color: $secondary-100;

		li {
			line-height: 1.6rem;
			padding: 1.2rem 2.4rem 0 2.4rem;

			&.bold {
				font-weight: 600;
			}

			&:last-of-type {
				padding-bottom: 1.2rem;
			}
		}
	}
	button {
		width: auto;
		height: auto;
		background: none;
		border: none;

		&.menu-button {
			width: 100%;
			padding: 1.5rem 1rem 1.25rem 2rem;
			font-weight: 600;
			text-align: left;
			background-color: $ultra-white;
			span {
				margin-left: 0.7rem;
			}
			svg {
				vertical-align: bottom;
			}
			&.selected {
				background-color: $grayscale-100;
			}
		}

		&.disabled {
			color: $grayscale-300 !important;
		}
	}
}
