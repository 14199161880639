@import './../../stylesheets/variables.scss';

.tooltip-component {
	&--overlay {
		.tooltip-inner {
			font-size: 1rem;
			background-color: $grayscale-900;
			border-radius: 0.4rem;
			padding: 1rem;
		}
	}
}
