.selected-professionals-body .list-selected-caregivers {
  list-style-type: none;
  padding: 0; }
  .selected-professionals-body .list-selected-caregivers .selected-caregiver-container {
    display: flex;
    flex-direction: row; }
    .selected-professionals-body .list-selected-caregivers .selected-caregiver-container button {
      border: solid #e7e7e7;
      border-width: 1px 1px 1px 0px;
      border-radius: 0px 4px 4px 0px;
      background-color: white; }
    .selected-professionals-body .list-selected-caregivers .selected-caregiver-container li {
      padding: 8px;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      border: 1px solid #e7e7e7;
      border-radius: 4px 0px 0px 4px; }
