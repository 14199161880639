@import '../../../stylesheets/variables.scss';

.guarantee-bundle-modal {
	.modal-dialog {
		max-width: 830px;
	}

	.modal-body > p {
		margin-top: 1rem;
		text-align: justify;
	}

	.mobile-button-container {
		@media (min-width: 576px) {
			display: none;
		}
	}
	.expand-table-buttons {
		min-width: 160px;
		padding: 10px 20px 10px 20px;
		border-radius: 30px;
		border: none;
		font-weight: 600;
		margin: 0.75rem;
		color: $primary-800;

		@media (min-width: 200px) and (max-width: 575px) {
			width: 100%;
		}

		&.secondary-button {
			background-color: $ultra-white;

			&:disabled {
				background-color: $grayscale-100;
				border-color: $grayscale-100;
				color: $ultra-white;
			}
		}

		&.primary-button {
			color: $ultra-white;
			border-color: $primary-500;
			background-color: $primary-500;

			&:disabled {
				background-color: $grayscale-100;
				border-color: $grayscale-100;
				color: $ultra-white;
			}
		}
	}

	.guarantee-section {
		@media (max-width: 575px) {
			display: none;
		}

		padding: 1rem 1rem;
		margin: 2rem 0;
		background-color: $purple;
		border-radius: 6px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		width: 100%;
		height: 100%;

		.guarantee-bold {
			color: $default-purple;
			font-weight: bold;
		}

		p {
			line-height: 2.4rem;
			margin: 0.5rem 0;
		}

		.section-icon {
			margin: 0 1.5rem 0 1rem;
		}
	}

	.info-header-container {
		@media (max-width: 575px) {
			display: flex;
			flex-direction: column;
			&.hidden {
				display: none;
			}
		}

		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: 1.3rem;

		.info-header-section {
			@media (max-width: 575px) {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: space-between;
			}
			width: 30%;
			height: 100%;
			background-color: $grayscale-25;
			padding: 0.5rem 1rem;

			p {
				line-height: 2.4rem;
				margin: 0.2rem 0;
			}
		}
	}

	.table-container {
		height: 280px;
		overflow-y: auto;
		border: 1px solid #ccc;
		margin: 2rem 0;

		&.expanded {
			height: 100%;
		}

		table {
			width: 100%;
			border-collapse: collapse;
		}

		th,
		td {
			padding: 1rem;
			text-align: left;
			border-bottom: 1px solid #ddd;
		}

		th {
			border-bottom: 1px solid $default-purple;
		}

		td.guarantee-icon {
			text-align: center;
		}
		svg {
			display: block;
			margin: auto;
		}

		th {
			background-color: $grayscale-50;
			font-weight: normal;
			font-size: 1.3rem;
		}

		th:nth-child(3) {
			width: auto;
		}

		th:nth-child(3) {
			width: 90px;
		}

		tr:nth-child(even) {
			background-color: $grayscale-25;
		}
	}

	.aknowledgement-section {
		&.hidden {
			display: none;
		}

		display: flex;
		justify-content: flex-start;
		width: 100%;
		height: 100%;
	}
}

.remove-guarantee {
	.modal-content .modal-footer .footer-buttons.primary-button {
		background-color: $red-500;
	}
}
