@import './../../stylesheets/variables.scss';

.search-caregivers-container {
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
	width: 100%;
	background-color: $ultra-white;

	&.active {
		outline: 1.5px solid $new-purple !important;
	}

	.clear-button {
		font-size: 1.4rem;
		font-weight: 600;
		color: $black;
		cursor: pointer;
		border: none;
		background-color: $ultra-white !important;
		text-decoration: underline;
	}

	.clear-button:hover {
		color: $new-purple;
		text-decoration: underline;
	}

	.search-caregivers {
		padding-right: 8px;
		flex-wrap: nowrap !important;
		flex-direction: row;

		.search-icon {
			border-radius: 0rem;
			background-color: $ultra-white;
			border: none;
			color: #979797;
			padding-left: 12px;
			content: url('../../assets/images/search-invite.svg');
			display: inline-block;
			opacity: 0.54;
		}

		input {
			height: 44px;
			border: none;
			color: #979797;
			font-size: 1.4rem;
		}

		input::placeholder {
			font-size: 1.4rem;
			font-weight: 600;
		}

		input.form-control:focus {
			outline: none !important;
			color: #979797;
			background-color: $ultra-white;
			border-color: #ced4da;
			box-shadow: none;
		}
	}

	.caregiver-search__avatar {
		height: 3rem;
		border-radius: 50%;
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.searched-caregivers {
		z-index: 1000;
		position: absolute;
		width: 100%;
		margin-top: 2px;

		&.hide {
			display: none !important;
		}

		.list-search-caregivers {
			list-style-type: none;
			padding: 0;

			li {
				padding: 8px;
				display: flex;
				align-items: center;
				font-weight: 600;
				background-color: $ultra-white;
				border: 1px solid $badge-grey;
				border-bottom: none;

				.platinum-badge-caregiver {
					margin-left: auto;
				}
			}

			li:last-child {
				border-bottom: 1px solid $badge-grey;
			}

			li:hover {
				background-color: rgb(241, 241, 241);
			}
		}
	}

	.selected-caregiver-container {
		align-items: center;
		display: flex;
		flex-grow: 1;
		height: 4.5rem;

		li {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			align-items: center;
			font-weight: 600;
			background-color: $badge-grey;
			border: 1px solid $badge-grey;
			border-radius: 0.4rem;

			.selected-caregiver-details {
				display: flex;
				align-items: center;
				gap: 1rem;
				line-height: 1;
				max-height: 3.6rem;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.remove-button {
				display: flex;
				gap: 1rem;

				button {
					border: none;
					background-color: $badge-grey;
					font-size: 2.3rem;
					width: 4.4rem;
					border-left: 1px solid $badge-grey;

					img {
						margin-bottom: 0.4rem;
					}
				}

				button:hover {
					background-color: rgb(169, 121, 121) !important;
					border-radius: 0 0.25rem 0.25rem 0;
				}
			}
		}
	}
}

@media (min-width: 200px) and (max-width: 575px) {
	.search-caregivers-container {
		width: 100%;
	}
}
