@import './../../stylesheets/variables.scss';

.portal-sidebar {
	border-right: 0.15rem solid $grayscale-100;
	margin-right: 2rem;
	font-size: 1.5rem;
	font-weight: 600;
	color: $grayscale-900;
	width: 200px;
	min-width: 200px;

	&__link {
		padding: 1rem 2rem;
		display: block;
		color: inherit;

		&:active,
		&.active {
			background-color: $grayscale-100;
		}

		&:hover {
			background-color: $grayscale-200;
			text-decoration: none;
			color: inherit;
		}
	}
}

@media (max-width: 992px) {
	.portal-sidebar {
		display: none;
	}
}
