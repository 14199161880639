.shift-requests-modal {
  color: #444444;
  font-size: 1.4rem; }
  .shift-requests-modal .modal-content {
    padding: 0; }
  .shift-requests-modal .modal-request-header {
    margin-right: 2rem;
    margin-left: 2rem; }
  .shift-requests-modal .modal-header {
    padding: 1.5rem 1rem 1rem 0;
    font-size: 2rem; }
    .shift-requests-modal .modal-header .modal-title {
      font-size: 1.6rem;
      font-weight: 600; }
    .shift-requests-modal .modal-header .close {
      font-size: 2rem;
      font-weight: 400;
      padding-right: 0; }
  .shift-requests-modal .modal-body {
    padding: 2rem 0;
    font-weight: 400;
    font-size: 1.4rem; }
  .shift-requests-modal .modal-footer {
    padding: 1.5rem 0;
    border-top: none;
    justify-content: center; }
    .shift-requests-modal .modal-footer .btn {
      padding: 5px 20px;
      width: 200px;
      height: 54px;
      font-size: 1.5rem;
      border-radius: 4px; }
    .shift-requests-modal .modal-footer .btn-secondary {
      margin-right: 0.5rem; }
    .shift-requests-modal .modal-footer .buttons {
      width: 100%;
      text-align: center; }
    .shift-requests-modal .modal-footer .single-shift-button {
      border: none;
      background-color: transparent;
      color: #4811a3;
      font-weight: 600;
      width: 152px;
      height: 54px; }
      .shift-requests-modal .modal-footer .single-shift-button:hover {
        text-decoration: underline; }
  .shift-requests-modal .request-shift-title {
    font-size: 3.5rem !important;
    font-weight: 550; }
  .shift-requests-modal .step-one {
    min-width: 23.5%;
    float: left; }
  .shift-requests-modal .step-two {
    width: 100%;
    padding-left: 4rem;
    float: right;
    border-left: 1px solid #dad8da; }
    .shift-requests-modal .step-two .guaranteed-section {
      padding: 0.2rem 1rem;
      background-color: rgba(187, 107, 217, 0.15);
      border-radius: 6px;
      width: 100%; }
      .shift-requests-modal .step-two .guaranteed-section .guaranteed-bold {
        color: #531362;
        font-weight: bold; }
      .shift-requests-modal .step-two .guaranteed-section .guaranteed-text {
        color: #531362; }
      .shift-requests-modal .step-two .guaranteed-section p {
        margin: 0 !important; }
  .shift-requests-modal .steps {
    display: flex;
    margin-top: 3rem;
    padding: 0 1.5rem; }
    .shift-requests-modal .steps .step-title {
      color: #444444;
      font-size: 2.5rem;
      font-weight: bold;
      text-align: left; }
    .shift-requests-modal .steps .step-sub {
      color: #757575;
      font-size: 1.5rem;
      padding-top: 1rem;
      text-align: left; }
  .shift-requests-modal .date-row {
    display: flex;
    margin-top: 2rem;
    padding: 1em 1em;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover !important; }
    .shift-requests-modal .date-row .shifts-section {
      width: 100%;
      padding-left: 4rem;
      float: right;
      border-left: 1px solid #dad8da; }
      .shift-requests-modal .date-row .shifts-section .bonus-selector {
        vertical-align: center;
        padding-right: 0.5rem; }
    .shift-requests-modal .date-row .last {
      padding-left: 4rem; }
  .shift-requests-modal .date-input-container {
    display: inline-block;
    position: relative; }
    .shift-requests-modal .date-input-container .day-string-date-picker {
      position: absolute;
      margin-top: 0.85rem;
      padding-left: 1rem;
      color: #757575;
      font-weight: bold; }
    .shift-requests-modal .date-input-container .input-shift-date-picker {
      color: #757575;
      padding-left: 4.5rem;
      width: 18rem; }
    .shift-requests-modal .date-input-container .arrow-wrapper {
      margin-left: 15rem;
      color: black !important;
      margin-top: -3.2rem; }
    .shift-requests-modal .date-input-container .arrow-down {
      border: 2px solid #cccccc;
      border-width: 0 2.5px 2.5px 0;
      display: inline-block;
      padding: 3px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg); }
  .shift-requests-modal .select-label {
    color: #757575;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding-bottom: 0 !important; }
  .shift-requests-modal .shift-row-grey-background {
    background-color: #f9fafc;
    background-repeat: no-repeat;
    background-size: 100% 100%; }
  .shift-requests-modal .req-shifts-button {
    margin-left: 2rem;
    margin-right: 2rem; }
  .shift-requests-modal .text-head {
    color: #757575;
    font-size: 1.4rem;
    font-weight: bold;
    text-align: left;
    margin-bottom: 0.5rem; }
  .shift-requests-modal .add-shift-button {
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #00b4c4;
    background-color: white;
    font-size: 1.1rem;
    border: 1.3px solid #00b4c4;
    width: 18rem;
    height: 3.5rem;
    border-radius: 4px;
    text-align: center; }
  .shift-requests-modal .add-date-button {
    color: #00b4c4;
    background-color: white;
    font-size: 1.1rem;
    border: 1.3px solid #00b4c4;
    width: 18rem;
    height: 3.5rem;
    border-radius: 4px;
    text-align: center;
    margin-top: 5rem; }
  .shift-requests-modal .date-section {
    min-width: 23.5%; }
  .shift-requests-modal .shift-required-separator {
    border-top: 1px solid #dbd9d9; }
  .shift-requests-modal .shift-options {
    width: 100%;
    display: flex; }
    .shift-requests-modal .shift-options .provider-label {
      color: #757575;
      font-weight: bold;
      margin-bottom: 0.5rem;
      padding-bottom: 0; }
    .shift-requests-modal .shift-options .provider-input {
      width: 100%;
      height: 3.8rem;
      border: 1px solid #cccccc;
      padding: 0 0.8em;
      border-radius: 4px; }
      .shift-requests-modal .shift-options .provider-input.has-error {
        border: 2px solid #9f2f45 !important; }
    .shift-requests-modal .shift-options .provider-input:focus {
      border: none;
      outline: none;
      box-shadow: 0 0 0 1.6px #2684ff; }
    .shift-requests-modal .shift-options .shift-selector {
      width: 18rem;
      margin-right: 3.5rem;
      margin-bottom: 1rem; }
      .shift-requests-modal .shift-options .shift-selector .bonus-remove {
        margin-top: 1.5rem;
        display: flex; }
      .shift-requests-modal .shift-options .shift-selector .guaranteed-shift {
        margin-top: 1rem;
        font-size: 1.2rem;
        display: flex;
        align-items: center; }
        .shift-requests-modal .shift-options .shift-selector .guaranteed-shift input {
          margin-right: 0.5rem; }
      .shift-requests-modal .shift-options .shift-selector .remove-section {
        display: flex;
        justify-content: center;
        cursor: pointer; }
      .shift-requests-modal .shift-options .shift-selector .remove-text {
        color: #ff03a0;
        font-size: 1.5rem;
        margin-left: 0.5rem;
        padding-top: 0.5rem;
        width: 4rem;
        text-align: left;
        float: right; }
      .shift-requests-modal .shift-options .shift-selector .remove-img {
        padding-bottom: 0.5rem;
        float: left; }
      .shift-requests-modal .shift-options .shift-selector .bonus-input {
        height: 3.3rem;
        width: 13rem;
        float: left; }
      .shift-requests-modal .shift-options .shift-selector .required-label {
        font-weight: bold;
        padding-top: 0.5rem;
        font-size: 1.3rem;
        color: #9f2f45;
        visibility: hidden; }
        .shift-requests-modal .shift-options .shift-selector .required-label.show {
          visibility: visible; }
  .shift-requests-modal .react-datepicker-wrapper {
    background-color: inherit; }
  .shift-requests-modal .react-datepicker-wrapper,
  .shift-requests-modal .react-datepicker__input-container,
  .shift-requests-modal .react-datepicker__input-container input {
    display: block;
    height: 3.8rem;
    border: 1px solid #cccccc !important;
    border-radius: 4px; }
    .shift-requests-modal .react-datepicker-wrapper .selected-picker-date,
    .shift-requests-modal .react-datepicker__input-container .selected-picker-date,
    .shift-requests-modal .react-datepicker__input-container input .selected-picker-date {
      border: none !important;
      outline: none !important;
      box-shadow: 0 0 0 1.8px #2684ff !important; }
  .shift-requests-modal .react-datepicker-wrapper {
    border: none !important;
    outline: none !important; }
  .shift-requests-modal .react-datepicker__input-container {
    border: none !important; }
  .shift-requests-modal .react-datepicker {
    font-size: 1em; }
  .shift-requests-modal .react-datepicker__header {
    padding-top: 0.8em; }
  .shift-requests-modal .react-datepicker__month {
    margin: 0.4em 1em; }
  .shift-requests-modal .react-datepicker__day-name,
  .shift-requests-modal .react-datepicker__day {
    width: 25px;
    height: 25px;
    line-height: 25px;
    margin: 3px; }
  .shift-requests-modal .react-datepicker__current-month {
    font-size: 1em; }
  .shift-requests-modal .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent; }
  .shift-requests-modal .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em; }
  .shift-requests-modal .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em; }
  .shift-requests-modal .spinner-wrapper {
    height: 15rem;
    background-color: #fcfcfc; }
  .shift-requests-modal .select-value {
    color: #757575 !important; }

@media all and (max-width: 1240px) {
  .step-one {
    min-width: 28.3% !important; }
  .date-section {
    min-width: 28.3% !important; }
  .shift-options {
    width: 100%; }
    .shift-options .shift-selector {
      width: 11rem !important; }
    .shift-options .provider-selector {
      margin-right: 0 !important; }
    .shift-options .bonus {
      padding-left: 1rem !important; }
  .shift-requests-modal .add-shift-button {
    width: 14rem !important; } }

@media all and (max-width: 1000px) {
  .step-one {
    min-width: 42% !important; }
  .date-section {
    min-width: 42% !important; }
  .arrow-wrapper {
    margin-left: 13.5rem !important; }
  .shift-options {
    width: 100%;
    display: inline-block !important; }
    .shift-options .shift-selector {
      width: 90% !important;
      margin-right: 1.5rem;
      padding-bottom: 1rem; }
    .shift-options .provider-selector {
      margin-bottom: 0 !important; }
    .shift-options .bonus-remove {
      margin-top: 0 !important; }
    .shift-options .remove-section {
      padding-left: -3rem; }
  .shift-requests-modal .add-shift-button {
    width: 90% !important; }
  .shift-requests-modal .modal-footer {
    justify-content: space-around !important; } }

@media (min-width: 576px) and (max-width: 768px) {
  .shift-requests-modal .add-shift-button {
    width: 85% !important;
    margin-top: 0 !important; }
  .shift-requests-modal .modal-footer {
    justify-content: space-evenly !important; }
    .shift-requests-modal .modal-footer .cancel-shifts-button {
      padding-right: 0 !important; }
  .shift-requests-modal .day-string-date-picker {
    font-size: 1.1rem !important;
    margin: 0 !important;
    margin-top: 1rem !important; }
  .shift-requests-modal .by-date-picker-wrapper {
    width: 100% !important; }
  .shift-requests-modal .input-shift-date-picker {
    height: inherit;
    width: 98%;
    font-size: 1.1rem !important; }
  .shift-requests-modal .step-one {
    min-width: 42% !important; }
  .shift-requests-modal .step-two {
    min-width: 55% !important;
    padding-left: 2rem !important; }
  .shift-requests-modal .bonus-remove {
    margin-top: 0; }
  .shift-requests-modal .shifts-section {
    margin: 0 !important;
    padding-left: 2rem !important;
    min-width: 55% !important; }
  .shift-requests-modal .date-section {
    margin: 0 !important;
    min-width: 42% !important; }
  .shift-requests-modal .arrow-wrapper {
    margin-left: 13.5rem !important; }
  .shift-requests-modal .shift-options {
    width: 100%;
    display: inline-block !important; }
    .shift-requests-modal .shift-options .select-container {
      margin-bottom: 1rem; }
      .shift-requests-modal .shift-options .select-container .shift-selector {
        width: 85% !important;
        margin-right: 1.5rem; }
    .shift-requests-modal .shift-options .remove-section {
      padding-left: -3rem; } }

@media (min-width: 361px) and (max-width: 575px) {
  .shifts-section {
    padding-left: 1rem !important; }
  .step-one {
    min-width: 42% !important; }
  .date-section {
    min-width: 42% !important; }
  .step-two {
    padding-left: 1rem !important; }
  .add-shift-button {
    margin-top: 0 !important; }
  .add-date-button {
    width: 100% !important; }
  .day-string-date-picker {
    font-size: 1.3rem !important;
    margin: 0 !important;
    margin-top: 1rem !important; }
  .date-row {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .steps {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .input-shift-date-picker {
    padding-left: 4.5rem !important;
    padding-top: 0.5rem !important;
    font-size: 1rem !important;
    width: 105% !important; }
  .by-date-picker-wrapper {
    width: 13.5rem !important;
    font-size: 1.3rem !important; }
    .by-date-picker-wrapper .react-datepicker__input-container {
      width: 100%; }
    .by-date-picker-wrapper .arrow-wrapper {
      margin-left: 10.5rem !important; } }

@media (max-width: 330px) {
  .step-one {
    min-width: 50% !important; }
  .date-section {
    min-width: 50% !important; }
  .shifts-section {
    padding-left: 1rem !important; }
  .date-row {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .steps {
    padding-left: 1rem !important;
    padding-right: 1rem !important; }
  .add-date-button {
    width: 12rem !important; }
  .step-two {
    padding-left: 1rem !important; }
  .add-shift-button {
    margin-top: 0 !important; }
  .day-string-date-picker {
    font-size: 1.1rem !important;
    margin: 0 !important;
    margin-top: 1rem !important; }
  .input-shift-date-picker {
    width: 95% !important;
    padding-left: 4rem !important;
    font-size: 1rem !important; }
  .by-date-picker-wrapper {
    width: 13.3rem !important;
    font-size: 1.1rem !important; }
    .by-date-picker-wrapper .react-datepicker__input-container {
      width: 95% !important; }
    .by-date-picker-wrapper .arrow-wrapper {
      margin-left: 10rem !important; }
  .modal-footer {
    padding-left: 1rem;
    flex-wrap: nowrap !important;
    display: flex !important; }
    .modal-footer .req-shifts-button {
      margin: 0 !important; } }

.confirmation-modal .header-confirmation-shifts {
  margin-bottom: 1rem; }
  .confirmation-modal .header-confirmation-shifts.slots {
    margin: 0; }
  .confirmation-modal .header-confirmation-shifts .head-text {
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1; }
    .confirmation-modal .header-confirmation-shifts .head-text.wrap {
      flex-wrap: wrap; }
      @media (min-width: 576px) {
        .confirmation-modal .header-confirmation-shifts .head-text.wrap {
          justify-content: left; } }

.confirmation-modal .row-confirmation-shifts {
  display: flex;
  margin: 0;
  align-items: center; }
  .confirmation-modal .row-confirmation-shifts .row,
  .confirmation-modal .row-confirmation-shifts .row-shift {
    flex: 1; }
  .confirmation-modal .row-confirmation-shifts.slots {
    margin: 0; }
  .confirmation-modal .row-confirmation-shifts.request:nth-child(even) {
    background-color: rgba(187, 107, 217, 0.15); }
  .confirmation-modal .row-confirmation-shifts .end-time {
    margin-top: 1rem !important; }
  .confirmation-modal .row-confirmation-shifts .row-shift {
    padding: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-width: 170px; }
    .confirmation-modal .row-confirmation-shifts .row-shift.resource-type {
      font-weight: bold; }
    .confirmation-modal .row-confirmation-shifts .row-shift.add .select-container {
      width: 75%; }
    .confirmation-modal .row-confirmation-shifts .row-shift .shift-count-details {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%; }
      .confirmation-modal .row-confirmation-shifts .row-shift .shift-count-details > :last-child {
        flex-shrink: 1;
        text-align: left;
        padding-left: 2rem; }
        @media (max-width: 575px) {
          .confirmation-modal .row-confirmation-shifts .row-shift .shift-count-details > :last-child {
            padding-left: 1rem; } }
      .confirmation-modal .row-confirmation-shifts .row-shift .shift-count-details .highlighted {
        font-weight: 700; }

.confirmation-modal .guaranteed-badge-span {
  display: inline-block;
  background-color: rgba(127, 217, 225, 0.3);
  color: #00b4c4;
  border-radius: 6px;
  border: 1px solid rgba(127, 217, 225, 0.3);
  font-size: 1.2rem;
  min-width: 7rem;
  height: 2rem;
  padding: 0 0.5rem;
  font-weight: initial; }
  .confirmation-modal .guaranteed-badge-span .guaranteed-icon {
    padding: 2px 6px;
    height: 16px; }
    @media (max-width: 575px) {
      .confirmation-modal .guaranteed-badge-span .guaranteed-icon {
        padding: 0; } }
  .confirmation-modal .guaranteed-badge-span .orientation-icon {
    vertical-align: center;
    padding: 2px 6px;
    height: 16px; }
  .confirmation-modal .guaranteed-badge-span.orientation {
    background-color: #8a78a0;
    border: #8a78a0;
    color: #ffffff; }

.confirmation-modal .modal-body-shift-confirmation {
  margin-top: 3rem;
  border: 1px #e0e6ef solid; }
  .confirmation-modal .modal-body-shift-confirmation .start-end {
    min-width: 13rem; }

.confirmation-modal .guaranteed-section {
  text-align: center;
  margin-top: 2rem;
  padding: 1rem 2rem;
  background-color: rgba(187, 107, 217, 0.06);
  border-radius: 6px;
  width: 100%; }
  .confirmation-modal .guaranteed-section .guaranteed-bold {
    color: #531362;
    font-weight: bold; }
  .confirmation-modal .guaranteed-section .guaranteed-text {
    color: #531362; }
  .confirmation-modal .guaranteed-section .guaranteed-italic {
    font-style: italic; }

.confirmation-modal .confirm-guaranteed {
  text-align: center;
  margin-top: 2rem;
  font-weight: 400;
  font-size: 1.7rem;
  margin-bottom: 2rem; }
  .confirmation-modal .confirm-guaranteed .guaranteed-detail {
    margin-top: 2rem; }

@media all and (max-width: 1024px) {
  .confirmation-modal .modal-header .modal-title {
    font-size: 2rem !important; }
  .confirmation-modal .header-confirmation-shifts {
    margin: 0;
    padding-bottom: 1rem; }
    .confirmation-modal .header-confirmation-shifts .head-text {
      font-weight: bold; }
    .confirmation-modal .header-confirmation-shifts .open-before {
      padding: 0 !important;
      padding-bottom: 1rem !important;
      padding-left: 1rem !important; }
    .confirmation-modal .header-confirmation-shifts .end-time {
      margin: 0 !important;
      padding-left: 1.5rem !important;
      padding-right: 3rem; }
    .confirmation-modal .header-confirmation-shifts .new-shifts {
      margin-left: 0 !important; }
    .confirmation-modal .header-confirmation-shifts .guaranteed-badge-span {
      margin-left: 1rem;
      width: 8rem; }
  .confirmation-modal .row-confirmation-shifts {
    margin: 0; }
    .confirmation-modal .row-confirmation-shifts .end-time {
      margin: 0 !important;
      padding-left: 1rem;
      padding-right: 7rem; } }

@media (max-width: 575px) {
  .date-input-container {
    height: inherit; }
  .shifts-section {
    padding-left: 1rem !important; }
  .add-date-button {
    width: 12rem !important; }
  .step-two {
    padding-left: 1rem !important; }
  .add-shift-button {
    margin-top: 0 !important; }
  .day-string-date-picker {
    font-size: 1.1rem !important;
    margin: 0 !important;
    margin-top: 1rem !important; }
  .input-shift-date-picker {
    width: 95% !important;
    padding-left: 4rem !important;
    font-size: 1rem !important; }
  .by-date-picker-wrapper {
    width: 13.3rem !important;
    font-size: 1.1rem !important; }
    .by-date-picker-wrapper .react-datepicker__input-container {
      width: 95% !important; }
    .by-date-picker-wrapper .arrow-wrapper {
      margin-left: 10rem !important; }
  .confirmation-modal .modal-body-shift-confirmation {
    border: none;
    margin-top: 0;
    padding: 0;
    overflow-y: auto; }
  .confirmation-modal .row-confirmation-shifts {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    margin-left: 0;
    padding-left: 1rem; }
    .confirmation-modal .row-confirmation-shifts.request:nth-of-type(odd) {
      background: none;
      border: 1px solid #e0e6ef; }
    .confirmation-modal .row-confirmation-shifts .row-shift {
      padding: 0.3rem 0;
      font-weight: 700;
      justify-content: left; }
      .confirmation-modal .row-confirmation-shifts .row-shift.end-time {
        margin: 0 !important;
        padding: 0 !important; }
      .confirmation-modal .row-confirmation-shifts .row-shift.new-shifts {
        padding: 0 !important;
        padding-bottom: 1rem !important; }
    .confirmation-modal .row-confirmation-shifts .open-before {
      padding-bottom: 0; } }

@media (max-width: 330px) {
  .header-confirmation-shifts .head-text {
    min-width: 3.6rem !important;
    max-width: 3.5rem !important; }
  .row-confirmation-shifts .row-shift {
    font-size: 0.9rem;
    min-width: 3.7rem !important;
    max-width: 3.5rem !important; } }

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 11rem; }
