@import '../../stylesheets/variables.scss';

.edit-contact {
	font-weight: 400;
	font-size: 1.4rem;
	white-space: nowrap;
	color: #7200a0;
	line-height: 2.4rem;
	border: none;
	background-color: transparent;
	padding: 0;
	display: flex;
	align-items: center;

	svg {
		width: 16px;
		height: 16px;
	}
}
