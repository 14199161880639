@import './../../stylesheets/variables.scss';

.navbar-menus {
	padding: 0 1.6rem;
	font-size: 1.5rem;

	.user-section,
	.apps-section {
		display: flex;
		align-items: center;
		height: 100%;
		padding: 0 1rem;

		&.active {
			background-color: $active-purple;
		}

		button {
			width: auto;
			height: auto;
			background: none;
			border: none;
			padding: 0;
			color: $grayscale-0;

			&.menu-button {
				width: 100%;
				padding: 8px;
				font-weight: 600;
				font-size: 1.4rem;
				text-align: left;
				color: $grayscale-900;
				border-bottom: 1px solid $grayscale-100;

				span {
					margin-left: 0.7rem;
				}

				svg {
					vertical-align: bottom;
				}

				&:hover {
					background-color: $grayscale-25;
				}
			}
		}

		&__avatar {
			display: flex;
			flex-direction: row;
			cursor: pointer;
		}

		&__carat {
			margin-left: 0.7rem;
			align-self: center;

			color: $grayscale-0;
		}

		&__dropdown {
			right: 4rem;
			top: 6.2rem;
		}
	}
	.apps-section {
		.dropdown-component {
			min-width: 150px;
		}
	}

	@media (min-width: 200px) and (max-width: 992px) {
		.user-section {
			&__avatar {
				padding: 20px 0 0 35px;
				align-self: center;
			}

			&.active {
				background-color: $active-purple-mobile;
			}
		}

		.icon {
			color: $off-black;
		}
	}
}
