.error-btn {
  justify-content: flex-start !important; }

.reccuring-shifts-grid {
  margin-left: -10px;
  overflow-y: auto;
  flex: 1 1 100%; }
  .reccuring-shifts-grid.error-container {
    padding: 0 4rem; }
  .reccuring-shifts-grid-font {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: bold;
    color: #2f385c; }
  .reccuring-shifts-grid .error-text {
    margin-top: 2.2rem; }
  .reccuring-shifts-grid .centered-v-h-position {
    display: flex;
    align-items: center;
    justify-content: center; }
  .reccuring-shifts-grid .centered-h-position {
    display: flex;
    justify-content: center; }
  .reccuring-shifts-grid-head .title-font {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 2rem; }
  .reccuring-shifts-grid-head .tooltip-icon-small {
    cursor: pointer;
    width: 12px;
    height: 12px;
    margin: 0; }
  .reccuring-shifts-grid-head .trade-mark-icon {
    height: 12px;
    padding-top: 0.1px; }
  .reccuring-shifts-grid-head .margin-right-6 {
    margin-right: 6px; }
  .reccuring-shifts-grid-head .margin-left-7 {
    margin-left: 7px; }
  .reccuring-shifts-grid-head .margin-right-4 {
    margin-right: 4px; }
  .reccuring-shifts-grid-head .right-top-corner {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    height: 100%;
    padding-top: 9px; }
  .reccuring-shifts-grid-head .table-head-centered-icons {
    display: flex;
    align-items: center;
    height: 100%; }
  .reccuring-shifts-grid-body .common-font {
    font-size: 2.2rem;
    line-height: 5rem; }
  .reccuring-shifts-grid-body .time-font {
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.8rem;
    color: #2f385c; }
  .reccuring-shifts-grid-body .orientation-badge {
    padding: 5px 15px;
    background: #531362;
    border-radius: 8px;
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 1.6rem;
    display: flex;
    align-items: center;
    color: #ffffff;
    justify-content: center; }
  .reccuring-shifts-grid-body .custom-shifts-checkbox .custom-checkbox-control {
    border: 2px solid #595959; }
  .reccuring-shifts-grid-body .custom-shifts-checkbox .custom-checkbox-container input:checked + .custom-checkbox-control {
    background-color: #11b4c3;
    border: 0; }
  .reccuring-shifts-grid-body .textInput {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #000000;
    width: 30px;
    max-width: 30px;
    text-align: center;
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 2.2rem;
    line-height: 5rem;
    color: #545454; }
  .reccuring-shifts-grid-body .greyout-input {
    border-color: #b0b0b0;
    color: #b0b0b0; }
  .reccuring-shifts-grid-body .error-badge {
    width: 142px;
    height: 24px;
    background: #a02e44;
    border-radius: 8px;
    font-family: Open Sans, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 54px;
    display: flex;
    align-items: center;
    color: #ffffff;
    justify-content: center; }
  .reccuring-shifts-grid-body .error-text {
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 4.5rem;
    line-height: 5rem;
    color: #545454; }
  @media (min-width: 992px) and (max-width: 1222px) {
    .reccuring-shifts-grid .reccuring-shifts-grid-head .title-font {
      font-size: 1.8rem; } }
  @media (min-width: 992px) and (max-width: 1222px) {
    .reccuring-shifts-grid .reccuring-shifts-grid-head .title-font {
      font-size: 1.2rem; }
    .reccuring-shifts-grid .reccuring-shifts-grid-head .tooltip-icon-small {
      width: 10px;
      height: 10px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-head .trade-mark-icon {
      width: 8px;
      height: 8px;
      padding-top: 3px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-head .right-top-corner {
      padding-top: 7px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .common-font {
      font-size: 1.4rem;
      line-height: 4rem; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .orientation-badge {
      border-radius: 6px;
      font-size: 1.3rem;
      line-height: 1.3rem;
      padding: 3px 10px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .error-badge {
      border-radius: 6px;
      font-size: 1.3rem;
      line-height: 1.3rem;
      padding: 3px 10px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .textInput {
      width: 24px;
      max-width: 24px;
      font-size: 1.8rem;
      line-height: 3rem; } }
  @media (max-width: 991px) {
    .reccuring-shifts-grid .reccuring-shifts-grid-head .title-font {
      font-size: 1.6rem; }
    .reccuring-shifts-grid .reccuring-shifts-grid-head .tooltip-icon-small {
      width: 8px;
      height: 8px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-head .trade-mark-icon {
      width: 6px;
      height: 6px;
      padding-top: 3px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-head .right-top-corner {
      padding-top: 5px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .common-font {
      font-size: 1rem;
      line-height: 3rem; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .orientation-badge {
      border-radius: 5px;
      font-size: 1rem;
      line-height: 1rem;
      padding: 2px 8px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .error-badge {
      border-radius: 5px;
      font-size: 1rem;
      line-height: 1rem;
      padding: 2px 8px; }
    .reccuring-shifts-grid .reccuring-shifts-grid-body .textInput {
      width: 20px;
      max-width: 20px;
      font-size: 1.4rem;
      line-height: 2rem; } }
