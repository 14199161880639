#bonusInformation .tooltip-inner {
  background-color: #121315;
  color: #ffffff;
  font-size: 1.4rem; }

.bonus-modal {
  color: #444444;
  font-size: 1.4rem;
  font-family: 'Open Sans', Arial, sans-serif; }
  .bonus-modal .modal-content {
    padding: 1.5rem 1.5rem;
    overflow: visible !important; }
    .bonus-modal .modal-content .remove-bonus-button {
      width: 100%;
      max-width: 12rem;
      height: 3.3rem;
      color: #fff;
      align-self: center;
      font-size: 1.2rem;
      background-color: #4811a3 !important;
      border: 1px solid #4811a3;
      border-radius: 4px;
      margin-bottom: 1rem; }
    .bonus-modal .modal-content .remove-bonus-icon {
      margin: 0rem 0.8rem;
      color: white; }
  .bonus-modal .modal-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 17px; }
  .bonus-modal .modal-body {
    padding: 2rem 0 0 0;
    font-weight: 400;
    font-size: 16px;
    overflow: visible !important; }
    .bonus-modal .modal-body .modal-shift-info {
      border: 4px solid #e0e6ef;
      text-align: left;
      padding: 10px 0 0 19px;
      max-width: 365px;
      margin: 1rem auto;
      font-weight: 400; }
      .bonus-modal .modal-body .modal-shift-info .modal-shift-info-resource-type {
        display: flex;
        align-items: center; }
        .bonus-modal .modal-body .modal-shift-info .modal-shift-info-resource-type .guaranteed-badge-span {
          display: flex;
          align-items: center;
          background-color: rgba(127, 217, 225, 0.3);
          color: #00b4c4;
          border-radius: 6px;
          border: 1px solid rgba(127, 217, 225, 0.3);
          font-size: 1.2rem;
          width: 10rem;
          height: 1.5rem;
          margin: 0;
          margin-left: 5px; }
          .bonus-modal .modal-body .modal-shift-info .modal-shift-info-resource-type .guaranteed-badge-span .guaranteed-icon,
          .bonus-modal .modal-body .modal-shift-info .modal-shift-info-resource-type .guaranteed-badge-span .orientation-icon {
            padding: 2px 6px;
            height: 16px; }
          .bonus-modal .modal-body .modal-shift-info .modal-shift-info-resource-type .guaranteed-badge-span.orientation {
            background-color: #8a78a0;
            border: #8a78a0;
            color: #ffffff; }
      .bonus-modal .modal-body .modal-shift-info .modal-shift-info-title {
        font-size: 18px;
        font-style: italic; }
      .bonus-modal .modal-body .modal-shift-info ul {
        list-style: none;
        width: 100%;
        padding-left: 0; }
        .bonus-modal .modal-body .modal-shift-info ul li {
          margin-top: 0.5rem; }
          .bonus-modal .modal-body .modal-shift-info ul li span {
            font-weight: 600; }
    .bonus-modal .modal-body .modal-bonus-pills {
      display: flex;
      justify-content: space-around;
      width: 80%;
      margin: 0 auto 3rem auto; }
      .bonus-modal .modal-body .modal-bonus-pills .modal-bonus-pill {
        width: 80px;
        height: 42px;
        border-radius: 30px;
        background-color: white !important;
        border: 1px solid #00b4c3;
        color: #00b4c3;
        font-weight: 600;
        font-size: 16px; }
        .bonus-modal .modal-body .modal-bonus-pills .modal-bonus-pill:hover, .bonus-modal .modal-body .modal-bonus-pills .modal-bonus-pill.selected {
          background-color: #00b4c3 !important;
          color: white; }
    .bonus-modal .modal-body .modal-input {
      margin-top: 2rem; }
      .bonus-modal .modal-body .modal-input label {
        color: #545454;
        font-weight: 400;
        font-size: 16px;
        margin-right: 10px; }
      .bonus-modal .modal-body .modal-input .bonus-amount::before {
        content: '$'; }
      .bonus-modal .modal-body .modal-input input {
        border: none;
        border-bottom: 1px solid #e0e6ef;
        width: 92px;
        text-align: center;
        outline: none; }
        .bonus-modal .modal-body .modal-input input::placeholder {
          color: #e0e6ef; }
        .bonus-modal .modal-body .modal-input input.has-error {
          border-bottom: 1px solid #f3519c; }
    .bonus-modal .modal-body .bonus-input-error {
      height: 2rem;
      color: #f3519c;
      visibility: hidden; }
      .bonus-modal .modal-body .bonus-input-error.show {
        visibility: visible; }
    .bonus-modal .modal-body .select-container {
      overflow: visible; }
    .bonus-modal .modal-body .modal-text {
      padding: 0 2rem; }
    .bonus-modal .modal-body .confirmation-text {
      padding: 0 5rem; }
  .bonus-modal .modal-footer {
    padding: 1rem 0 3rem 0;
    border-top: none;
    justify-content: space-around !important;
    font-family: 'Open Sans', Arial, sans-serif; }
    .bonus-modal .modal-footer .bonus-go-back {
      color: #4811a3;
      cursor: pointer;
      font-size: 16px; }
    .bonus-modal .modal-footer .btn {
      width: 12.5rem;
      height: 3.5rem;
      border-radius: 4px;
      font-size: 12px; }
      .bonus-modal .modal-footer .btn.disabled {
        cursor: not-allowed; }
    .bonus-modal .modal-footer .btn-secondary {
      margin-right: 0.5rem; }

.bonus-permissions-modal .modal-dialog .modal-content {
  padding: 0rem 2rem; }
  .bonus-permissions-modal .modal-dialog .modal-content .text-center .modal-title {
    margin-top: 1rem; }

@media (min-width: 992px) and (max-width: 1199px) {
  .bonus-permissions-modal .modal-dialog {
    max-width: 990px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .bonus-permissions-modal .modal-dialog {
    max-width: 730px; } }

@media (min-width: 576px) and (max-width: 767px) {
  .bonus-permissions-modal .modal-dialog {
    max-width: 500px; } }

@media (min-width: 300px) and (max-width: 767px) {
  .bonus-modal .modal-body .modal-bonus-pills {
    display: none; }
  .bonus-modal .modal-footer .btn {
    max-width: 10.5rem; }
  .bonus-modal .modal-body .modal-shift-info-resource-type .guaranteed-badge-span {
    font-size: 1.2rem !important;
    max-width: 9rem !important; } }
