@import './../../stylesheets/variables.scss';

.caregiver-card {
	margin-bottom: 1rem;

	&__body {
		display: flex;
		flex-direction: row;
	}

	&__details {
		margin-right: 2rem;
		display: flex;
		flex-direction: row;
	}

	&__actions {
		display: flex;
		flex-direction: row;
		min-width: 200px;

		&--dropdown {
			text-align: right;

			.sm {
				&__control {
					border: none;
					color: $grayscale-400;
					cursor: pointer;

					&--is-focused {
						color: $primary-500;
					}

					&--menu-is-open {
						color: $primary-500;

						.sm__dropdown-indicator {
							transform: rotate(180deg);
						}
					}
				}

				&__value-container {
					justify-content: flex-end;
					font-weight: 700;
				}

				&__placeholder {
					color: inherit;
				}

				&__dropdown-indicator {
					color: inherit;
				}
			}
		}
	}

	&__main-profile {
		flex-direction: column;
		flex-grow: 1;

		.caregiver-name {
			font-weight: 600;
			font-size: 1.6rem;
		}
	}

	&__avatar {
		margin-right: 1.5rem;
	}

	&__specialties {
		line-height: 3rem;
		font-weight: 600;

		.resource {
			font-weight: 700;
		}

		.tier {
			margin-left: 2rem;
		}
	}

	&__phone-number {
		line-height: 4rem;
	}

	&__stats {
		margin-right: 4rem;
		line-height: 3rem;
		display: inline-block;
	}

	&__additional-details {
		display: flex;
		width: 100%;
		flex-direction: column;
	}

	&__stats-container {
		flex-direction: row;
	}

	&__avatar-deactivated {
		opacity: 0.3;
	}

	.caregiver-actions-container {
		margin-left: auto;

		.btn-primary.dropdown-toggle {
			background: none !important;
			outline: none !important;
			border: none !important;
			font-weight: bold;
			color: $active-purple;
			font-size: 1.4rem;

			&:focus,
			&:active {
				color: $active-purple;
			}
		}

		.dropdown-menu > .dropdown-item {
			color: $grayscale-800;
			font-size: 1.4rem;

			&:focus {
				color: $grayscale-800;
				background: none;
			}
		}
	}
}

.caregiver-deactivated {
	color: $grayscale-200;
}

@media (max-width: 992px) {
	.caregiver-card {
		&__stats {
			margin-right: 0;
			display: block;
		}

		&__specialties {
			text-overflow: ellipsis;
			overflow: hidden;
			display: -webkit-box;
			-webkit-box-orient: vertical;
			white-space: normal;
			word-break: break-all;

			&.collapsed {
				-webkit-line-clamp: 3;
			}
		}
	}
}
