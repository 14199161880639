@import './../../stylesheets/variables.scss';

#pastShiftsInformation {
	.tooltip-inner {
		background-color: $background-black;
		color: $ultra-white;
		font-size: 1.4rem;
		text-align: left;
	}

	ul {
		margin-top: 0.5rem;
		padding-left: 2.3rem;
		text-align: left;
	}

	li {
		font-size: 1.2rem;
	}
}

#dispute-tooltip .tooltip-inner {
	background-color: $background-black;
	color: $ultra-white;
	width: auto;
	max-width: inherit;
	text-align: left;
	padding: 0.5rem 0.8rem;
	font-size: 1.5rem;
	div {
		margin-bottom: 0.3rem;
		white-space: nowrap;
	}
}

#credential-packet-tooltip .tooltip-inner {
	background-color: $background-black;
	color: $ultra-white;
	width: auto;
	max-width: inherit;
	text-align: left;
	padding: 0.5rem 0.8rem;
	font-size: 1.5rem;
	div {
		margin-bottom: 0.3rem;
		white-space: nowrap;
	}
}

.past-shifts-page {
	background-color: $past-shift-grey;
	padding: 3.2rem;
	width: 100%;
	height: 90vh;
}

.past-shift-data-table {
	overflow: auto;
	height: 95%;
	width: 100%;
	background-color: white;
	box-shadow: 0.5px 3px 10px rgba(119, 119, 119, 0.1);

	.shifts-list {
		width: 100%;
		flex: 0 100%;
	}

	table {
		table-layout: fixed;
		width: 100%;

		tbody {
			&.disputes-exist {
				border-bottom: 3px solid grey;
			}
		}

		tr {
			th:first-child {
				margin-left: 2rem;
			}
			.table {
				&-col {
					width: 20%;
				}
				&-col-big {
					width: 25%;
				}
				&-col:first-child {
					margin-left: 2rem;
				}
				&-col-small {
					width: 10%;

					&.actions {
						text-align: center;
					}
				}
				&-col-icon {
					width: 3%;
				}
			}
			&.table-separator {
				border-bottom: solid 1px $border-color;
			}
			&.disputed-separator {
				border-bottom: solid 12px $border-color;
			}
			th,
			td {
				padding: 20px;
			}
		}
	}
	.picker-and-message {
		display: flex;
		align-items: center;
		margin: 3rem 0rem;

		.message {
			font-size: 1.6rem;
			margin-left: auto;
			padding-right: 1rem;
			font-weight: bold;
			color: $past-shifts-message;
			text-align: center;
		}

		.reviews {
			font-size: 2.6rem;
			font-style: normal;
			font-weight: 700;
			line-height: 54px;
			margin-left: auto;
			padding-right: 1rem;
		}

		.information-icon {
			height: 25px;
			margin-right: 2rem;
		}
	}

	.shifts-period {
		font-size: 1.6rem;
		line-height: 54px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-align: center;

		.dates-separate {
			font-weight: bold;
			margin-left: 1rem;
			margin-right: 0.8rem;
		}

		.shifts-period-nav {
			padding: 0 8px;
			margin-right: 2rem;
			margin-left: 2rem;

			&:hover {
				cursor: pointer;
			}

			&.disabled {
				&:hover {
					cursor: not-allowed;
				}
			}
		}

		.nav-calendar {
			margin-right: 2.2rem;
		}

		.datepicker-input {
			position: relative;
			direction: rtl;

			.datepicker-arrow {
				position: absolute;
				bottom: 21px;
				right: 30px;
				width: 15px;
				height: 24px;
			}

			.datepicker-icon {
				position: absolute;
				bottom: 21px;
				right: 155px;
				width: 24px;
				height: 24px;
			}
			input {
				border-color: $new-purple;
				width: 20rem;
				height: 6.4rem;
				border-radius: 2rem;
				text-align: center;
			}
		}
	}
	.past-shift-separator {
		height: 0.15rem;
		margin: 16px auto;

		&.header-separator {
			margin: 10px 0px 0px 0px;
			border-bottom: solid 0.1rem $new-purple;
		}

		&.row-separator {
			background-color: #000000;
			mix-blend-mode: normal;
			opacity: 0.15;
		}
	}
	.past-shift-table-header {
		padding: 0px;
		.header-title {
			margin-bottom: 10px;
			font-weight: bold;
			color: $table-header-grey;

			&.table-col-small.total-pay {
				padding: 10px;
			}
		}
	}

	.table-body-row {
		font-size: 1.5rem;
		padding: 0;
		margin: 0, 0, 0, 0.95rem;

		.guaranteed-badge {
			margin-left: 1.1rem;
		}

		.download-file {
			cursor: pointer;
			width: 2.2rem;
			margin-left: 12px;
		}

		.error-slot {
			color: $red-error-past-shift;
			font-weight: bold;
		}

		.table-col-small.total-pay {
			padding: 10px;
		}

		.table-col {
			.external-worker-badge {
				border-radius: 4px;
				background-color: $grayscale-100;
				color: $background-black;
				padding: 2px;
				font-size: 0.8rem;
				display: flex;
				justify-content: center;
				flex: 0 1 9.6rem;
				height: 2rem;
				align-items: center;
				width: 100px;
				margin: 2px 0;
			}
		}
	}
	.no-shifts-available {
		font-size: 1.5rem;
		margin-left: 2rem;
	}

	.table-body-cell {
		display: flex;
		align-items: center;
	}

	.dispute-resolved {
		color: $dispute-resolved;
		font-weight: bold;
		font-size: 2rem;
	}

	.table-col {
		&.shifts-action {
			padding: 0;
			justify-content: flex-end;
		}
	}

	.dispute-actions {
		text-align: center;

		.past-shift-action {
			height: 3.5rem;
			cursor: pointer;

			&.past-shift-approve {
				&.active,
				&:hover:not(.disabled) {
					circle {
						fill: $approved-slot;
						stroke: white;
					}

					path {
						fill: white;
					}
				}

				&.disabled {
					cursor: not-allowed;
				}
			}

			&.past-shift-dispute {
				&.active,
				&:hover:not(.disabled) {
					circle {
						fill: $dispute-slot;
						stroke: white;
					}

					path {
						fill: white;
					}
				}

				&.disabled {
					cursor: not-allowed;
				}
			}

			&.dispute {
				margin-left: 0.5rem;
			}
		}

		.resolved-dispute {
			color: $dispute-resolved;
			font-weight: bold;
			font-size: 2rem;
		}
	}
}

@media (min-width: 887px) and (max-width: 959px) {
	.past-shifts-page {
		.past-shift-data-table {
			.picker-and-message {
				.message,
				.reviews {
					font-size: 1.3rem;
					word-break: break-all;
				}

				.information-icon {
					height: 15px;
				}
			}

			.dispute-actions {
				.past-shift-action {
					height: 2.5rem;
				}
			}
		}
	}
}

@media (min-width: 200px) and (max-width: 886px) {
	.past-shifts-page {
		.past-shift-data-table {
			.picker-and-message {
				.message,
				.reviews {
					font-size: 1.1rem;
					word-break: break-all;
				}

				.information-icon {
					height: 13px;
				}
			}

			.dispute-actions {
				.past-shift-action {
					height: 2.5rem;
				}
			}
		}
	}
}
