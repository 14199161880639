@import './../../stylesheets/variables.scss';

.select-label {
	padding-bottom: 4px;
}

.sm__control {
	&.sm__control--is-focused,
	&.sm__control--is-focused:hover {
		box-shadow: none;
		border-color: $new-purple;
	}
}

.highlight {
	.sm__control {
		&.sm__control--is-focused {
			box-shadow: none;
			border: 0.3rem solid $new-purple;
		}
	}

	.sm__menu {
		border: 0.3rem solid $new-purple;
	}
}

.has-error {
	.sm__control {
		box-shadow: none;
		border: 2px solid $table-error;
	}
}

.select__readonly {
	border-bottom: 1px solid $grayscale-100;
	min-height: 40px;
	line-height: 3.2rem;
}

.select-container {
	.read-only {
		.sm__control {
			background-color: $grayscale-50;
			border-color: $grayscale-200;
		}
	}
}

.employee-edit-page {
	svg {
		color: $grayscale-600;
		width: 1.5rem;
		height: 1.5rem;
	}
}
