@import './../../../stylesheets/variables.scss';

.base-modal {
	&.confirm-modal {
		&.already-arrived-modal {
			.modal-content {
				.modal-footer {
					.primary-button {
						border-color: $primary-500;
						background-color: $primary-500;
					}
				}
			}
		}
	}
}
