.bonus-permissions-modal {
  color: #444444;
  font-size: 1.4rem;
  font-family: 'Open Sans', sans-serif; }
  .bonus-permissions-modal .modal-content {
    padding: 1.5rem 1.5rem;
    overflow: visible !important; }
  .bonus-permissions-modal .modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 17px; }
  .bonus-permissions-modal .modal-body {
    padding: 2rem 0 0 0;
    font-weight: 400;
    font-size: 16px;
    overflow: visible !important; }
    .bonus-permissions-modal .modal-body .select-container {
      overflow: visible; }
    .bonus-permissions-modal .modal-body .modal-text {
      padding: 0 2rem; }
  .bonus-permissions-modal .modal-footer {
    padding: 1rem 0 3rem 0;
    border-top: none; }
    .bonus-permissions-modal .modal-footer .btn {
      width: 8rem;
      height: 3.5rem;
      border-radius: 3rem;
      font-size: 16px;
      font-weight: 600; }
      .bonus-permissions-modal .modal-footer .btn.confirm {
        background-color: #7200a0;
        color: #ffffff; }

@media (min-width: 992px) and (max-width: 1199px) {
  .bonus-permissions-modal .modal-dialog {
    max-width: 990px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .bonus-permissions-modal .modal-dialog {
    max-width: 730px; } }

@media (min-width: 576px) and (max-width: 767px) {
  .bonus-permissions-modal .modal-dialog {
    max-width: 500px; } }
