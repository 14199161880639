.login-screen {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .login-screen .login-background {
    object-fit: cover;
    height: 100%;
    max-width: 50%; }
  .login-screen .login-form-container {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    text-align: center; }
  .login-screen .header {
    color: #7200a0;
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding-bottom: 2.5rem; }
  .login-screen .login-form {
    width: 35rem;
    border: 1px solid #e7e7e7;
    padding: 2rem;
    border-radius: 0.8rem;
    background-color: #ffffff; }
  .login-screen .logo {
    padding-bottom: 20%;
    cursor: pointer; }
  .login-screen .input-container {
    display: flex;
    width: 100%;
    height: 40px;
    margin-bottom: 2rem; }
    .login-screen .input-container .input-icon {
      width: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e7e7e7;
      border-right: none; }
    .login-screen .input-container input {
      padding-left: 5px;
      border: 1px solid #e7e7e7; }
    .login-screen .input-container .input-password-wrapper {
      display: flex;
      position: relative;
      flex: 1; }
      .login-screen .input-container .input-password-wrapper .password-show-hide-toggle {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        color: #4f0a74;
        cursor: pointer; }
    .login-screen .input-container .ReactPasswordStrength-input {
      width: 100%;
      height: 100%;
      border: none;
      font-size: 1.4rem;
      padding-right: 106px; }
    .login-screen .input-container .ReactPasswordStrength-strength-desc {
      width: auto; }
  .login-screen .text {
    font-size: 1.2rem;
    color: #858586;
    padding-bottom: 2rem; }
  .login-screen .text-purple {
    color: #7200a0;
    font-size: 1.4rem;
    text-align: center; }
    .login-screen .text-purple.link {
      border: none;
      background: none;
      padding: 1.5rem 2.5rem; }
      .login-screen .text-purple.link:hover {
        color: inherit;
        text-decoration: none; }
  .login-screen .btn-primary {
    background-color: #7200a0 !important;
    border-color: #7200a0 !important;
    color: #ffffff;
    box-shadow: none !important;
    height: 40px;
    margin: 2rem 0; }
  .login-screen .g-recaptcha {
    margin-top: 2rem; }
    .login-screen .g-recaptcha > div > div {
      margin: auto; }
  .login-screen .password-strength {
    height: 4rem;
    letter-spacing: 1px;
    font-size: 1.2rem; }
  .login-screen .is-error {
    box-shadow: 0 0 0 0.2rem #ba8282 !important; }

.password-popover {
  background-color: #f3519c !important;
  border: #f3519c !important; }
  .password-popover .arrow:after {
    border-right-color: #f3519c !important; }
  .password-popover .popover-body {
    padding: 1rem !important;
    color: #ffffff;
    font-size: 1.2rem; }
    .password-popover .popover-body ul {
      padding-inline-start: 2rem; }

.old-browser-alert {
  position: absolute;
  left: 55%;
  visibility: visible;
  padding: 2rem 2rem;
  border: 10px solid #ba3838; }
  .old-browser-alert h1 {
    color: #ba3838;
    text-align: center;
    font-weight: bold;
    font-size: 3rem; }
  .old-browser-alert p {
    font-size: 2rem; }

@media (max-width: 1150px) {
  .old-browser-alert {
    position: absolute;
    left: 50%; }
  h1 {
    font-size: 2.5rem; }
  p {
    font-size: 1.5rem; } }

@media (max-width: 1024px) {
  .password-popover .arrow:after {
    border-right-color: #ffffff !important;
    border-top-color: #ba8282 !important; } }

@media (max-width: 768px) {
  .login-screen {
    margin: auto;
    background: url(../../assets/images/login-background.png) no-repeat;
    background-size: cover; }
    .login-screen .login-form {
      width: 32rem;
      margin: auto; }
  .login-background {
    display: none; } }

@media (max-width: 576px) {
  .old-browser-alert {
    visibility: hidden; } }
