@import './variables.old.scss';

// Standardized naming:
$grayscale-0: #ffffff;
$grayscale-25: #f9f9f9;
$grayscale-50: #f0f0f0;
$grayscale-100: #e7e7e7;
$grayscale-125: #f3f3f3;
$grayscale-150: #d8d8d8;
$grayscale-200: #cececf;
$grayscale-300: #b6b6b7;
$grayscale-400: #858586;
$grayscale-500: #545556;
$grayscale-600: #323335;
$grayscale-700: #232426;
$grayscale-800: #1c1d1f;
$grayscale-900: #121315;

$primary-100: #ece1fa;
$primary-300: #e3ccec;
$primary-400: #9d4dbd;
$primary-500: #7200a0;
$primary-800: #4f0a74;
$primary-900: #7200a0;

$blue-200: #d8eaff;

$secondary-400: #00b4c3;
$secondary-500: #00a698;

$ultra-white: $grayscale-0;
$white: #dddddd;

$off-black: #121315;
$black: #000000;

// warning
$red-100: #ba8282;
$red-200: #ffdbdb;
$red-400: #ba3838;
$red-500: #a11010;
$red-800: #540404;

$green-100: #e5f5e5;
$green-300: #c2eabd;
$green-600: #10a112;
$yellow-200: #ffeca8;
$yellow-600: #f6d046;

// transparencies
$transparency-80: 0.8;

$green-confirmed: #10a11266;

// badges
$badge-default: #cfeaf0;

//professional badge
$available-badge: #cbe6cb;
$available-dot: #10a112;

$unavailable-badge: #ff9f9f;
$unavailable-dot: #a11010;

$assigned-badge: #e7e7e7;
$assigned-dot: #858586;

$not_provided-badge: #ffefb6;
$not_provided-dot: #f6d046;
