.custom-backdrop {
  z-index: 1050 !important; }

.confirmation-modal {
  color: #444444;
  font-size: 1.4rem; }
  .confirmation-modal .modal-content {
    padding: 0 1.5rem;
    overflow: visible !important; }
  .confirmation-modal .modal-header {
    padding: 1.5rem 1rem 1rem 0;
    font-size: 2rem; }
    .confirmation-modal .modal-header .modal-title {
      font-size: 2.4rem;
      font-weight: 400; }
    .confirmation-modal .modal-header .close {
      font-size: 2rem;
      font-weight: 400;
      color: #ff3c9e;
      padding-right: 0; }
  .confirmation-modal .modal-body {
    padding: 2rem 0;
    font-weight: 400;
    font-size: 1.4rem;
    overflow: visible !important; }
    .confirmation-modal .modal-body .select-container {
      overflow: visible; }
  .confirmation-modal .modal-footer {
    padding: 1.5rem 0;
    border-top: none; }
    .confirmation-modal .modal-footer .btn {
      width: 12.5rem;
      height: 3.5rem;
      border-radius: 4px; }
    .confirmation-modal .modal-footer .btn-primary:disabled {
      cursor: initial; }
    .confirmation-modal .modal-footer .btn-secondary {
      margin-right: 0.5rem; }

@media (min-width: 992px) and (max-width: 1199px) {
  .confirmation-modal .modal-dialog {
    max-width: 990px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .confirmation-modal .modal-dialog {
    max-width: 730px; }
  .confirmation-modal .modal-header .modal-title {
    font-size: 2rem !important; } }

@media (min-width: 576px) and (max-width: 767px) {
  .confirmation-modal .modal-dialog {
    max-width: 500px; } }

@media (max-width: 575px) {
  .confirmation-modal .modal-footer {
    justify-content: space-around !important; } }
