.employee-details__wrapper {
  width: 40%;
  line-height: 2.4rem; }
  .employee-details__wrapper h3 {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 2.4rem;
    color: #323335; }
  .employee-details__wrapper h4 {
    font-weight: 600;
    line-height: 2.4rem;
    font-size: 1.4rem;
    color: #121315; }
  .employee-details__wrapper h2 {
    font-weight: 600;
    margin-bottom: 2.4rem;
    color: #323335; }

.name-and-licenses__wrapper {
  gap: 2.4rem;
  margin-bottom: 2.4rem;
  border-bottom: 0.15rem solid #e7e7e7; }
  .name-and-licenses__wrapper:last-child {
    border-bottom: none; }

.details-email__wrapper {
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem; }

.details__wrapper {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.15rem solid #e7e7e7;
  padding-bottom: 1.6rem;
  margin-bottom: 1.6rem;
  font-size: 2.4rem; }
  .details__wrapper h3 {
    font-weight: 400; }
  .details__wrapper h4 {
    font-weight: 600; }

.home-details__wrapper {
  border-bottom: 0.15rem solid #e7e7e7;
  padding-bottom: 2.4rem; }

.home-details {
  display: flex;
  justify-content: space-between; }

.home-details__subtype {
  padding-left: 1.6rem; }

.toggle-section {
  background: none;
  border: none; }

.toggle-icon {
  width: 1.1rem;
  height: 0.65rem;
  color: #323335; }

.home-details__list {
  display: flex;
  flex-direction: column;
  padding-left: 6rem;
  font-size: 1.4rem;
  color: #121315; }

.license-type h4 {
  background-color: #e7e7e7;
  border-radius: 4px;
  padding: 0 8px; }

.home-details-license__wrapper {
  margin-bottom: 1.6rem; }
