.orientation-tooltip {
  display: none !important; }

.sticky-class {
  clip-path: inset(0 0 0 0); }

.shifts-page-container {
  display: flex;
  flex-direction: column;
  height: 100vh; }
  .shifts-page-container .padded {
    padding: 0.5rem; }
  .shifts-page-container .actions {
    display: flex;
    gap: 1rem;
    margin-top: 1rem; }
    .shifts-page-container .actions .secondary-button {
      color: #000000;
      background-color: #ffffff;
      border: 1px solid #000000;
      border-radius: 4.4rem;
      font-size: 1.4rem;
      font-weight: bold;
      height: 4.4rem;
      padding: 0 5rem; }
    .shifts-page-container .actions .primary-button {
      align-items: center;
      justify-content: center;
      color: #fff;
      background-color: #7200a0;
      border: 1px solid #7200a0;
      border-radius: 4.4rem;
      font-size: 1.4rem;
      font-weight: bold;
      height: 4.4rem;
      padding: 0 5rem; }
    .shifts-page-container .actions .no-confirms-pending {
      cursor: unset;
      background: #d8d8d8;
      box-shadow: none;
      border: none; }

.shifts-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 3rem 4rem 1rem 4rem; }
  .shifts-page .font-style {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    letter-spacing: 0.15rem;
    color: #ffffff; }
  .shifts-page .shift-interval-cell {
    position: relative; }
  .shifts-page .top-buttons-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4rem;
    width: 100%; }
  .shifts-page .arrival-status-wrapper {
    padding: 0; }
  .shifts-page .arrival-status {
    position: relative;
    max-width: 23rem; }
    .shifts-page .arrival-status .select-label {
      font-size: 1rem;
      font-weight: 700; }
  .shifts-page .shift-search {
    position: relative;
    display: flex;
    align-items: flex-end;
    height: 6rem;
    padding: 0; }
    .shifts-page .shift-search .search-input {
      height: 40px;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px; }
    .shifts-page .shift-search .search-icon {
      position: absolute;
      left: 10px;
      top: 33px; }
    .shifts-page .shift-search .clear-icon {
      position: absolute;
      right: 10px;
      top: 34px; }
      .shifts-page .shift-search .clear-icon:hover {
        cursor: pointer; }
    .shifts-page .shift-search input {
      border: 1px solid #dad8da; }
  .shifts-page .header-tools {
    padding: 2rem 0; }
    .shifts-page .header-tools .col {
      padding: 0; }
  .shifts-page .shifts-grid {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    height: calc(100% - 4rem);
    color: #121315; }
    .shifts-page .shifts-grid.fit-to-content {
      min-width: fit-content; }
    .shifts-page .shifts-grid .table-separator {
      position: relative; }
    .shifts-page .shifts-grid .table-separator::after {
      content: '';
      right: 0;
      position: absolute;
      height: 40px;
      border-right: 1px solid #dad8da; }
    .shifts-page .shifts-grid .select-status-daily-schedule {
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 1.4rem;
      min-width: 170px;
      width: 170px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .shifts-page .shifts-grid .select-status-daily-schedule .sm__value-container {
        justify-content: center; }
        .shifts-page .shifts-grid .select-status-daily-schedule .sm__value-container .sm__placeholder {
          margin-left: 3rem; }
      .shifts-page .shifts-grid .select-status-daily-schedule .sm__control {
        cursor: pointer;
        background-color: unset;
        border: 1px solid #121315;
        border-radius: 20px;
        text-align: center;
        max-width: 160px;
        color: #121315;
        flex-grow: 1; }
        @media (min-width: 200px) and (max-width: 575px) {
          .shifts-page .shifts-grid .select-status-daily-schedule .sm__control {
            width: 100%;
            max-width: none; } }
        .shifts-page .shifts-grid .select-status-daily-schedule .sm__control .sm__indicator,
        .shifts-page .shifts-grid .select-status-daily-schedule .sm__control .sm__placeholder {
          color: #121315; }
        .shifts-page .shifts-grid .select-status-daily-schedule .sm__control .disabled, .shifts-page .shifts-grid .select-status-daily-schedule .sm__control--is-disabled {
          cursor: not-allowed;
          pointer-events: none;
          color: #858586;
          border-color: #858586; }
          .shifts-page .shifts-grid .select-status-daily-schedule .sm__control .disabled .sm__indicator,
          .shifts-page .shifts-grid .select-status-daily-schedule .sm__control .disabled .sm__placeholder, .shifts-page .shifts-grid .select-status-daily-schedule .sm__control--is-disabled .sm__indicator,
          .shifts-page .shifts-grid .select-status-daily-schedule .sm__control--is-disabled .sm__placeholder {
            color: #858586; }
    .shifts-page .shifts-grid .slots-container {
      display: flex;
      flex-direction: column; }
      .shifts-page .shifts-grid .slots-container .slot {
        align-self: stretch;
        flex-grow: 1; }
        .shifts-page .shifts-grid .slots-container .slot:not(:first-child) {
          margin-top: 6px; }
        .shifts-page .shifts-grid .slots-container .slot.green:nth-child(even) {
          background-color: #f2f9f5; }
        .shifts-page .shifts-grid .slots-container .slot.green:nth-child(odd) {
          background-color: rgba(33, 150, 83, 0.14); }
        .shifts-page .shifts-grid .slots-container .slot.purple:nth-child(even) {
          background-color: rgba(187, 107, 217, 0.06); }
        .shifts-page .shifts-grid .slots-container .slot.purple:nth-child(odd) {
          background-color: rgba(187, 107, 217, 0.15); }
        .shifts-page .shifts-grid .slots-container .slot.yellow:nth-child(even) {
          background-color: rgba(242, 201, 76, 0.15); }
        .shifts-page .shifts-grid .slots-container .slot.yellow:nth-child(odd) {
          background-color: #fefcf4; }
        .shifts-page .shifts-grid .slots-container .slot.blue:nth-child(even) {
          background-color: #f2f9fd; }
        .shifts-page .shifts-grid .slots-container .slot.blue:nth-child(odd) {
          background-color: rgba(45, 156, 219, 0.15); }
    .shifts-page .shifts-grid .with-border {
      border: 2px solid #e0e6ef;
      min-height: 6rem;
      margin-top: 2px;
      display: flex; }
    .shifts-page .shifts-grid .no-left-border {
      border-left: none; }
    .shifts-page .shifts-grid .shift-status-cell {
      min-width: 12rem; }
      .shifts-page .shifts-grid .shift-status-cell.blue {
        background-color: rgba(45, 156, 219, 0.15); }
      .shifts-page .shifts-grid .shift-status-cell.light-blue {
        background-color: #f2f9fd; }
      .shifts-page .shifts-grid .shift-status-cell.green {
        background-color: rgba(33, 150, 83, 0.14); }
      .shifts-page .shifts-grid .shift-status-cell.light-green {
        background-color: #f2f9f5; }
      .shifts-page .shifts-grid .shift-status-cell.purple {
        background-color: rgba(187, 107, 217, 0.15); }
      .shifts-page .shifts-grid .shift-status-cell.light-purple {
        background-color: rgba(187, 107, 217, 0.06); }
      .shifts-page .shifts-grid .shift-status-cell.yellow {
        background-color: #fefcf4; }
      .shifts-page .shifts-grid .shift-status-cell.light-yellow {
        background-color: rgba(242, 201, 76, 0.15); }
      .shifts-page .shifts-grid .shift-status-cell .guaranteed-checkbox-label {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-bottom: 0; }
    .shifts-page .shifts-grid .first-col {
      display: flex;
      align-items: center; }
    .shifts-page .shifts-grid .col-sm-10 {
      padding-left: 0;
      padding-right: 0; }
    .shifts-page .shifts-grid .shifts-grid-header {
      font-size: 1.2rem;
      font-weight: 700;
      color: #444444;
      min-height: 3rem;
      border: 2px solid #7200a0;
      margin-bottom: 1rem; }
      .shifts-page .shifts-grid .shifts-grid-header .col-sm-10 {
        align-items: center; }
      .shifts-page .shifts-grid .shifts-grid-header .border-left {
        border-left: 1px solid #dad8da;
        min-height: 4rem; }
    .shifts-page .shifts-grid .shifts-grid-empty {
      padding: 2rem 0;
      font-weight: 600;
      font-size: 1.6rem; }
    .shifts-page .shifts-grid .shifts-grid-body {
      flex: 1;
      overflow-y: auto;
      padding-bottom: 1rem; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-day-separator {
        width: 100%;
        height: 4px;
        background-color: #e7e7e7;
        border-radius: 4px;
        margin: 8px 0; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-separator {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 1rem 0;
        gap: 4rem;
        font-size: 1.4rem; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-separator-date {
          font-weight: 600; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-separator-today {
          background-color: #e7e7e7;
          padding: 0.8rem;
          font-weight: 600; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-separator-line {
          flex: 1;
          height: 8px;
          background-color: #531362;
          opacity: 0.98;
          border-radius: 8px; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-day {
        justify-content: center;
        align-items: initial; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-day .shift-time span {
          font-size: 1.8rem;
          font-weight: 600;
          padding-left: 0.6rem; }
          @media (max-width: 1100px) {
            .shifts-page .shifts-grid .shifts-grid-body .shift-day .shift-time span {
              font-size: 1.2rem; } }
        .shifts-page .shifts-grid .shifts-grid-body .shift-day .shift-time:last-child span {
          vertical-align: top; }
      .shifts-page .shifts-grid .shifts-grid-body .current-date .month-date,
      .shifts-page .shifts-grid .shifts-grid-body .current-date .week-day {
        color: #531362 !important; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-label {
        color: #444444;
        font-weight: 700;
        min-width: 80px; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-info-container {
        padding: 1rem; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-info-container .shift-info-badges {
          gap: 1rem; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-block {
        width: 100%; }
        @media (max-width: 575px) {
          .shifts-page .shifts-grid .shifts-grid-body .shift-block {
            width: auto; } }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block hr {
          width: 80%; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .resource-type {
          font-size: 1.4rem;
          margin-bottom: 0.6rem; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .integrated-shift-badge {
          color: #121315;
          background-color: #b0b0b0;
          border-radius: 4px;
          padding: 2px 4px;
          font-size: 1rem;
          display: block;
          font-weight: bold;
          text-transform: uppercase;
          width: fit-content; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-shift .guaranteed-checkbox-span {
          color: #00b4c4;
          align-items: center;
          white-space: nowrap; }
          .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-shift .guaranteed-checkbox-span .guaranteed-icon {
            margin: 0 3px 3px 6px;
            width: 10px; }
          .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-shift .guaranteed-checkbox-span > svg {
            margin-right: 5px;
            margin-top: -3px; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-shift .guaranteed-checkbox-label {
          display: flex;
          align-items: center; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-shift input {
          height: 1.5rem;
          width: 1.5rem !important;
          border: 2px solid #00b4c4;
          border-radius: 2px; }
          .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-shift input:checked:before {
            content: ' ';
            position: relative;
            display: inline-block;
            width: 1.5rem;
            height: 1.5rem;
            border-radius: 2px;
            border: none;
            background: #00b4c4 url("../../assets/images/checkbox-mark.svg") center center/100% 90% no-repeat; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-shift .not-guaranteed {
          color: grey; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-icon {
          vertical-align: center;
          margin: 0 3px 3px 6px;
          width: 10px; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-slot {
          font-size: 1rem; }
          .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-slot .guaranteed-icon {
            margin: 0 2px 0 0;
            padding: 0;
            width: 1.4rem;
            height: 1.6rem; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-icon {
          vertical-align: center;
          margin: 0 4px 1px 6px;
          height: 12px; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block {
          display: flex;
          background-color: #8a78a0;
          color: #ffffff;
          border-radius: 6px;
          border: 1px solid #8a78a0;
          font-size: 1rem;
          width: 8.8rem;
          align-items: center; }
          .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block .guaranteed-icon {
            margin-top: 0; }
          .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block.shift {
            margin-left: 0; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-interval {
        color: #444444;
        font-weight: 600; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-ratio {
        font-weight: bold;
        font-size: 1.8rem; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-ratio.resource-type {
          font-size: 2.4rem; }
      .shifts-page .shifts-grid .shifts-grid-body .type {
        font-weight: 700;
        font-size: 1.4rem;
        line-height: 1.6rem; }
      .shifts-page .shifts-grid .shifts-grid-body .unit {
        margin-top: 0.8rem; }
      .shifts-page .shifts-grid .shifts-grid-body .caregiver {
        align-items: center; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .replaced-caregiver {
          color: #b0b0b0;
          font-size: 1rem;
          text-decoration: line-through;
          display: flex;
          width: 100%;
          white-space: nowrap;
          margin-left: 15%; }
          .shifts-page .shifts-grid .shifts-grid-body .caregiver .replaced-caregiver:before {
            content: url(../../assets/images/user-light-grey.svg);
            padding-right: 5px; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .replaced-caregiver-flex {
          display: flex;
          align-items: flex-start; }
          .shifts-page .shifts-grid .shifts-grid-body .caregiver .replaced-caregiver-flex .phone {
            height: 1.6rem;
            width: 1.6rem;
            margin-left: 0.3rem; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .caregiver-name {
          color: #444444;
          display: flex;
          line-height: 2.4rem; }
          .shifts-page .shifts-grid .shifts-grid-body .caregiver .caregiver-name .phone {
            height: 1.6rem;
            width: 1.6rem;
            margin-left: 0.3rem; }
          .shifts-page .shifts-grid .shifts-grid-body .caregiver .caregiver-name .invite {
            height: 1.6rem;
            width: 1.6rem;
            margin-right: 0.3rem; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .current-caregiver {
          position: relative;
          width: 100%; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .flex-caregiver {
          font-size: 1.5rem;
          font-weight: bold; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .looking-caregiver {
          display: inline-flex; }
          .shifts-page .shifts-grid .shifts-grid-body .caregiver .looking-caregiver:before {
            content: url(../../assets/images/replace-arrow.svg);
            padding-right: 5px; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .looking-caregiver-badge {
          font-size: 1rem;
          font-style: italic; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .worker-badge-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          gap: 1rem; }
          .shifts-page .shifts-grid .shifts-grid-body .caregiver .worker-badge-wrapper .credentials-download {
            color: #531362;
            font-size: 1rem;
            display: flex;
            align-items: center;
            justify-content: center; }
            .shifts-page .shifts-grid .shifts-grid-body .caregiver .worker-badge-wrapper .credentials-download:before {
              content: url(../../assets/images/file-download.svg);
              padding-right: 5px;
              padding-top: 2px; }
            .shifts-page .shifts-grid .shifts-grid-body .caregiver .worker-badge-wrapper .credentials-download:hover {
              cursor: pointer;
              text-decoration: underline; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .oriented-worker-badge,
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .returning-worker-badge,
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .block-schedule-badge,
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .external-worker-badge {
          border-radius: 4px;
          background-color: #8a78a0;
          padding: 2px;
          font-size: 0.8rem;
          color: white;
          display: flex;
          justify-content: center;
          flex: 0 1 9.6rem;
          height: 2rem;
          align-items: center;
          min-width: 100px;
          margin: 2px 0; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .returning-worker-badge {
          background-color: rgba(127, 217, 225, 0.3);
          color: #575e9f; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .block-schedule-badge {
          background-color: rgba(0, 0, 0, 0);
          color: #7200a0;
          border-color: #7200a0;
          border: solid 1px; }
        .shifts-page .shifts-grid .shifts-grid-body .caregiver .external-worker-badge {
          background-color: #e7e7e7;
          color: #121315; }
      .shifts-page .shifts-grid .shifts-grid-body .status-indicator {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 11rem;
        flex-direction: column; }
        .shifts-page .shifts-grid .shifts-grid-body .status-indicator .status-indicator-label {
          color: #444444;
          margin-top: 8px; }
          .shifts-page .shifts-grid .shifts-grid-body .status-indicator .status-indicator-label:before {
            content: ' ';
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            background-color: #531362;
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle; }
          .shifts-page .shifts-grid .shifts-grid-body .status-indicator .status-indicator-label.purple:before {
            background-color: #531362; }
          .shifts-page .shifts-grid .shifts-grid-body .status-indicator .status-indicator-label.teal:before {
            background-color: #00b4c4; }
          .shifts-page .shifts-grid .shifts-grid-body .status-indicator .status-indicator-label.pink:before {
            background-color: #f3519c; }
        .shifts-page .shifts-grid .shifts-grid-body .status-indicator .status-indicator-detail {
          font-size: 1rem;
          color: #757575;
          display: block; }
      .shifts-page .shifts-grid .shifts-grid-body .status-dropdown {
        padding-bottom: 8px; }
      .shifts-page .shifts-grid .shifts-grid-body .edit-pencil-icon {
        width: 11px;
        height: 12px;
        display: none;
        margin: 0 1rem; }
        .shifts-page .shifts-grid .shifts-grid-body .edit-pencil-icon.show {
          display: inline;
          cursor: pointer; }
        .shifts-page .shifts-grid .shifts-grid-body .edit-pencil-icon.add-icon {
          display: none; }
      .shifts-page .shifts-grid .shifts-grid-body .add-pencil-icon {
        display: none; }
      .shifts-page .shifts-grid .shifts-grid-body .cancel-button {
        width: 11rem;
        height: 3.3rem;
        color: #fff;
        background-color: #f3519c;
        border: 1px solid #f3519c;
        border-radius: 4px; }
      .shifts-page .shifts-grid .shifts-grid-body .confirm-button {
        width: 11rem;
        height: 3.3rem;
        color: #fff;
        background-color: #00b4c4;
        border: 1px solid #00b4c4;
        border-radius: 4px; }
      .shifts-page .shifts-grid .shifts-grid-body .add-slot-button {
        width: 100%;
        height: 3.2rem;
        color: #ffffff;
        background-color: #7200a0;
        border: 1px solid #7200a0;
        border-radius: 3rem; }
      .shifts-page .shifts-grid .shifts-grid-body .add-bonus-button,
      .shifts-page .shifts-grid .shifts-grid-body .edit-bonus-button {
        width: 100%;
        height: 3.2rem;
        color: #000000;
        background-color: rgba(0, 0, 0, 0);
        border: 1px solid #000000;
        border-radius: 3rem;
        text-align: center; }
        .shifts-page .shifts-grid .shifts-grid-body .add-bonus-button.disabled,
        .shifts-page .shifts-grid .shifts-grid-body .edit-bonus-button.disabled {
          pointer-events: none;
          background-color: #b0b0b0; }
      .shifts-page .shifts-grid .shifts-grid-body .add-buttons-column {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 0.8rem; }
        .shifts-page .shifts-grid .shifts-grid-body .add-buttons-column .action-buttons {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 90%;
          gap: 1rem; }
        .shifts-page .shifts-grid .shifts-grid-body .add-buttons-column .button-container {
          display: flex;
          justify-content: center;
          width: 90%; }
      .shifts-page .shifts-grid .shifts-grid-body .add-buttons-inline {
        padding-top: 1rem;
        display: flex;
        justify-content: center;
        gap: 1rem;
        padding-inline: 1.8rem; }
        .shifts-page .shifts-grid .shifts-grid-body .add-buttons-inline .add-slot-button,
        .shifts-page .shifts-grid .shifts-grid-body .add-buttons-inline .add-bonus-button,
        .shifts-page .shifts-grid .shifts-grid-body .add-buttons-inline .edit-bonus-button {
          height: 4rem; }
        .shifts-page .shifts-grid .shifts-grid-body .add-buttons-inline .button-container {
          flex: 1 100px;
          width: 100%; }
      .shifts-page .shifts-grid .shifts-grid-body .invite-hcp::before {
        position: relative;
        right: 3px;
        top: 1px;
        content: url("../../assets/images/calendar.svg"); }
      .shifts-page .shifts-grid .shifts-grid-body .edit-wage::before {
        position: relative;
        right: 3px;
        top: 1px;
        content: url("../../assets/images/edit-pencil.svg"); }
      .shifts-page .shifts-grid .shifts-grid-body .confirmed {
        color: #00b4c4;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center; }
        .shifts-page .shifts-grid .shifts-grid-body .confirmed:before {
          content: url(../../assets/images/confirmed.svg);
          padding-top: 2px;
          padding-right: 5px; }
      @media (min-width: 575px) {
        .shifts-page .shifts-grid .shifts-grid-body .shift-card {
          margin-left: 1rem; } }
      .shifts-page .shifts-grid .shifts-grid-body .shift-card:hover {
        background-color: #f9fafc;
        border-color: #e0e6ef; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-card.canceled-row {
        background-color: #efeeee; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-card .slot-arrival-section .select-container {
        width: 100%; }

.request-shifts-button-container .dropdown-item {
  font-size: 1.4em;
  padding: 1rem 2rem; }

.request-shifts-button-container button.btn-primary {
  color: #000000;
  background-color: #ffffff !important;
  border: 1px solid #000000 !important;
  border-radius: 4.4rem;
  font-size: 1.4rem;
  font-weight: bold;
  height: 4.4rem;
  padding: 0 5rem; }

.request-shifts-button-container button.btn-primary.read-only {
  background: #b0b0b0 !important;
  border-color: transparent !important; }

.request-shifts-button-container .show > .btn-primary.dropdown-toggle {
  color: #000000; }

.rmc-pull-to-refresh {
  width: 100%; }

.rmc-pull-to-refresh-content {
  margin-top: -70px; }
  .rmc-pull-to-refresh-content .rmc-pull-to-refresh-indicator {
    text-align: center; }

.credentials-tooltip .tooltip-inner {
  background-color: #121315;
  color: #ffffff;
  font-size: 1.4rem; }

.accounts-receivable-tooltip .tooltip-inner {
  background-color: #121315;
  color: #ffffff;
  font-size: 1.4rem; }

@media (min-width: 576px) and (max-width: 1400px) {
  .contract-tooltip,
  .orientation-tooltip,
  .guaranteed-tooltip {
    display: block !important; }
  .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block,
  .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-slot {
    width: inherit; }
    .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block-text,
    .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-slot-text {
      display: none; }
    .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block .orientation-icon,
    .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block .guaranteed-icon,
    .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-slot .orientation-icon,
    .shifts-page .shifts-grid .shifts-grid-body .shift-block .guaranteed-slot .guaranteed-icon {
      height: 1.6rem;
      margin: 1px auto 2px auto; } }

@media (max-width: 1100px) {
  .guaranteed-slot {
    max-width: 7rem;
    font-size: 0.85rem !important; }
  .guaranteed-icon {
    margin: 0 2px 3px 0;
    width: 9px; }
  .guaranteed-shift {
    font-size: 0.8rem; }
    .guaranteed-shift input {
      height: 1rem !important;
      width: 1rem !important; }
  .shift-status-cell {
    min-width: 8rem !important; }
    .shift-status-cell .add-slot-button {
      font-size: 1rem; }
    .shift-status-cell .action-buttons {
      flex-direction: column !important;
      gap: 0.8rem;
      align-items: center; }
    .shift-status-cell .add-bonus-button,
    .shift-status-cell .edit-bonus-button {
      width: 100%;
      font-size: 1rem; }
  .shifts-page {
    padding: 1.5rem 1.5rem 0 1.5rem; }
    .shifts-page .shifts-grid .shifts-grid-body .caregiver .looking-caregiver-badge-small {
      width: 11rem;
      font-size: 0.8rem; }
    .shifts-page .shifts-grid .shifts-grid-body .caregiver .replaced-caregiver {
      margin-left: 0; }
    .shifts-page .shifts-grid .shifts-grid-body .caregiver .current-caregiver {
      padding-left: 0; }
    .shifts-page .shifts-grid .shifts-grid-body .cancel-button {
      width: 7.5rem;
      height: 2.3rem;
      color: #fff;
      background-color: #f3519c;
      border: 1px solid #f3519c;
      border-radius: 4px; }
    .shifts-page .shifts-grid .shifts-grid-body .confirm-button {
      width: 7.5rem;
      height: 2.3rem;
      color: #fff;
      background-color: #00b4c4;
      border: 1px solid #00b4c4;
      border-radius: 4px; } }

@media (min-width: 576px) and (max-width: 767px) {
  .shifts-page-container {
    height: 95vh; }
  .shifts-page {
    overflow: scroll;
    background-color: #fcfcfc; }
    .shifts-page .top-buttons-wrap {
      min-height: 40px;
      margin-top: 10px; }
      .shifts-page .top-buttons-wrap .confirmation-label {
        padding: 4px;
        font-size: 1rem;
        border: 1px solid #2f385c;
        box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 30px; }
        .shifts-page .top-buttons-wrap .confirmation-label-inner {
          padding: 0 10px;
          border-radius: 30px; }
          .shifts-page .top-buttons-wrap .confirmation-label-inner-text {
            font-size: 1rem;
            line-height: 3rem; }
          .shifts-page .top-buttons-wrap .confirmation-label-inner-img {
            width: 6px;
            height: 8px;
            margin-left: 5px; }
      .shifts-page .top-buttons-wrap .request-shifts-button {
        width: 120px;
        font-size: 1rem;
        font-weight: bold;
        line-height: 3rem;
        height: 36px; }
    .shifts-page .shifts-grid .shifts-grid-body .add-buttons-inline {
      display: flex;
      flex-flow: row wrap; }
      .shifts-page .shifts-grid .shifts-grid-body .add-buttons-inline .add-slot-button {
        font-size: 1rem;
        margin-right: 0;
        max-width: 8rem !important;
        width: 100%;
        height: 3.5rem; }
    .shifts-page .shifts-grid .shifts-grid-body .caregiver .current-caregiver {
      padding-right: 0; }
    .shifts-page .shifts-grid .shifts-grid-body .confirmed {
      font-size: 1rem; }
  .guaranteed-shift {
    display: block !important; }
  .shifts-grid-body .shift-block .guaranteed-slot {
    margin: 0 !important;
    padding: 0 3px; }
  .shift-status-cell {
    min-width: 6rem !important; }
    .shift-status-cell .add-slot-button {
      max-width: 6rem !important;
      font-size: 0.8rem; } }

@media (min-width: 200px) and (max-width: 575px) {
  .shifts-page-container {
    height: 95vh; }
  .shifts-page {
    overflow: scroll; }
    .shifts-page .arrival-status {
      max-width: 40rem; }
    .shifts-page .top-buttons-wrap {
      flex-direction: column-reverse;
      gap: 1.5rem; }
      .shifts-page .top-buttons-wrap .actions {
        width: 100%; }
        .shifts-page .top-buttons-wrap .actions .request-shifts-button {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.5rem;
          line-height: 4.5rem;
          height: 44px;
          width: 100%; }
    .shifts-page .shifts-grid {
      padding-top: 1.5rem; }
      .shifts-page .shifts-grid .shifts-grid-body .shift-block {
        width: 100%;
        flex-direction: row;
        align-items: center; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .orientation-block {
          margin-left: 1.5rem; }
        .shifts-page .shifts-grid .shifts-grid-body .shift-block .unit {
          margin-top: 0;
          margin-left: 1rem; }
      .shifts-page .shifts-grid .shifts-grid-body .table-separator::after {
        content: none; }
      .shifts-page .shifts-grid .shifts-grid-body .caregiver .current-caregiver {
        justify-content: flex-start; }
      .shifts-page .shifts-grid .shifts-grid-body .caregiver .worker-badge-wrapper {
        justify-content: flex-start; }
      .shifts-page .shifts-grid .shifts-grid-body .select-status-daily-schedule {
        width: 100%; }
      .shifts-page .shifts-grid .with-border {
        margin-top: 1rem;
        border-radius: 6px;
        padding: 1rem 0; }
      .shifts-page .shifts-grid .no-left-border {
        border: 1px solid #dad8da; }
      .shifts-page .shifts-grid .shift-card {
        font-size: 1.4rem; }
        .shifts-page .shifts-grid .shift-card .row {
          padding: 0.5rem 0.5rem;
          flex-wrap: nowrap;
          width: 100%; }
          .shifts-page .shifts-grid .shift-card .row > *:first-of-type {
            vertical-align: top;
            height: 100%;
            text-align: right;
            padding: 0; }
            .shifts-page .shifts-grid .shift-card .row > *:first-of-type:after {
              content: ':'; }
    .shifts-page .edit-pencil-icon {
      margin-top: 2px;
      visibility: visible; }
      .shifts-page .edit-pencil-icon.add-icon {
        visibility: visible !important; }
    .shifts-page .add-pencil-icon {
      visibility: hidden; }
      .shifts-page .add-pencil-icon.show {
        display: block;
        visibility: visible !important;
        width: auto; }
    .shifts-page .show-mobile-add::after {
      content: '$0' !important; }
  .guaranteed-shift {
    display: flex !important;
    max-height: 3rem; }
    .guaranteed-shift-mobile {
      padding: 0.5rem 1.5rem; }
      .guaranteed-shift-mobile .shift-label {
        font-size: 12px; }
      .guaranteed-shift-mobile .guaranteed-checkbox-label {
        margin-left: 3rem; }
      .guaranteed-shift-mobile .guaranteed-checkbox-span {
        align-self: center;
        margin-left: 1rem; }
        .guaranteed-shift-mobile .guaranteed-checkbox-span .guaranteed-icon {
          width: 14px; } }

.ie-10 .with-padding {
  padding-top: 1rem !important; }
