.invite-caregiver-modal,
.assign-caregiver-modal {
  color: #444444;
  font-size: 1.4rem; }
  @media (max-width: 426px) {
    .invite-caregiver-modal,
    .assign-caregiver-modal {
      padding-left: 0 !important; } }
  .invite-caregiver-modal .modal-dialog,
  .assign-caregiver-modal .modal-dialog {
    right: 0;
    margin-left: auto;
    margin-top: 0;
    margin-right: 0;
    width: 510px; }
    @media (max-width: 510px) {
      .invite-caregiver-modal .modal-dialog,
      .assign-caregiver-modal .modal-dialog {
        width: 100vw; } }
  .invite-caregiver-modal .modal-content,
  .assign-caregiver-modal .modal-content {
    padding: 0 1.5rem;
    overflow: visible;
    margin-right: 0;
    margin-left: 0; }
    .invite-caregiver-modal .modal-content h4,
    .assign-caregiver-modal .modal-content h4 {
      width: 100%;
      text-align: left;
      border-bottom: 2px solid #121315;
      line-height: 0.1em;
      margin: 10px 0 20px; }
      .invite-caregiver-modal .modal-content h4 span,
      .assign-caregiver-modal .modal-content h4 span {
        background: #fff;
        padding-right: 10px;
        font-size: 1.6rem;
        font-weight: 600;
        color: #121315; }
  .invite-caregiver-modal .modal-header,
  .assign-caregiver-modal .modal-header {
    padding: 1.5rem 1rem 1rem 0;
    font-size: 2rem; }
    .invite-caregiver-modal .modal-header .modal-title,
    .assign-caregiver-modal .modal-header .modal-title {
      font-size: 2.4rem;
      font-weight: 400; }
    .invite-caregiver-modal .modal-header .close,
    .assign-caregiver-modal .modal-header .close {
      font-size: 2rem;
      font-weight: 400;
      padding-right: 0; }
  .invite-caregiver-modal .modal-footer,
  .assign-caregiver-modal .modal-footer {
    padding: 1.5rem 0;
    border-top: none; }
    .invite-caregiver-modal .modal-footer .btn,
    .assign-caregiver-modal .modal-footer .btn {
      border-radius: 36px;
      padding: 10px 20px;
      gap: 10px;
      font-weight: 600;
      font-size: 14px; }
    .invite-caregiver-modal .modal-footer .btn-primary,
    .assign-caregiver-modal .modal-footer .btn-primary {
      border-color: #7200a0 !important;
      background: #7200a0 !important; }
    .invite-caregiver-modal .modal-footer .btn-primary:disabled,
    .assign-caregiver-modal .modal-footer .btn-primary:disabled {
      border-color: #e3ccec !important;
      background-color: #e3ccec !important;
      color: #ffffff;
      cursor: initial; }
    .invite-caregiver-modal .modal-footer .btn-secondary,
    .assign-caregiver-modal .modal-footer .btn-secondary {
      background-color: white !important;
      color: #121315;
      border-color: #121315 !important;
      margin-right: 0.5rem;
      font-weight: 600;
      font-size: 14px; }
