.section-caregiver-search .search-invite {
  flex-direction: row; }
  .section-caregiver-search .search-invite input {
    height: 44px;
    border-left: none;
    color: #979797;
    font-size: 1.4rem; }
  .section-caregiver-search .search-invite input::placeholder {
    font-size: 1.4rem;
    font-weight: 600; }

.section-caregiver-search .search-icon {
  background-color: white;
  border-right: none;
  color: #979797;
  padding-left: 12px;
  content: url("../../../../assets/images/search-invite.svg");
  display: inline-block;
  border-radius: 0.25rem 0 0 0.25rem; }

.section-caregiver-search .searched-caregivers .list-search-caregivers {
  list-style-type: none;
  padding: 0; }
  .section-caregiver-search .searched-caregivers .list-search-caregivers button {
    padding-top: 8px;
    padding-bottom: 8px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: 600;
    background-color: white;
    border: 1px solid #e7e7e7; }
    .section-caregiver-search .searched-caregivers .list-search-caregivers button .platinum-badge-caregiver {
      margin-left: auto; }
  .section-caregiver-search .searched-caregivers .list-search-caregivers button:hover {
    background-color: #f1f1f1; }

.section-caregiver-search .search-professional-info-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row; }
  .section-caregiver-search .search-professional-info-container .info-icon {
    position: relative;
    content: url("../../../../assets/images/important.svg"); }
  .section-caregiver-search .search-professional-info-container p {
    font-style: italic;
    font-weight: 600;
    font-size: 1.4rem;
    color: #ef853b;
    padding-top: 8px;
    margin-left: 12px; }

.selected-professionals-body::-webkit-scrollbar {
  width: 4px; }

/* Track */
.selected-professionals-body::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
.selected-professionals-body::-webkit-scrollbar-thumb {
  background: #888; }

/* Handle on hover */
.selected-professionals-body-list::-webkit-scrollbar-thumb:hover {
  background: #555; }

.selected-professionals-body-list::-webkit-scrollbar {
  width: 4px; }

/* Track */
.selected-professionals-body-list::-webkit-scrollbar-track {
  background: #f1f1f1; }

/* Handle */
.selected-professionals-body-list::-webkit-scrollbar-thumb {
  background: #888; }
