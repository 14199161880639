.pro-tip-box {
  display: inline-flex;
  margin: 0rem 4rem;
  background-color: #f5e9f9; }

.pro-tip-text {
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: #531362;
  margin: 5px 30px; }
  .pro-tip-text img {
    margin-right: 4px; }
  .pro-tip-text span {
    font-weight: 700; }
