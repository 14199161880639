@import './../../stylesheets/variables.scss';

.card {
	color: $off-black;
	font-size: 1.6rem;
	line-height: 2.4rem;
	padding: 1.6rem;
	flex-direction: row !important;
	border-radius: 0.8rem !important;
	border-color: $grayscale-200 !important;

	p {
		font-size: 1.6rem;
	}

	a {
		color: $primary-500;

		&:hover {
			color: $primary-400;
			text-decoration: none;
		}
	}

	.card-body {
		padding: 0;
		flex-grow: 1;
		flex-direction: column;
	}

	.card-actions {
		display: flex;
		flex-direction: row;
		flex-shrink: 1;
		gap: 1rem;

		&__button {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 6.5rem;
			border-radius: 4px;
			background-color: $ultra-white;
			padding: 0 1rem;
			min-width: 8.2rem;
			text-align: center;
			cursor: pointer;
			font-size: 1.4rem;

			&:hover {
				background-color: $grayscale-50;
			}
		}
	}
}

@media (max-width: 992px) {
	.card {
		flex-direction: column !important;

		.card-actions {
			padding-top: 1.25rem;
			margin-top: 1rem;
			border-top: 1px solid $grayscale-100;
			justify-content: space-evenly;
		}
	}
}
