@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.eot");
  src: url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.woff") format("woff"), url("./../assets/fonts/Open-Sans/OpenSans-Regular-webfont.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'Open Sans';
  src: url("./../assets/fonts/Open-Sans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

* {
  box-sizing: border-box; }

html {
  font-size: 62.5%;
  width: 100%;
  height: 100%; }

html.keyboard-shown {
  height: 100%; }

body,
body > #root,
#root .page {
  width: 100%;
  height: 100%; }

#root {
  position: relative;
  background-color: #fff;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom); }

body {
  font-family: 'Open Sans', Arial;
  font-size: 12px;
  margin: 0;
  padding: 0;
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
  height: 100%;
  overflow: hidden;
  color: #2f385c;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

.App {
  display: flex;
  flex-flow: row wrap; }

.flex-one {
  flex: 1; }

.flex-two {
  flex: 2; }

button:focus {
  outline: none; }

.btn-primary {
  border-radius: 0;
  background-color: #00b4c4 !important;
  border-color: #00b4c4 !important; }

.btn-secondary {
  border-radius: 0;
  background-color: #ff3c9e !important;
  border-color: #ff3c9e !important; }

.vertical-ruler {
  width: 1px;
  background-color: #dad8da; }

.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
  padding: 0; }

.row {
  margin: 0; }

.btn:focus {
  box-shadow: none !important; }

.btn-success,
.show > .btn-success.dropdown-toggle {
  color: transparent !important;
  background-color: inherit !important;
  border-color: transparent !important; }
  .btn-success:focus,
  .show > .btn-success.dropdown-toggle:focus {
    box-shadow: none; }

.read-only {
  background: #b0b0b0 !important;
  border-color: transparent !important;
  pointer-events: none;
  opacity: 0.7; }

.button-read-only {
  background: #b0b0b0 !important;
  border-color: transparent !important;
  cursor: default !important;
  opacity: 0.7; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type='number'] {
  -moz-appearance: textfield; }

input.read-only:checked:before {
  background: #b0b0b0 url("../assets/images/checkbox-mark.svg") center center/100% 90% no-repeat !important; }

.sm__indicator-separator {
  display: none; }

.cursor-pointer {
  cursor: pointer; }

.modal-dialog-scrollable .modal-content {
  max-height: inherit;
  overflow: auto !important; }
