@import './../../stylesheets/variables.scss';

.search-bar {
	position: relative;
	width: 100%;

	&::before {
		position: absolute;
		top: 1rem;
		right: 1.3rem;
		content: '';
		width: 1.3rem;
		height: 1.3rem;
		background: url('./../../assets/images/search-icon.svg') 0 0/100% 100% no-repeat;
		z-index: 7;
	}

	.input {
		position: relative;
		width: 100%;
		padding-left: 1rem;

		&:active,
		&:focus-visible {
			outline: none;
			border: 2px solid $new-purple;
		}

		&.error {
			border: 2px solid $table-error;
		}
	}

	&__results-list {
		position: absolute;
		top: 4rem;
		left: 0;
		margin: 0.2rem 0 0 0;
		padding: 0;
		width: 100%;
		list-style-type: none;
		border: 1px solid $text-grey;
		box-shadow: inset 0 1px 3px 0 $text-grey, 0 2px 9px 0 $text-grey;
		z-index: 5;

		li {
			padding: 1rem;
			font-size: 1.2rem;
			background-color: $white;

			&:nth-child(even) {
				background-color: $text-grey;
			}

			&.focus {
				background-color: $text-grey;
				color: $white;
			}
		}
	}
}
