.invite-caregiver-container {
  /* Track */
  /* Handle */
  /* Handle on hover */
  /* Track */
  /* Handle */ }
  .invite-caregiver-container .section-buttons {
    background-color: white !important;
    color: #121315;
    font-size: 1.6rem;
    font-weight: 600;
    border-width: 0;
    border-bottom-width: 6px;
    border-color: #121315 !important;
    margin-right: 16px; }
  .invite-caregiver-container .section-buttons:hover {
    color: #7200a0;
    border-color: #7200a0 !important; }
  .invite-caregiver-container .selected {
    background-color: white !important;
    color: #7200a0 !important;
    font-size: 1.6rem;
    font-weight: 600;
    border-width: 0;
    border-bottom-width: 6px;
    border-color: #7200a0 !important;
    margin-right: 1rem; }
  .invite-caregiver-container .modal-body {
    padding: 2rem 0;
    font-weight: 400;
    font-size: 1.4rem;
    overflow: visible; }
    .invite-caregiver-container .modal-body .select-container {
      overflow: visible; }
  .invite-caregiver-container .selected-professionals-body {
    height: 200px;
    overflow-y: scroll; }
    .invite-caregiver-container .selected-professionals-body .list-selected-caregivers {
      list-style-type: none;
      padding: 0; }
      .invite-caregiver-container .selected-professionals-body .list-selected-caregivers .selected-caregiver-container {
        display: flex;
        flex-direction: row; }
        .invite-caregiver-container .selected-professionals-body .list-selected-caregivers .selected-caregiver-container button {
          border: none;
          background-color: white; }
        .invite-caregiver-container .selected-professionals-body .list-selected-caregivers .selected-caregiver-container button:hover {
          background-color: #a97979 !important; }
        .invite-caregiver-container .selected-professionals-body .list-selected-caregivers .selected-caregiver-container li {
          padding: 8px;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          background-color: white;
          border: 1px solid #e7e7e7;
          border-radius: 4px; }
          .invite-caregiver-container .selected-professionals-body .list-selected-caregivers .selected-caregiver-container li .platinum-badge-caregiver {
            margin-left: auto; }
  .invite-caregiver-container .selected-professionals-body-list {
    height: 200px;
    overflow-y: scroll; }
    .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers {
      list-style-type: none;
      padding: 0; }
      .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container {
        display: flex;
        flex-direction: row; }
        .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container button {
          border: none;
          background-color: white; }
        .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container button:hover {
          background-color: #a97979 !important; }
        .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container li {
          padding: 8px;
          width: 100%;
          margin-right: 1.7rem;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-weight: 600;
          background-color: white;
          border: 1px solid #e7e7e7;
          border-radius: 4px; }
          .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container li .platinum-badge-caregiver {
            margin-left: auto; }
        .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container span {
          font-weight: 400; }
          .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container span b {
            font-weight: 700; }
            .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container span b.caregiver-name {
              font-weight: 600; }
        .invite-caregiver-container .selected-professionals-body-list .list-selected-caregivers .selected-caregiver-container.accepted li {
          background-color: #e0f7e0;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25); }
  .invite-caregiver-container .selected-professionals-body::-webkit-scrollbar {
    width: 4px; }
  .invite-caregiver-container .selected-professionals-body::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .invite-caregiver-container .selected-professionals-body::-webkit-scrollbar-thumb {
    background: #888; }
  .invite-caregiver-container .selected-professionals-body-list::-webkit-scrollbar-thumb:hover {
    background: #555; }
  .invite-caregiver-container .selected-professionals-body-list::-webkit-scrollbar {
    width: 4px; }
  .invite-caregiver-container .selected-professionals-body-list::-webkit-scrollbar-track {
    background: #f1f1f1; }
  .invite-caregiver-container .selected-professionals-body-list::-webkit-scrollbar-thumb {
    background: #888; }
