.confirmation-modal {
  color: #444444;
  font-size: 1.4rem; }
  .confirmation-modal .modal-content {
    padding: 0 1.5rem;
    overflow: visible !important; }
  .confirmation-modal .modal-body {
    padding: 2.5rem 0;
    font-weight: 400;
    font-size: 1.4rem;
    overflow: visible !important; }
    .confirmation-modal .modal-body .information {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .confirmation-modal .modal-body .information .select-container {
        overflow: visible; }
      .confirmation-modal .modal-body .information .title {
        font-size: 2.7rem;
        text-align: center;
        margin-bottom: 1.1rem; }
  .confirmation-modal .ok-button {
    font-size: 1.8rem; }
