@import './../../../stylesheets/variables.scss';

.flex-request-shift-modal {
	.modal-dialog {
		max-width: 500px;
		gap: 16px;
	}

	.modal-content {
		padding: 8px;
	}

	.modal-header {
		.title {
			width: 420px;
			font-size: 18px;
			text-align: left;
			color: $black;
			font-weight: bold;
			line-height: 24px;
			font-family: 'Open Sans', sans-serif;
		}

		.close {
			font-size: 24px;
			padding: 0.5rem 1rem;
			opacity: 1;
		}
	}

	.modal-body {
		padding: 8px 0;
		display: flex;
		flex-direction: column;
		align-items: center;

		.text-content {
			width: 420px;
			height: 38px;
			font-family: 'Open Sans', sans-serif;
			font-size: 14px;
			text-align: left;
			margin-top: 8px;
			color: $black;
		}

		.box-text-content {
			width: 420px;
			padding: 10px 16px;
			background-color: $blue-200;
			border-radius: 4px;
			font-family: 'Open Sans', sans-serif;
			font-size: 14px;
			text-align: left;
			margin-top: 12px;
			margin-bottom: 8px;
			color: $black;
		}
	}

	.modal-footer {
		padding-top: 8px;
		display: flex;
		gap: 4px;

		.primary-button {
			width: auto;
			height: 40px;
			padding: 8px 16px;
			background-color: $primary-900;
			border-radius: 4px;
			font-family: 'Open Sans', sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
			text-align: center;
			color: $grayscale-0;
			border: none;
			cursor: pointer;
			order: 1;
		}

		.secondary-button {
			width: auto;
			height: 40px;
			padding: 8px 16px;
			background-color: $grayscale-0;
			border: 1px solid $primary-900;
			border-radius: 4px;
			font-family: 'Open Sans', sans-serif;
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
			text-align: center;
			color: $primary-900;
			cursor: pointer;
			order: 2;
		}
	}
}
