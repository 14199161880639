.not-found-screen {
  height: 100vh;
  padding-top: 20vh;
  display: flex;
  max-width: 1280px;
  margin: auto; }
  .not-found-screen .not-found-background {
    margin: 5rem 10rem;
    flex: 1;
    max-width: 460px;
    background: url(../../assets/images/404.svg) no-repeat;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain; }
  .not-found-screen .not-found-container {
    display: flex;
    position: relative;
    flex-direction: column;
    padding-right: 2rem; }
  .not-found-screen .title {
    color: #531362;
    font-size: 16rem; }
  .not-found-screen .subtitle {
    color: #531362;
    font-size: 2.4rem; }
  .not-found-screen .text {
    color: #444444;
    font-size: 1.4rem;
    line-height: 2.4rem;
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    text-align: left; }
  .not-found-screen .go-back {
    width: 225px;
    height: 33px;
    color: #fff;
    background-color: #00b4c4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
    padding: 1rem;
    align-self: center; }
    .not-found-screen .go-back:hover {
      text-decoration: none; }

@media (max-width: 768px) {
  .not-found-screen {
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center; }
    .not-found-screen .not-found-container {
      align-items: center; } }
