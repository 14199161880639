@import './../../stylesheets/variables.scss';

.main-menu-item {
	display: flex;
	align-items: center;
	justify-content: center;

	.main-menu-label {
		font-family: 'Open Sans', sans-serif;
		font-size: 1.6rem;
		font-weight: 600;
		line-height: 2.6rem;
		color: $ultra-white;
		padding-left: 8px;
	}

	.circle-red-number {
		min-width: 3rem;
		padding: 0.5rem;
		margin-left: 0.7rem;
		position: relative;
		top: -0.5rem;
		font-weight: 700;
		background: $ultra-white;
		color: $red-400;
		border-radius: 1.5rem;
		align-self: flex-end;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
