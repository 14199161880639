.table-title:first-child,
.table-body-column:first-child {
  max-width: 30px; }

.table-title,
.table-body-column {
  padding: 0 !important; }
  .table-title .bundle-badge-slot-view,
  .table-body-column .bundle-badge-slot-view {
    margin: 0 0 4px 14px; }

.confirmations-table-header,
.confirmations-table-rows {
  width: 99%;
  margin: 0 auto !important; }

.confirmations-table-body .table-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.8rem; }

.confirmations-table-body .table-body-column {
  font-size: 1.6rem; }
  .confirmations-table-body .table-body-column-errored,
  .confirmations-table-body .table-body-column-errored a {
    color: #595959 !important; }

.confirmations-table-body .shift-day-header-date {
  font-size: 1.8rem;
  font-weight: 400; }

.confirmations-table-body ul {
  margin: 0; }
  .confirmations-table-body ul li {
    display: flex;
    align-items: center; }

@media screen and (max-width: 996px) {
  .confirmations-table-body .table-title {
    font-size: 1.5rem; }
  .confirmations-table-body .table-body-column {
    font-size: 1.2rem; }
  .confirmations-table-body .confirmations-checkbox {
    width: 15px;
    height: 15px; }
  .confirmations-table-body .shift-day-header-date {
    font-size: 1.5rem; } }
