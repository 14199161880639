@import './../../stylesheets/variables.scss';

.font-style {
	font-family: Open Sans, sans-serif;
	font-style: normal;
	font-weight: normal;
	font-size: 1rem;
	line-height: 2rem;
	color: $ultra-white;
}

.app-header-mobile {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: $header-height;
	background-color: $primary-500;
	color: $ultra-white;
	padding: 0 2rem 0 2rem;
	z-index: 10;
	position: relative;
	--safe-area-inset-bottom: env(safe-area-inset-bottom);

	.mobile-black-text {
		color: $black;
	}

	.dropdown-item.active,
	.dropdown-item:active {
		background-color: $primary-400;
	}

	.menu-hamburguer {
		margin-right: 0;
		width: 25px;
		display: flex;
	}

	.icon-img {
		width: 20px;
		height: 20px;
	}

	.facility-and-menu {
		display: flex;
		position: absolute;
		flex-direction: column;
		background: $ultra-white;
		z-index: 1500;
		top: $header-height;

		transition: right 0.5s ease-out;
		width: 90%;
		overflow: hidden;
		right: -90%;

		&.open {
			right: 0;
		}

		.shift-menu-item {
			cursor: pointer;
			text-decoration: none;
			max-height: 6rem;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			flex-grow: 1;
			padding: 20px 35px;
			width: 100%;

			&.active {
				background-color: $primary-100;
			}

			.right-menu-label {
				padding: 0 0 0 10px;
				white-space: nowrap;
				padding-left: 25px;
				font-family: Open Sans, serif;
				font-style: normal;
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 1.83rem;
				letter-spacing: 0.01833rem;
				color: $off-black;
				cursor: pointer;
			}
		}

		.right-menu {
			flex-grow: 1;
		}

		.top-content {
			display: flex;
			flex-direction: column;
			margin: -1px -1px;
			background-color: $primary-500;

			.right-menu {
				align-items: center;
				max-height: 6rem;
				display: flex;
				flex-direction: column;
				margin: 0;
				height: 100%;

				.active {
					position: relative;
				}

				.circle-shifts-to-review {
					min-width: 3rem;
					padding: 0.5rem;
					margin-left: 0.7rem;
					position: relative;
					top: -0.5rem;
					font-weight: 700;
					border-radius: 1.5rem;
					align-self: flex-end;
					display: flex;
					justify-content: center;
					align-items: center;
					background: $red-400;
					color: $ultra-white;
				}
			}

			.facility-label-dropdown {
				align-items: center;
				display: flex;
				padding: 2rem 0;
				width: 100%;
				flex: none;
				flex-flow: row wrap;
				background-color: $primary-500;

				.select-container {
					background-color: $ultra-white;
					width: 100%;
					padding: 27px 30px;
				}

				.active-facility-label {
					color: $primary-500;
					font-family: Open Sans, serif;
					font-style: normal;
					font-weight: normal;
					font-size: 1.33rem;
					line-height: 1.83rem;
					word-wrap: break-word;
					padding: 25px 30px;
				}

				.dropdown {
					display: flex;
					max-width: 500px;
					width: 100%;
					height: 100%;
					margin-left: 3rem;

					.toggle {
						background: none;
						border: none;
						align-items: center;
						display: flex;
						color: $ultra-white;
						font-size: 1.5rem;

						.toggle-content {
							text-align: left;
							min-width: 15ch;
							max-width: 45ch;
							text-overflow: ellipsis;
							overflow: hidden;
							-webkit-line-clamp: 2;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							white-space: normal;
							word-break: break-all;
						}

						&:hover {
							text-decoration: none;
						}

						&__caret {
							width: 1.8rem;
							min-width: 1.8rem;
							margin-left: 2rem;
						}
					}

					.dropdown-menu {
						width: 100%;
						max-width: 330px;
					}
				}

				.expand-dropdown {
					padding-left: 5px;
					width: 26px;
					height: 16px;
					cursor: pointer;
					margin-right: 10px;
				}

				.rbt {
					width: 100%;
					height: 100%;

					div:first-child {
						align-items: center;
					}

					.rbt-highlight-text {
						white-space: normal;
					}

					.rbt-input-main {
						background-color: transparent;
						border: none;
						border-radius: 0;
						box-shadow: none;
						color: $white;
						font-size: 1.4rem;
						padding: 0 20px 0 20px;

						&:focus {
							background-color: $ultra-white;
							color: $off-black;
						}
					}

					.rbt-input-hint {
						display: none;
					}

					.rbt-menu {
						border-radius: 0;

						.dropdown-item {
							align-items: center;
							display: flex;
							justify-content: space-between;
							padding: 0;

							&:active {
								background-color: $primary-100;
							}

							&:hover {
								background-color: $primary-400;
								color: $ultra-white;
							}

							.text {
								padding: 0.25rem 1.5rem;
								white-space: normal;
								width: 100%;
								display: flex;
								flex-direction: row;

								span {
									flex-grow: 1;
								}

								&.selected {
									background-color: $primary-100;
								}
							}

							.pending-confirmation {
								font-size: 10px;
							}
						}
					}
				}

				> .select-wrapper {
					flex: 1 100%;
				}
			}
		}
	}

	.logo {
		display: flex;
		justify-content: center;
		width: 13rem;
		min-width: 8rem;
		height: 3.41rem;
		background: url(../../assets/images/logo-white.svg) no-repeat;
		-webkit-background-size: contain;
		-moz-background-size: contain;
		-o-background-size: contain;
		background-size: contain;
	}

	.header-menu-icon {
		width: 2.5rem;
		height: 2.5rem;
		margin-right: 22px;
	}

	.dropdown-item {
		font-size: 1.2rem;
		line-height: 2.2rem;
	}

	.sidebar-backdrop {
		display: block;
		position: absolute;
		background-color: $off-black;
		opacity: 0.4;
		width: 100%;
		height: calc(100% - 6rem);
		top: 6rem;
		z-index: 9;
	}

	.user {
		display: flex;
		background-color: $primary-500;
		min-height: 80px;
		align-items: flex-end;
		margin: 0;
		margin-left: 3.5rem;

		.user-data {
			display: flex;
			flex-direction: column;
			padding: 24px 20px 0 20px;

			.user-name {
				font-weight: bold;
				font-size: 1.8rem;
				line-height: 3rem;
			}

			.user-email {
				font-size: 1.2rem;
				line-height: 2rem;
				word-break: break-all;
			}
		}
	}

	.flex-bottom {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 2rem;

		.flex-bottom__item {
			padding: 20px 35px;
			width: 100%;

			svg {
				vertical-align: bottom;
			}

			.right-menu-label {
				display: inline;
				padding-left: 18px;
				font-family: Open Sans, serif;
				font-style: normal;
				font-weight: 600;
				font-size: 1.6rem;
				line-height: 1.83rem;
				letter-spacing: 0.01833rem;
				color: $off-black;
				cursor: pointer;
			}
		}
	}
}
