@import './../../stylesheets/variables.scss';

.external-agency-professionals {
	.total-count {
		font-size: 1.6rem;
	}

	.p-datatable-tbody {
		.row-disabled {
			> td:not(.no-line-through) {
				text-decoration: line-through;
				color: $grayscale-900;
				opacity: 0.4;
			}
		}

		.column-status {
			text-transform: capitalize;

			&.disabled {
				color: $red-500;
			}
		}
	}
}

#popup_action.professionals-actions {
	background-color: $grayscale-600;
	border-radius: 0.4rem;

	.p-menuitem-link:not(.p-disabled):hover {
		background: $grayscale-500;
	}

	.p-menuitem-text {
		color: white;
	}
}
