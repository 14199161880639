@import './../../../stylesheets/variables.scss';

#tooltip-signature {
	.tooltip-inner {
		background-color: $background-black;
		color: $ultra-white;
		font-size: 1.4rem;
	}
}

.signature-rule {
	font-family: 'Open Sans', Arial, sans-serif;

	.sub-title {
		font-weight: 700;
		font-size: 2rem;
		color: $black;

		&.mention {
			font-size: 1.8rem;
			font-style: italic;
		}
	}

	.select-signature-section {
		margin-top: 4rem;
		display: flex;
		color: $black;

		p {
			font-size: 1.6rem;
			font-weight: 600;
		}

		.signature-select,
		.clock-out {
			width: 20rem;
			margin-right: 10rem;
		}

		.signature-select {
			.sm__control {
				border-radius: 0.8rem;
				border-width: 2px;
				border-color: $white;

				&.sm__control--is-focused,
				&.sm__control--is-focused:hover {
					box-shadow: none;
					border-color: $new-purple;
				}
			}
		}

		.signature-required-box {
			border-radius: 0.8rem;
			height: 3.7rem;
			color: $white;
			border: 2px solid $white;

			p {
				font-size: 1.3rem;
				margin-top: 0.85rem;
				padding-left: 1.5rem;
			}
		}
	}

	.save-changes {
		font-weight: 600;
		line-height: 20px;
		text-align: center;
		letter-spacing: 0.05em;
		background: $medium-gray;
		border-color: $medium-gray;
		margin-top: 5rem;
		padding: 1.2rem 4rem;
		width: 25rem;
		font-size: 1.4rem;
		border-radius: 4rem;
		border: none;
		color: white;
		cursor: not-allowed !important;

		&.active {
			cursor: pointer !important;
			background: $new-purple;
		}
	}

	.info-clock-out {
		margin-left: 0.5rem;
		margin-bottom: 0.5rem;
		height: 1.6rem;
	}
}
