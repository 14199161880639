@import './../../stylesheets/variables.scss';

.edit-wage-modal {
	color: $black;

	.modal-content {
		padding: 10px 10px;
		.modal-header {
			border: none;

			.modal-title {
				font-size: 1.8rem;
				font-weight: 700;
			}

			.close {
				font-size: 3rem;
				padding-top: 0;
			}
		}

		.modal-body {
			.current-wage-section {
				font-style: italic;

				p {
					margin-bottom: 8px;
				}
			}

			.new-wage-section {
				padding-top: 18px;
				height: 101px;

				.new-wage-field {
					label {
						font-weight: 700;
					}

					.input-group {
						border: 1px solid $medium-gray;
						border-radius: 3px;
						font-weight: 600;
						width: 37%;

						span {
							background-color: transparent;
							border: none;
							font-size: 1.2rem;
							font-weight: 600;
							color: $black;
						}

						input {
							border: none;
							height: 27px;
							font-size: 1.2rem;
							font-weight: 600;
							padding-left: 1px;
							color: $black;
						}

						:focus {
							box-shadow: none;
						}

						::placeholder {
							color: $info-gray;
						}

						&.invalid {
							border-color: $warning-contacts;
						}
					}

					.error-message {
						font-style: italic;
						color: $warning-contacts;
						font-weight: 700;
						font-size: 1.2rem;
					}
				}

				.new-wage-confirm {
					display: flex;
					align-items: center;
					justify-content: center;

					div {
						width: 25%;
						text-align: center;
						padding: 0 5px;

						&:nth-of-type(2) {
							width: 7%;
							padding: 10px 0 0 0;
						}

						p {
							font-weight: 600;
							margin-bottom: 5px;
						}

						.big-number {
							font-size: 2.6rem;
							font-weight: 400;

							&.previous {
								color: $grey-info;
								text-decoration: line-through;
								text-decoration-thickness: 1px;
							}

							&.new {
								font-weight: 700;
							}
						}

						img {
							width: 100%;
						}

						svg {
							position: relative;
							top: 0.45rem;
						}
					}
				}
			}

			.shift-info-section {
				padding-top: 15px;

				p {
					font-weight: 600;

					strong {
						font-weight: 700;
					}

					.guaranteed {
						color: $guaranteed-badge-color;
					}

					span {
						border-left: 1px solid $info-gray;
						padding: 0 7px;

						&:first-of-type {
							border-left: 0;
						}

						img {
							height: 100%;
						}
					}
				}
			}
		}

		.modal-footer {
			border: none;

			.buttons {
				display: flex;
				gap: 10px;

				button {
					width: 7.875rem;
					height: 3.5rem;
					font-weight: 400;
					font-size: 1.3rem;
					border-radius: 1.875rem;

					&.cancel {
						color: $black;
						background-color: transparent;
						border: 1px solid $black;
					}

					&.confirm {
						background-color: $new-purple;
						color: $ultra-white;
						border: 1px solid $new-purple;
					}

					&.disabled {
						cursor: not-allowed;
					}
				}
			}

			.go-back-button {
				display: flex;
				align-items: center;
				background-color: transparent;
				border: none;
				font-size: 1.3rem;
				color: $upload-blue;
				font-weight: 600;

				svg {
					position: relative;
					top: 0.45rem;
				}
			}

			&.confirm-layout {
				justify-content: space-between;
			}
		}

		.bottom-divider {
			border-bottom: 1px solid $info-gray;
		}
	}
}
