@import './../../stylesheets/variables.scss';

.search-professional-container {
	border: 1px solid $grayscale-300;
	border-radius: 4px;
	width: 100%;
	background-color: $ultra-white;
	height: 4rem;

	&.active {
		outline: 1.5px solid $new-purple !important;
	}

	.clear-button {
		font-size: 1.4rem;
		font-weight: 400;
		color: $red-500;
		cursor: pointer;
		border: none;
		background-color: $ultra-white !important;
	}

	.clear-button:hover {
		color: $red-500;
	}

	.search-professional {
		padding-right: 12px;
		flex-wrap: nowrap !important;
		flex-direction: row;

		.search-icon {
			border-radius: 0rem;
			background-color: $ultra-white;
			border: none;
			color: $grayscale-900;
			content: url('../../assets/images/search-invite.svg');
			display: inline-block;
			opacity: 1;
			margin-left: 4px;
			margin-bottom: 0.2rem;
		}

		input {
			height: 3.7rem;
			border: none;
			color: $grayscale-900;
			font-size: 1.4rem;
		}

		input::placeholder {
			font-size: 1.4rem;
			font-weight: 400;
		}

		input.form-control:focus {
			outline: none !important;
			color: $grayscale-900;
			background-color: $ultra-white;
			border-color: #ced4da;
			box-shadow: none;
			font-weight: 600;
		}
	}

	.professional-search__avatar {
		height: 3rem;
		border-radius: 50%;
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.searched-professional {
		z-index: 1000;
		position: sticky;
		width: 32rem;
		margin-top: 2px;

		&.hide {
			display: none !important;
		}

		.list-search-professional {
			list-style-type: none;
			padding: 0;

			li {
				padding: 8px;
				display: flex;
				align-items: center;
				font-weight: 600;
				background-color: $ultra-white;
				border: 1px solid $badge-grey;
				border-bottom: none;

				.platinum-badge-professional {
					margin-left: auto;
				}
			}

			li:last-child {
				border-bottom: 1px solid $badge-grey;
			}

			li:hover {
				background-color: rgb(241, 241, 241);
			}
		}
	}

	.selected-professional-container {
		align-items: center;
		display: flex;
		flex-grow: 1;
		height: 4rem;

		li {
			flex-grow: 1;
			display: flex;
			justify-content: space-between;
			flex-direction: row;
			align-items: center;
			font-weight: 600;
			background-color: $grayscale-0;
			border: $grayscale-0;
			border-radius: 0.4rem;

			.selected-professional-details {
				display: flex;
				align-items: center;
				gap: 1rem;
				line-height: 1;
				max-height: 3.6rem;
				overflow: hidden;
				max-width: 24rem;
				text-overflow: ellipsis;
			}

			.remove-button {
				display: flex;
				gap: 1rem;

				button {
					border: none;
					background-color: $grayscale-0;
					font-size: 2.3rem;
					border-left: $grayscale-0;

					img {
						margin-bottom: 0.4rem;
					}
				}

				button:hover {
					background-color: rgb(169, 121, 121) !important;
					border-radius: 0 0.25rem 0.25rem 0;
				}
			}
		}
	}
}

@media (min-width: 200px) and (max-width: 575px) {
	.search-professional-container {
		width: 100%;
	}
}
