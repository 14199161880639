.confirmation-modal .modal-dialog .modal-content {
  border: 2px solid #dddddd;
  box-sizing: border-box;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.25); }
  .confirmation-modal .modal-dialog .modal-content .modal-body {
    padding: 1.5rem 0; }

.confirmation-modal {
  font-family: Open Sans, sans-serif; }
  .confirmation-modal .information .close {
    margin-left: 92%;
    font-size: 3.6rem;
    font-weight: 400; }
  .confirmation-modal .information .title {
    margin-top: 2rem;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: #000000; }
  .confirmation-modal .information .subtitle {
    padding: 0 1.5rem;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000; }
  .confirmation-modal .modal-footer {
    padding-top: 0; }
    .confirmation-modal .modal-footer .close-button {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #7200a0;
      border: none;
      border-radius: 30px;
      padding: 10px 20px;
      width: 160px;
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 600;
      line-height: 2.2rem;
      margin-bottom: 20px; }
