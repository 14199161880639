.assign-professional-modal {
  color: #121315;
  font-size: 1.4rem; }
  @media (max-width: 426px) {
    .assign-professional-modal {
      padding-left: 0 !important; } }
  .assign-professional-modal .modal-dialog {
    margin: 0 0 0 auto;
    padding-top: 60px;
    height: 100%; }
  .assign-professional-modal .modal-content {
    height: 100%;
    padding: 0 1.5rem;
    overflow: visible;
    margin-right: 0;
    margin-left: 0; }
    .assign-professional-modal .modal-content h4 {
      width: 100%;
      text-align: left;
      border-bottom: 2px solid #121315;
      line-height: 0.1em;
      margin: 10px 0 20px; }
      .assign-professional-modal .modal-content h4 span {
        background: #fff;
        padding-right: 10px;
        font-size: 1.6rem;
        font-weight: 600;
        color: #121315; }
  .assign-professional-modal .modal-header {
    padding: 2.5rem 1rem 2.5rem 1rem;
    font-size: 2rem;
    border-bottom: 0px !important; }
    .assign-professional-modal .modal-header .modal-title {
      font-size: 2.4rem;
      font-weight: 400; }
    .assign-professional-modal .modal-header .close {
      color: #323335;
      font-weight: 400;
      font-size: 3rem;
      opacity: initial; }
  .assign-professional-modal .modal-footer {
    padding: 1.5rem 0;
    border-top: 1px solid #e7e7e7; }
    .assign-professional-modal .modal-footer .btn {
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 1.4rem;
      line-height: 2.4rem; }
    .assign-professional-modal .modal-footer .btn-primary {
      border-color: #7200a0 !important;
      background: #7200a0 !important; }
    .assign-professional-modal .modal-footer .btn-primary:disabled {
      border-color: #e3ccec !important;
      background-color: #e3ccec !important;
      color: #ffffff !important;
      cursor: initial; }
    .assign-professional-modal .modal-footer .btn-secondary {
      background-color: #ffffff !important;
      color: #121315 !important;
      border-color: #121315 !important;
      margin-right: 0.5rem;
      font-weight: 600;
      font-size: 14px; }
    .assign-professional-modal .modal-footer .btn-tertiary {
      margin-right: 0.5rem;
      color: #7200a0; }
