@import '../../stylesheets/variables.scss';

.past_shift--badge {
	display: inline-block;
	padding: 0.3rem 1rem;
	text-align: center;
	background-color: $late-cancellation-badge;
	color: white;
	border-radius: 5px;
	white-space: nowrap;

	font-weight: bold;
	font-size: 1.2rem;
}

// Sticking to bootstrap breakpoint
@media (max-width: 991px) {
	.past_shift--badge {
		font-size: 1rem;
	}
}
