@import './../../stylesheets/variables.scss';

.warning-past-shift-modal {
	color: $default-text-color;
	max-height: 100%;
	font-size: 1.4rem;

	.modal-dialog {
		max-width: 30%;
	}

	.modal-content {
		padding: 0.5rem 6rem;
		overflow: visible !important;
		border-radius: 2rem;
	}

	.modal-header {
		padding: 1.5rem 1rem 0 0;
		font-size: 2rem;
		border-bottom: none;

		.modal-title {
			width: 100%;
			text-align: left;

			h2 {
				font-size: 3.3rem;
				font-weight: 400;
			}

			.confirmation-subtitle {
				font-size: 1.6rem;
			}
		}

		.close {
			font-size: 6rem;
			font-weight: 400;
			color: black;
			opacity: 0.5;
			padding: 0;
			height: 5rem;

			span {
				height: 5rem;
				display: inline-block;
			}
		}

		hr {
			margin: 1.2rem 0 1.2rem 0;
		}
	}

	.container {
		display: flex;
		flex-direction: column;
		align-items: center;

		img {
			width: 23.897rem;
		}

		.text-info-past {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			h2 {
				font-size: 2.6rem;
				font-weight: 700;
				line-height: 5.4rem;
				letter-spacing: 0rem;
			}

			p:first-child {
				font-size: 1.8rem;
				font-weight: 400;
				line-height: 2.6rem;
				letter-spacing: 0rem;
			}

			p:last-child {
				font-size: 1.1rem;
				font-style: italic;
				font-weight: 300;
				line-height: 1.5rem;
				letter-spacing: 0rem;
			}
		}
	}

	.modal-footer {
		border-top: none;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 1.5rem;

		.button {
			width: 100%;
			font-style: normal;
			font-size: 1.8rem;
			line-height: 5.4rem;
			&-approve {
				color: white;
				border: none;
				background-color: $soft-purple;
				height: 4rem;
				font-weight: bold;
				border-radius: 2rem;
				width: 100%;
				margin-bottom: 1.5rem;
			}
			&-cancel {
				border: none;
				width: 100%;
				background-color: white;
				color: $table-header-grey;
			}
		}
	}
}
