@import './../../stylesheets/variables.scss';

.react-datepicker {
	font-size: 1.4rem;
	border-color: $new-purple !important;
	border: 2px solid;
	border-radius: 8px;

	&__week {
		height: 30px;
	}

	&__week:last-child {
		height: 40px;
	}

	&__triangle {
		display: none;
	}

	&__month {
		width: 30rem;
		height: 100%;
	}

	&__day,
	&__day-name {
		margin: 0.7rem;
	}

	&__day--selected,
	&__day--selected:hover {
		display: inline-flex;
		border-radius: 50%;
		background-color: $new-purple !important;
		color: white !important;
		font-weight: 400 !important;
		text-decoration: none !important;
		width: 25px;
		height: 25px;
		align-items: center;
		justify-content: space-around;
		margin: 3px;
	}

	&__day--keyboard-selected {
		background-color: $ultra-white;
		color: $black;
		text-decoration: none;
		outline: none;
		border: none;

		&.react-datepicker__day--disabled {
			background-color: $ultra-white;
			color: #ccc;
			text-decoration: none;
			outline: none;
			border: none;
		}
	}

	&__day--today {
		background-color: white;
		color: $new-purple;
		text-decoration: underline;
		font-weight: bold;
	}

	&__day:hover {
		display: inline-flex;
		border-radius: 50%;
		width: 25px;
		height: 25px;
		align-items: center;
		justify-content: space-around;
		margin: 3px;
	}

	&__header {
		border-bottom: hidden;
	}

	&__navigation {
		top: 30px;
		width: 26px;
	}

	&__navigation--previous,
	&__navigation--previous:hover {
		border-right-color: $new-purple;
	}

	&__navigation--next,
	&__navigation--next:hover {
		border-left-color: $new-purple;
	}

	&__current-month {
		font-size: 1.8rem;
	}
}
