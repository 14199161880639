@import './../../stylesheets/variables.scss';

.multi-select-container {
	min-width: 17rem;

	.button {
		min-height: 4.4rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		border: 1px solid $light-grey;
		border-radius: 2px;
		cursor: pointer;
		color: $light-grey;
		caret-color: transparent;
		background-color: $ultra-white;
		font-weight: 600;
		font-size: 1.4rem;
		padding: 2px 3px;
		width: 100%;

		span > b {
			font-weight: 600;
			font-size: 1.4rem;
			color: $black;
		}

		img {
			transform: rotate(90deg);
			height: 1.1rem;
			width: 6px;
			margin-right: 5px;
		}
	}
	.active {
		outline: 1.5px solid $new-purple;

		img {
			transform: rotate(270deg);
		}
	}

	.content {
		z-index: 1000;
		background-color: $ultra-white;
		margin-top: 1px;
		width: 27rem;
		border: 1px solid $light-grey;
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
		padding: 16px;

		.search-section {
			height: 6%;
			width: 100%;
			position: relative;

			input {
				width: 100%;
				border: 1px solid $grayscale-400;
				border-radius: 4px;
				padding: 8px 32px 8px 16px;

				&:focus-visible {
					outline: 1px solid $grayscale-600;
				}
			}

			&.no-results-search input {
				border-radius: 4px 4px 0 0;
			}

			.no-results {
				text-align: center;
				line-height: 2.4rem;
				color: $off-black;
				padding: 16px;
				border-radius: 0 0 4px 4px;
				border: 1px solid $grayscale-400;
				border-top: none;
				background-color: $grayscale-25;
			}

			.search-icon {
				position: absolute;
				right: 4px;
				top: 12px;
				bottom: 0;
				padding: 0 1rem;
			}

			.delete-icon {
				position: absolute;
				right: 4px;
				top: 12px;
				bottom: 0;
				padding: 0 1rem;
				height: 1.2rem;
				cursor: pointer;
				opacity: 0.5;

				&:hover {
					opacity: unset;
				}
			}
		}

		.checkbox-section {
			max-height: 25rem;
			width: 100%;
			overflow-y: auto;
			margin-top: 16px;

			.checkbox-item {
				height: 2.4rem;
				display: flex;
				gap: 6px;
				align-items: center;
				margin-bottom: 1rem;
				padding-left: 16px;

				&.select-all {
					padding-left: 0;
				}

				input {
					min-width: 2.4rem;
					height: 1.8rem;

					&:before {
						content: ' ';
						position: relative;
						display: inline-block;
						width: 2.4rem;
						height: 2.4rem;
						border-radius: 2px;
						border: none;
						top: -3px;
						background: $ultra-white url('../../assets/images/checkbox-unchecked.svg') center center/100% 90% no-repeat;
					}

					&:checked:before {
						background: $ultra-white url('../../assets/images/checkbox-checked-purple.svg') center center/100% 90%
							no-repeat;
					}
				}

				.checkbox-item-label {
					color: $grayscale-900;
					font-size: 1.2rem;
					line-height: 1.6rem;
					text-align: left;
				}

				label {
					margin-bottom: 0 !important;
				}
			}
		}

		hr {
			margin: 6px 0;
		}

		.footer-section {
			max-height: 7%;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			padding-top: 10px;

			.cancel-button {
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 2.4rem;
				color: $primary-500;
				background-color: $ultra-white;
				border: none;
				padding: 0.4rem 1.6rem;
				margin-right: 1rem;
				cursor: pointer;
			}

			.cancel-button:hover {
				color: $new-purple;
			}

			.apply-button {
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 2.4rem;
				color: $ultra-white;
				background-color: $new-purple;
				border: none;
				border-radius: 4px;
				padding: 4px 16px;
				cursor: pointer;

				&.disabled {
					background-color: $grayscale-100;
					cursor: not-allowed;
				}
			}
		}
	}
}

@media (min-width: 200px) and (max-width: 575px) {
	.multi-select-container {
		width: 100%;
	}
}

@media (min-width: 768px) {
	.multi-select-container {
		.content {
			position: absolute;
		}
	}
}
