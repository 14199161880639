@import './../../stylesheets/variables.scss';

.external-agency-screen {
	width: 100%;
	height: calc(100vh - 60px);
	padding: 32px;
	display: flex;
	flex-direction: column;

	.title {
		font-size: 2.4rem;
		font-weight: 700;
		line-height: 3.2rem; /* 133.333% */
		letter-spacing: -0.333px;
		padding-bottom: 16px;
		color: $grayscale-900;
	}

	.global-search-container {
		position: relative;

		input {
			min-width: 392px;
			padding: 8px 10px 8px 32px;
			border-radius: 4px;
			border: 1px solid $grayscale-400;
			color: $grayscale-400;
			font-size: 1.4rem;
			line-height: 2.4rem;
		}

		&:before {
			position: absolute;
			margin-top: 14px;
			left: 10px;
			content: '';
			width: 1.4rem;
			height: 1.4rem;
			background: url('./../../assets/images/search-icon.svg') 0 0/100% 100% no-repeat;
			z-index: 7;
		}
	}

	.p-tabview {
		display: flex;
		height: 100%;
		flex-direction: column;

		.p-tabview-panels,
		.p-tabview-panel {
			height: 100%;
			display: flex;
			flex-direction: column;
		}

		.p-tabview-panels {
			padding: 16px 0 0 0;
		}

		.p-tabview-nav {
			.p-tabview-nav-link {
				font-size: 1.6rem;
				line-height: 2.4rem;
				font-weight: 400;
				color: $black;

				&:focus {
					box-shadow: none !important;
				}
			}

			li.p-highlight .p-tabview-nav-link {
				font-weight: 700;
				color: $primary-500;
				border-color: $primary-500;
			}

			li:not(.p-highlight):hover .p-tabview-nav-link {
				border-color: $black !important;
				color: $black !important;
			}
		}
	}

	.toolbar {
		display: flex;
		gap: 16px;
		padding-bottom: 32px;
		font-size: 1.4rem;

		.multi-select-container > button {
			min-height: 4rem;
		}

		.date-range-selector {
			display: flex;
		}

		.refresh-button {
			border: none;
			background-color: inherit;
			color: $primary-500;
			padding: 8px 12px;
			font-size: 14px;
			cursor: pointer;
			display: flex;
			gap: 4px;
			align-items: center;

			&:hover {
				color: $primary-800;
				font-weight: 600;
				letter-spacing: -0.28px;

				.button-label {
					margin-top: -1px;
				}
			}
		}
	}
	.datatable-wrapper {
		display: flex;
		flex-direction: column;
		position: relative;
		flex: 1;

		& > .wrapper-container {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}
	}
	.p-datatable {
		flex: 1;
		font-size: 1.4rem;
		line-height: 2.4rem;
		letter-spacing: -0.333px;

		.p-column-header-content {
			justify-content: space-between;
		}
		&.p-datatable-sm > .p-datatable-footer {
			font-weight: normal;
			padding: 0;
			border: 0;
			background-color: transparent;
		}
		&.p-datatable-striped .p-datatable-tbody .p-row-odd {
			background-color: $grayscale-25;
		}

		.p-datatable-table {
			.p-datatable-thead th,
			.table-cell {
				padding: 8px 8px 8px 16px;
				color: $off-black;
				font-weight: 400;

				&.p-frozen-column {
					box-shadow: inset 10px 0 20px -15px $grayscale-300;
				}
			}

			.p-datatable-tbody > tr.p-selectable-row:focus {
				outline: none;
			}
		}
	}
}
