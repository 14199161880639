@import './../stylesheets/variables.scss';

.sidebar-wrapper {
	&__container {
		width: 100%;
		height: 100vh;
		overflow-y: scroll;
		margin-bottom: 4rem;
		padding: 2rem;
	}

	&__page {
		display: flex;
		min-height: 100%;

		h1 {
			font-size: 1.6rem;
			font-weight: 700;
			line-height: 5rem;
			text-align: left;
		}
	}

	&__screen {
		position: relative;
		height: 100%;
		width: 100%;
		flex-grow: 1;
		font-style: normal;
		padding-bottom: 12rem;

		.title-wrapper {
			display: flex;
			align-items: baseline;
			background-color: $ultra-white;
			z-index: 2;
			border-bottom: 0.15rem solid $grayscale-100;
			line-height: 5rem;
			margin-bottom: 2rem;
			position: sticky;
			top: -2.2rem;
			white-space: nowrap;

			font-size: 2rem;
			font-weight: 700;

			h2 {
				font-size: inherit;
				font-weight: inherit;
				text-overflow: ellipsis;
				overflow: hidden;
			}

			@media (max-width: 992px) {
				justify-content: space-between;
			}

			.toggle-section {
				background: none;
				border: none;

				@media (min-width: 992px) {
					display: none;
				}
			}

			.action {
				margin-left: 2rem;
				font-weight: 700;

				@media (max-width: 992px) {
					margin-left: auto;
				}

				white-space: nowrap;
			}
		}
	}

	&__dropdown button {
		&:active,
		&.active {
			background-color: $grayscale-100;
		}

		&:hover {
			background-color: $grayscale-200;
			text-decoration: none;
			color: inherit;
		}
	}
}

.agency-page {
	.title-wrapper {
		padding-bottom: 1.5rem;
		padding-top: 1rem;
		width: 40%;
	}
	.sidebar-wrapper__container {
		width: 100%;
		margin-bottom: 4rem;
		padding: 2rem;
	}

	.sidebar-wrapper__page {
		display: flex;
		height: 100%;
	}

	.sidebar-wrapper__screen {
		position: relative;
		height: 100%;
		width: 100%;
		flex-grow: 1;
		font-style: normal;
		padding-left: 3rem;
		padding-bottom: 0;

		.title-wrapper {
			display: flex;
			align-items: baseline;
			z-index: 2;
			margin-bottom: 2rem;
			position: sticky;
			top: -2.2rem;
			white-space: nowrap;
			padding-bottom: 1.5rem;

			h2 {
				font-size: 2.4rem;
				font-weight: 700;
				color: $off-black;
			}

			.toggle-section {
				background: none;
				border: none;
			}

			.action {
				margin-left: auto;
				margin-right: 1.6rem;
				font-weight: 400;
				font-size: 1.4rem;
				white-space: nowrap;
				color: $primary-500;
				line-height: 2.4rem;
				svg {
					margin: 0 0.4rem 0 0;
					width: 1.2rem;
					height: 1.6rem;
				}
				@media (max-width: 992px) {
					margin-left: auto;
				}
			}
		}

		@media (max-width: 992px) {
			.title-wrapper {
				justify-content: space-between;

				.toggle-section {
					display: none;
				}

				.action {
					margin-left: auto;
				}
			}
		}
	}

	.sidebar-wrapper__dropdown button:hover {
		background-color: #cececf;
		text-decoration: none;
		color: inherit;
	}

	.sidebar-back__action {
		display: flex;
		align-items: center;
		color: $off-black;
		width: 200px;
	}

	.sidebar-back__action__title {
		padding: 1rem 1rem 2.4rem 2.4rem;
		display: flex;
		align-items: center;
		color: $primary-500;
		font-weight: 700;
		font-size: 1.6rem;
		border: none;
		border-bottom: 0.15rem solid #e7e7e7;
		background-color: transparent;
		text-align: left;
		gap: 0.8rem;

		&:hover,
		&:active {
			color: $primary-800;
		}
	}

	.sidebar-wrapper_menu {
		margin-left: 0.75rem;
		padding-right: 3rem;
		justify-items: center;
	}

	.sidebar {
		border-right: none;
		justify-items: center;
	}

	.sidebar_menu {
		color: $primary-500;
		font-weight: 700;
		line-height: 1.5rem;
		font-size: 1.5rem;
		background-color: white;
		border-left: 0.2rem solid $primary-500;
		padding: 0.5rem 1rem;

		&.active {
			background-color: transparent;
		}
		&:hover {
			color: $primary-800;
		}
	}

	&.sidebar-wrapper__container {
		height: 100%;
		overflow-y: auto;
		max-height: calc(100vh - 6rem);
	}
}
