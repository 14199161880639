$default-text-color: #2f385c;
$text-silver: #757575;
$default-purple: #531362;
$new-purple: #7200a0;
$badge-grey: #e7e7e7;
$smart-purple: #6939b7;
$button-purple: #4811a3;
$table-divider-gray: #dad8da;
$info-gray: #dadada;
$text-grey: #545454;
$checkbox-grey: #595959;
$dark-grey: #444444;
$light-grey: #b0b0b0;
$disabled-grey: #c4c4c4;
$background-light-grey: #fcfcfc;
$background-grey: #f3f3f3;
$background-black: #121315;
$teal: #00b4c4;
$pink: #f3519c;
$pink-1: #ff3c9e;
$pink-2: #ff03a0;
$border-color: #e0e6ef;
$orientation: #8a78a0;
$row-highlight: #f9fafc;
$row-highlight-border: #e0e6ef;
$past-shift-grey: #e5e5e5;
$success-green: #8cc152;
$navy: #23275a;
$confirmed-slot-status: #54be7a;
$error-red: #a02e44;
$dnr-red: #9d2424;
$table-error: #9f2f45;
$default-blue: #26a5da;
$table-header-grey: #717579;
$border-color: #e0e6ef;
$soft-grey: #efefef;
$past-shifts-message: #5870d1;
$red-label: #b32929;
$red-error-past-shift: #962d2d;
$cancelled-slot: #efeeee;
$dispute-resolved: #04724d;
$approved-slot: #54be7a;
$dispute-slot: #b32929;
$loading-bg-color: rgba(247, 247, 247, 0.8);
$header-height: 6rem;
$shift-cell-height: 6rem;
$returning-worker-badge-bg: rgba(127, 217, 225, 0.3);
$returning-worker-badge: #575e9f;
$looking-caregiver-badge: #fddceb;
$guaranteed-badge-bg: #e8eafc;
$guaranteed-badge-color: #00a698;
$rn-badge: #ffa900;
$lpn-badge: #cc51f4;
$late-cancellation-badge: #bb2026;
$soft-grey-border: #d8d8d8;
$soft-black: #424242;
$portal-grey: #717579;
$upload-blue: #316df0;
$grey-info: #545556;
$warning-contacts: #ba3838;
$line-divider: #e7e7e7;
$warning-yellow: #ffe3a4;
$warning-notification: #f6d046;
$grey-background: #f5f5f5;

$blue: rgba(45, 156, 219, 0.15);
$light-blue: rgba(242, 249, 253, 1);

$pale-white: #edecfb;
$soft-purple: #6939b7;

$purple: rgba(187, 107, 217, 0.15);
$light-purple: rgba(187, 107, 217, 0.06);

$yellow: #fefcf4;
$light-yellow: rgba(242, 201, 76, 0.15);
$banner-yellow: #ffb81c;

$light-green: rgba(242, 249, 245, 1);
$green: rgba(33, 150, 83, 0.14);
$request-shifts-green: #01a698;

$box-shadow: rgba(0, 0, 0, 0.25);
$transparent: rgba(0, 0, 0, 0);

$disputes-grey-title: #2f385c;
$disputes-prefix-color: #717579;
$disputes-section-title-color: #5870d1;
$guaranteed-badge-color: #00a698;

$dnr-tooltip-border: #c4c4c4;
$medium-gray: #b0b0b0;
$dnr-caregiver-picture-border: #b0b0b0;

$secondary-100: #f3f2ee;
$active-purple: #511c7e;
$active-purple-mobile: #d2c4e9;
