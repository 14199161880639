@import './../../stylesheets/variables.scss';

.edit-unit-modal {
	.resource {
		font-weight: 600;
	}

	.select {
		width: 300px;

		@media (min-width: 200px) and (max-width: 575px) {
			width: 100%;
		}
	}

	.shift-detail {
		padding: 0 1rem;
		border-right: 1px solid $grayscale-200;

		@media (min-width: 200px) and (max-width: 575px) {
			width: 100%;
			border-right: none;
			line-height: 3.2rem;
			padding: 0;
			display: block;
		}

		&:last-of-type {
			border-right: none;
		}

		&:first-of-type {
			padding-left: 0;
		}

		&.guaranteed {
			display: inline-block;
			font-weight: 600;
			color: $secondary-500;
		}
	}
}
