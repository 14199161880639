@import './../../stylesheets/variables.scss';

.global-overview-screen {
	.overview-sidebar {
		display: flex;
		flex-direction: column;
		border-right: 0.15rem solid $grayscale-100;
		font-size: 1.5rem;
		font-weight: 600;
		color: $off-black;
		width: 200px;
		min-width: 200px;
		padding: 24px 20px;

		.heading {
			border-bottom: 1px solid $grayscale-150;
			display: flex;
			align-items: center;
			font-size: 1.6rem;
			font-weight: 600;
			line-height: 2.4rem; /* 150% */
			letter-spacing: -0.333px;
			padding-bottom: 8px;
			margin-bottom: 16px;

			.facilities-icon {
				padding-right: 4px;
			}
		}

		.search-container {
			display: flex;
			position: relative;

			.search-input {
				padding: 8px 24px 8px 28px;
				color: $grayscale-500;
				border: 1px solid $light-grey;
				font-size: 14px;
				line-height: 14px;
				font-weight: 500;
				width: 100%;
			}

			.search-icon {
				position: absolute;
				left: 9px;
				top: 12px;
			}

			.delete-icon {
				position: absolute;
				right: 8px;
				top: 12px;
				height: 1.2rem;
				cursor: pointer;
			}
		}

		.selected-facility-heading {
			font-size: 1.3rem;
			font-weight: 700;
			line-height: 1.6rem;
			letter-spacing: -0.333px;
			padding-top: 12px;
		}

		.checkbox-section {
			padding-left: 4px;
			margin-top: 16px;
			margin-bottom: 8px;
			overflow: auto;

			.checkbox-item {
				display: flex;
				align-items: start;
				margin-bottom: 14px;

				input {
					min-width: 1.8rem;
					height: 1.8rem;
					accent-color: $new-purple;
					margin-right: 7px;
					border: 2px solid $black;
					border-radius: 2px;
				}

				.checkbox-item-label {
					font-size: 1.3rem;
					line-height: 16px;
					text-align: left;
					color: $black;
					letter-spacing: -0.333px;
					margin-bottom: 0;
				}
			}
		}

		.reset-container {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 8px 0;
			margin-top: 8px;
			border-top: 1px solid $grayscale-150;

			.reset-button {
				border: none;
				background-color: transparent;
				color: $red-500;
				font-size: 1.4rem;
				font-weight: 600;
				line-height: 1.6rem;
				letter-spacing: -0.333px;
			}
		}

		.buttons-container {
			display: flex;
			gap: 8px;
			padding-top: 16px;
			border-top: 1px solid $grayscale-150;

			.button {
				display: flex;
				padding: 8px 16px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				gap: 10px;
				flex: 1 0 0;
				border-radius: 46px;
				font-size: 1.4rem;
				font-weight: 600;
				line-height: normal;
			}

			.primary-button {
				border: none;
				background-color: $primary-500;
				color: $grayscale-0;

				&.disabled {
					background-color: $grayscale-100;
					color: $grayscale-0;
				}
			}

			.secondary-button {
				border: 1.5px solid $grayscale-900;
				background-color: $grayscale-0;
				color: $grayscale-900;

				&.disabled {
					border: 1.5px solid $grayscale-100;
					color: $grayscale-100;
				}
			}
		}
	}
}

.checkbox-label-tooltip {
	.tooltip-inner {
		border-radius: 4px;
		font-size: 1.4rem;
		padding: 6px 8px;
	}
}
