.facility__cancelled--badge {
  display: inline-block;
  padding: 0.3rem 1rem;
  text-align: center;
  background-color: #bb2026;
  color: white;
  border-radius: 5px;
  white-space: nowrap;
  font-weight: bold;
  font-size: 1.2rem; }

@media (max-width: 991px) {
  .facility__cancelled--badge {
    font-size: 1rem; } }
