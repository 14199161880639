@import '../../stylesheets/variables.scss';

.base-modal {
	&.confirm-modal {
		&.reopen-shift-modal {
			.modal-content {
				.modal-footer {
					.primary-button {
						border-color: $primary-500;
						background-color: $primary-500;
					}
				}
			}
		}
	}
}

.reopen-shift-modal {
	&__container {
		display: flex;
		flex-direction: column;
	}

	&__title {
		font-size: 1.6rem;
		font-weight: 600;
		padding: 0.5rem 0;
	}

	&__facility {
		padding: 0.5rem 0;

		&__name {
			font-size: 1.6rem;
			font-weight: 700;
		}
	}

	&__shift-details {
		display: flex;
		flex-direction: row;
		padding: 0.5rem 0;

		&__resource {
			padding-right: 3rem;
			border-right: 1px solid $info_gray;

			&__name {
				font-size: 1.6rem;
				font-weight: 700;
			}
		}

		&__date {
			padding-left: 3rem;
			padding-right: 3rem;
			border-right: 1px solid $info_gray;
		}

		&__time {
			padding-left: 3rem;
		}
	}
}
