@import './../../stylesheets/variables.scss';

.base-badge {
	display: flex;
	justify-content: center;
	padding: 4px 8px;
	border-radius: 4px;
	min-width: 10rem;
	width: fit-content;
	margin: 0.2rem;
	background: $badge-default;
	color: $grayscale-900;
	font-weight: 400;
	align-items: stretch;
	font-size: 1rem;

	> svg {
		width: 1.4rem;
		height: 1.4rem;
		margin-right: 0.6rem;
	}

	&.confirmed-badge {
		background-color: $green-confirmed;
	}

	&.pending-badge {
		background-color: $warning-notification;
	}

	&.expired-badge {
		background-color: #ff9f9f;
		color: #000000;
	}

	&.cancelled-badge {
		background-color: $looking-caregiver-badge;
	}

	&.deactivated-badge {
		background-color: $grayscale-300;
	}

	&.guaranteed-badge {
		background-color: $secondary-400;
		color: $grayscale-0;
	}

	&.integrated-badge {
		background-color: $grayscale-100;
		color: $grayscale-600;
	}

	&.worker-type-badge {
		color: $primary-800;
	}

	&.orientation-badge {
		background-color: $orientation;
		color: $grayscale-0;
	}

	&.bundle-badge {
		background: $grayscale-100;
		color: $grayscale-600;

		@media (max-width: 575px) {
			background-color: $grayscale-0;
			border: 1px solid $grayscale-600;
		}
	}

	.badge-label {
		text-transform: uppercase;
		line-height: 1.4rem;
	}
}
