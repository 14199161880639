@import './../../stylesheets/variables.scss';

.data-table-container {
	height: 100%;
	.data-table {
		width: 100%;
		border-spacing: 0px 5px;

		table {
			border-collapse: collapse;
			overflow: hidden;
			border: 2px solid $soft-black;
			color: $black;
			font-weight: 700;
			background-color: $ultra-white;
		}

		th {
			padding: 1.25rem;
		}

		th:last-child {
			border-right: none;
		}
		th.small-title {
			width: 10%;
		}

		.flex {
			display: flex;
		}
		.action-buttons {
			padding-bottom: 0;
		}

		.header-section {
			width: 100%;
			overflow: hidden;
			border: 2px solid $soft-black;
			color: $black;
			font-weight: 700;
			background-color: $ultra-white;
			th {
				padding: 1.25rem;
			}
			th:last-child {
				border-right: none;
			}
			th.small-title {
				width: 10%;
			}
			th.normal-title {
				width: 20%;
			}
			th.large-title {
				width: 30%;
			}
		}
		.body-section {
			tr {
				border: solid 1px $soft-grey-border;
				td {
					border-bottom: solid 1px $soft-grey-border;
					padding: 1.25rem;
					border-right: $soft-grey-border 1px solid;
				}
			}
			.separator {
				height: 2rem;
				border: none;
			}
		}
	}
}
