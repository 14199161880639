.confirmations-pending-modal {
  color: #2f385c;
  max-height: 100%;
  font-size: 1.4rem; }
  .confirmations-pending-modal .modal-dialog {
    max-width: 95%; }
  .confirmations-pending-modal .modal-content {
    padding: 5px 40px;
    margin: 0 40px;
    overflow: visible !important; }
  .confirmations-pending-modal .modal-header {
    padding: 1.5rem 1rem 0 0;
    font-size: 2rem;
    border-bottom: none; }
    .confirmations-pending-modal .modal-header .modal-title {
      width: 100%;
      text-align: left; }
      .confirmations-pending-modal .modal-header .modal-title h2 {
        font-size: 3.3rem;
        font-weight: 400; }
      .confirmations-pending-modal .modal-header .modal-title .confirmation-subtitle {
        font-size: 1.6rem; }
    .confirmations-pending-modal .modal-header .close {
      font-size: 6rem;
      font-weight: 400;
      color: black;
      opacity: 0.5;
      padding: 0;
      margin: -2rem -3rem -2rem auto;
      height: 50px; }
      .confirmations-pending-modal .modal-header .close span {
        height: 50px;
        display: inline-block; }
    .confirmations-pending-modal .modal-header hr {
      margin: 12px 0 12px 0; }
  .confirmations-pending-modal .modal-body {
    padding: 0 0 2rem 0;
    font-weight: 400;
    font-size: 1.4rem; }
    .confirmations-pending-modal .modal-body .slot {
      align-self: stretch;
      flex-grow: 1;
      margin: 12.5px 0; }
      .confirmations-pending-modal .modal-body .slot-checkbox {
        display: flex;
        align-items: center; }
        .confirmations-pending-modal .modal-body .slot-checkbox .custom-checkbox-control {
          width: 20px;
          height: 20px; }
      .confirmations-pending-modal .modal-body .slot .credential-packet-download {
        font-weight: bold;
        color: #4811a3; }
    .confirmations-pending-modal .modal-body .confirmations-table-rows {
      max-height: 50vh;
      overflow-y: auto; }
    .confirmations-pending-modal .modal-body .confirmations-pending-modal-terms {
      display: flex;
      flex-wrap: nowrap; }
      .confirmations-pending-modal .modal-body .confirmations-pending-modal-terms p {
        font-weight: bold;
        font-size: 1.5rem; }
    .confirmations-pending-modal .modal-body .confirmations-pending-modal-errored {
      margin-top: 20px;
      font-weight: bold;
      font-size: 1.5rem;
      line-height: 30px; }
    .confirmations-pending-modal .modal-body .confirmations-pending-modal-footer {
      width: 99%;
      margin: 0 auto; }
    .confirmations-pending-modal .modal-body .shift-day-separator {
      height: 4px;
      background-color: #531362;
      border-radius: 4px;
      margin: 16px auto;
      opacity: 0.08; }
      .confirmations-pending-modal .modal-body .shift-day-separator.header-separator {
        margin: 10px auto 16px auto; }
    .confirmations-pending-modal .modal-body .shift-separator {
      width: 98%;
      height: 8px;
      background-color: #531362;
      opacity: 0.98;
      border-radius: 8px;
      margin: 8px auto; }
    .confirmations-pending-modal .modal-body .confirmations-checkbox {
      width: 20px;
      height: 20px; }
    .confirmations-pending-modal .modal-body .returning-worker-badge {
      background-color: #531362;
      color: white;
      width: 14rem;
      text-align: center;
      font-weight: bold;
      border-radius: 4px;
      white-space: nowrap;
      border: 1px solid rgba(127, 217, 225, 0.3);
      font-size: 1.3rem;
      margin-bottom: 3px; }
    .confirmations-pending-modal .modal-body .credentials-download {
      color: #531362;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10rem; }
      .confirmations-pending-modal .modal-body .credentials-download:before {
        content: url(../../../assets/images/file-download.svg);
        padding-right: 5px;
        padding-top: 2px; }
      .confirmations-pending-modal .modal-body .credentials-download:hover {
        cursor: pointer;
        text-decoration: underline; }
    .confirmations-pending-modal .modal-body .credentials-tooltip .tooltip-inner {
      background-color: #121315;
      color: #ffffff;
      font-size: 1.4rem; }
    .confirmations-pending-modal .modal-body .guaranteed-badge-span {
      display: flex;
      background-color: rgba(127, 217, 225, 0.3);
      padding-right: 0.6rem;
      color: #00b4c4;
      border-radius: 6px;
      border: 1px solid rgba(127, 217, 225, 0.3);
      font-size: 1rem;
      max-width: 12rem;
      align-items: center; }
      .confirmations-pending-modal .modal-body .guaranteed-badge-span .guaranteed-icon {
        vertical-align: center;
        padding: 2px 6px;
        height: 16px; }
      .confirmations-pending-modal .modal-body .guaranteed-badge-span .orientation-icon {
        vertical-align: center;
        padding: 2px 6px;
        height: 16px; }
      .confirmations-pending-modal .modal-body .guaranteed-badge-span.orientation {
        display: flex;
        align-items: center;
        background-color: #8a78a0;
        border: #8a78a0;
        color: #ffffff;
        font-size: 1.3rem; }
    .confirmations-pending-modal .modal-body .oriented-worker-badge {
      border-radius: 4px;
      background-color: #8a78a0;
      padding: 2px 6px;
      font-size: 1.2rem;
      color: white;
      display: flex;
      justify-content: center;
      height: 2rem;
      align-items: center;
      font-weight: bold;
      min-width: 100px;
      margin-bottom: 3px; }
    .confirmations-pending-modal .modal-body .select-container {
      overflow: visible; }
    .confirmations-pending-modal .modal-body .buttons {
      display: flex;
      margin-top: 20px; }
      .confirmations-pending-modal .modal-body .buttons .confirm-button {
        font-size: 1.5rem;
        border-radius: 4px;
        padding: 5px 20px;
        width: 200px;
        height: 54px;
        box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.03); }
        .confirmations-pending-modal .modal-body .buttons .confirm-button:disabled {
          opacity: 0.5;
          cursor: not-allowed; }

@media (min-width: 992px) and (max-width: 1199px) {
  .confirmation-modal .modal-dialog {
    max-width: 990px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .confirmation-modal .modal-dialog {
    max-width: 730px; }
  .confirmation-modal .modal-header .modal-title {
    font-size: 2rem; }
  .confirmation-modal .table-title {
    font-size: 1.6rem; } }

@media (min-width: 576px) and (max-width: 767px) {
  .confirmation-modal .modal-dialog {
    max-width: 500px; } }
