@import './../../../stylesheets/variables.scss';

.modal-dialog {
	overflow: hidden;
}

.aditionalInformation {
	.tooltip-inner {
		background-color: $background-black;
		color: $ultra-white;
		font-size: 1.4rem;
		text-align: left;
		padding: 0.9rem 1.1rem;
		border-radius: 0.5rem;
	}
}

.confirmation-modal {
	&.custom-confirmation {
		.modal-content {
			margin: 0 auto;
			max-width: 400px;
			min-width: 50%;
		}
	}
}

.recurring-shifts-modal {
	min-height: 500px;
	color: $default-text-color;
	max-height: 100%;
	font-size: 1.4rem;

	.modal-dialog {
		padding: 2rem 0rem;
		min-height: 500px;
		height: 100%;
		margin: 0 auto;
		width: 1100px;
		max-width: 80%;
	}

	.tooltip-icon {
		margin-top: -25px;
		cursor: pointer;
	}

	.modal-content {
		height: 100%;
		padding: 5px 40px;
		overflow: auto;
	}

	.modal-body {
		height: calc(100% - 60px);
	}

	.modal-header {
		padding: 1.5rem 1rem 0 0;
		font-size: 2rem;
		border-bottom: none;
		height: 60px;

		.modal-title {
			width: 100%;
			text-align: left;

			h2 {
				font-size: 3.3rem;
				font-weight: 400;
			}

			.confirmation-subtitle {
				font-size: 1.6rem;
			}
		}

		.close {
			font-size: 2rem;
			font-weight: 400;
			color: black;
			opacity: 0.5;
			padding: 0;
			margin: -1rem -2rem -2rem auto;
			height: 50px;

			span {
				height: 50px;
				display: inline-block;
			}
		}

		hr {
			margin: 12px 0 12px 0;
		}
	}

	&-body {
		display: flex;
		flex-flow: column;
		height: 100%;
		font-weight: 400;
		font-size: 1.4rem;

		.recurring-shifts-modal-tabs {
			display: flex;
			justify-content: space-between;
			align-items: baseline;

			.recurring-shifts-modal-tab {
				display: flex;
				align-items: baseline;
				justify-content: center;
				font-weight: 400;
				color: $text-silver;
				font-size: 2rem;
				cursor: pointer;
				flex: 0 0 14%;

				svg {
					align-self: center;
				}

				&.active-day {
					color: $button-purple;
					font-weight: bold;
					font-size: 2.8rem;

					.recurring-shift-icon {
						width: 17px;
						height: 23px;
						color: $button-purple;
					}
				}

				.recurring-shift-icon {
					width: 10px;
					height: 13px;
					margin-right: 5px;
					color: $text-silver;
					fill: currentColor;
				}
			}
		}

		.recurring-shifts-modal-terms {
			display: flex;
			flex-wrap: nowrap;

			p {
				font-weight: bold;
				font-size: 1.5rem;
			}
		}

		.recurring-shifts-modal-footer {
			width: 99%;
			margin: 0 auto;
			text-align: center;

			.single-shift-button {
				border: none;
				background-color: transparent;
				color: $button-purple;
				font-weight: 600;
				width: 132px;
				height: 54px;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.confirmations-checkbox {
			width: 20px;
			height: 20px;
		}

		.select-container {
			overflow: visible;
		}

		.buttons {
			display: flex;
			margin: 20px auto 0 auto;
			max-width: 424px;
			justify-content: space-between;

			.confirm-button {
				font-size: 1.5rem;
				border-radius: 4px;
				padding: 5px 20px;
				width: 200px;
				height: 54px;
				box-shadow: 0px -10px 20px rgba(0, 0, 0, 0.03);

				&:disabled {
					cursor: not-allowed;
				}
			}
		}
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.confirmation-modal {
		.modal-header {
			.modal-title {
				font-size: 2rem;
			}
		}
	}
}

@media screen and (max-width: 768px) {
	.recurring-shifts-modal {
		.modal-body {
			.recurring-shifts-modal-tabs {
				.recurring-shifts-modal-tab {
					font-size: 1.6rem;

					.recurring-shift-icon {
						width: 8px;
						height: 12px;
					}

					&.active-day {
						font-size: 2rem;

						.recurring-shift-icon {
							width: 14px;
							height: 18px;
						}
					}
				}
			}
		}
	}
}
